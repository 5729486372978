import { all } from 'redux-saga/effects';

import authSaga from './auth';
import billsSaga from './bills';
import confSaga from './conf';
import entitiesSaga from './entities';
import experiencesSaga from './experiences';
import formsSaga from './forms';
import hotelAdminSaga from './hotelAdmin';
import hotelsSaga from './hotels';
import navigationSaga from './navigation';
import picturesSaga from './pictures';
import roomsSaga from './rooms';
import tagsSaga from './tags';
import vouchersSaga from './vouchers';

export default function* sagas() {
  yield all([
    entitiesSaga(),
    navigationSaga(),
    hotelsSaga(),
    formsSaga(),
    hotelAdminSaga(),
    authSaga(),
    billsSaga(),
    roomsSaga(),
    picturesSaga(),
    confSaga(),
    experiencesSaga(),
    tagsSaga(),
    vouchersSaga(),
  ]);
}
