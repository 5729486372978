import {
  ExclamationCircleOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Divider, Row, Tag, Tooltip } from 'antd';
import React, { Fragment, useContext, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import type { BedCategory, Room as RoomType } from 'app/typings';
import { Experience } from 'app/typings/experiences';

import { Room } from './Room/Room';

const ROOMS_PER_PAGE = 2;
const NB_GUEST = 2;

export const PER_PERSON = '/pers.';

type OverviewProps = {
  hasRoom: boolean;
  bedCategories: BedCategory[];
  roomsList: RoomType[];
  includedExperiences: Experience[];
  experienceCategories: ExperienceCategory[];
  sumPrice: number;
};

export const Overview = ({
  hasRoom,
  bedCategories,
  roomsList,
  includedExperiences,
  experienceCategories,
  sumPrice,
}: OverviewProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const [currentRoomPage, setCurrentRoomPage] = useState(0);
  const startIndex = currentRoomPage * ROOMS_PER_PAGE;
  const endIndex = startIndex + ROOMS_PER_PAGE;
  const currentRooms = roomsList.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    if (currentRoomPage > 0) {
      setCurrentRoomPage(currentRoomPage - 1);
    }
  };

  const handleNextPage = () => {
    if ((currentRoomPage + 1) * ROOMS_PER_PAGE < roomsList.length) {
      setCurrentRoomPage(currentRoomPage + 1);
    }
  };

  const getExperiencesCategory = (experienceId: number) => {
    return experienceCategories.find((cat) => cat.id === experienceId);
  };

  const formatTagExperience = (experience: Experience) => {
    const experienceName = getExperiencesCategory(experience.categoryId)?.name;
    const price = currencyFormatter(experience.price ?? 0);
    const suffix = experience.priceDescription;

    return suffix
      ? `${experienceName} (${price} ${suffix})`
      : `${experienceName} (${price})`;
  };

  return (
    <Row>
      <div className="overview__yourPackage">
        <p className="overview__yourPackage__title">Your package</p>
        <div className="overview__yourPackage__infos">
          <div className="infoPrice">
            <p className="price">{currencyFormatter(sumPrice * NB_GUEST)}</p>
            <p className="priceDescription">
              for two guests{' '}
              <span className="boldDescription">
                ({currencyFormatter(sumPrice) + PER_PERSON})
              </span>
            </p>
          </div>
          <div className="infoContent">
            {includedExperiences.map((exp, index) => (
              <Fragment key={exp.id}>
                <Tag>{formatTagExperience(exp)}</Tag>
                {index + 1 !== includedExperiences.length && ' + '}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      {hasRoom && (
        <>
          <Divider type="vertical" className="overview__divider" />
          <div className="overview__yourRooms">
            <div className="overview__yourRooms__header">
              <p className="overview__yourRooms__title">
                Your rooms (for more than 2 adults)
                <Tooltip title="Contact your Account Manager if there is something wrong in your room configuration">
                  <QuestionCircleOutlined />
                </Tooltip>
              </p>
              {roomsList.length > 2 && (
                <div className="pagination">
                  <Button
                    onClick={handlePrevPage}
                    disabled={currentRoomPage === 0}
                  >
                    <LeftOutlined />
                  </Button>
                  <Button
                    onClick={handleNextPage}
                    disabled={endIndex >= roomsList.length}
                  >
                    <RightOutlined />
                  </Button>
                </div>
              )}
            </div>
            <div className="overview__yourRooms__infos">
              {currentRooms.length > 0 ? (
                currentRooms.map((room, index) => {
                  return (
                    <Room
                      key={index}
                      index={index}
                      room={room}
                      bedCategories={bedCategories}
                    />
                  );
                })
              ) : (
                <div className="noRooms">
                  <ExclamationCircleOutlined className="noRooms_icon" />
                  <div>
                    <p className="noRooms_title">No matching room</p>
                    <p className="noRooms_description">
                      You have no room configured for more than 2 adults.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Row>
  );
};
