import { differenceInCalendarDays } from 'date-fns';

import { RoomCategory } from 'app/typings';
import { DEFAULT_ADULTS_PAX } from 'app/typings/PAX';
import { BookingDetails } from 'app/typings/extranetBookings/booking';
import { VccStatus } from 'app/typings/vcc';

export const getTooltipText = (textStatus: VccStatus) => {
  switch (textStatus) {
    case VccStatus.Inactive:
      return 'Active on the day of check-in.';

    case VccStatus.Cancelled:
      return 'The client cancelled the booking, the refund will be issued by Staycation.';

    case VccStatus.Deactivated:
      return 'VCC expire within 180 days of check-in for fraud protection.';

    default:
      return undefined;
  }
};

export const getNbNight = (booking: BookingDetails, roomCat?: RoomCategory) => {
  return differenceInCalendarDays(
    new Date(booking.checkout),
    new Date(booking.checkin)
  );
};

export const getTotalPrice = (
  booking: BookingDetails,
  roomCategories?: Array<RoomCategory>
) => {
  const nbNight = getNbNight(
    booking,
    roomCategories?.find((rc) => rc.id === booking.room.categoryId)
  );

  const bedRoomPriceForAllNights =
    (booking.room.bedPricePerNight ?? 0) * nbNight;

  const singleOrExtraGuest =
    booking.pax.adults === DEFAULT_ADULTS_PAX
      ? 0
      : booking.pax.adults > DEFAULT_ADULTS_PAX
      ? booking.room.additionalAdultPricePerNight ?? 0
      : booking.room.singleCustomerDiscountPerNight ?? 0;

  const paxForAllNights =
    singleOrExtraGuest * nbNight * (booking.pax.adults - DEFAULT_ADULTS_PAX);

  const childrenPaxForAllNights =
    (booking.room.additionalChildPricePerNight ?? 0) *
    booking.pax.children *
    nbNight;

  return (
    booking.room.discountPrice -
    bedRoomPriceForAllNights -
    paxForAllNights -
    childrenPaxForAllNights
  );
};
