import { User } from 'app/typings';
import { BillingInterval } from 'app/typings/billingIntervals';
import { Country } from 'app/typings/countries';
import { Hotel } from 'app/typings/hotels/Hotel/Hotel';
import { isUserSuperAdmin } from 'app/utils/user/user';

export type ExtranetPaymentDisplayProps = {
  adyenPayout: boolean;
  stripeAccountConfigured: boolean;
  stripeAccountError: boolean;
  newHotel: boolean;
  stripeAllowed: boolean;
  issuingAllowed: boolean;
  VCCActive: boolean;
  hotelUser: boolean;
};

export const getDisplayProps = (
  hotel: Hotel,
  country: Country,
  user?: User
): ExtranetPaymentDisplayProps => {
  const adyenPayout = !country.stripeActivated;
  const stripeAccountConfigured = hotel.pspOnboardingDone && !!hotel.pspId;
  const stripeAccountError =
    !adyenPayout && stripeAccountConfigured && !hotel.payoutEnabled;
  const newHotel = !hotel.pspOnboardingDone && country.stripeActivated;
  const stripeAllowed = country.stripeActivated;
  const issuingAllowed = country.issuingAllowed;
  const VCCActive = hotel.issuingActivated;
  const hotelUser =
    !!(user?.role === 'user' && user.hotelIds && user.hotelIds.length) ||
    isUserSuperAdmin(user);

  return {
    adyenPayout,
    stripeAccountConfigured,
    stripeAccountError,
    newHotel,
    stripeAllowed,
    issuingAllowed,
    VCCActive,
    hotelUser,
  };
};

export const billingIntervalUnitMap = {
  [BillingInterval.DAILY]: 'day',
  [BillingInterval.MONTHLY]: 'month',
  [BillingInterval.WEEKLY]: 'week',
};

export const getBillingIntervalUnit = (billingInterval: BillingInterval) =>
  billingIntervalUnitMap[billingInterval] ?? [BillingInterval.MONTHLY];
