import type { PropsWithChildren } from 'react';
import { useContext, useEffect } from 'react';

import { UserContext } from 'app/context/UserContext/UserContext';

import * as featureflags from './featureFlagsService';

export const FeatureFlags = (props: PropsWithChildren) => {
  useInitFeatureFlags();

  return <>{props.children}</>;
};

function useInitFeatureFlags() {
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      return;
    }

    const experimentUser = {
      user_id: `${user.id}`,
    };

    const initExperiment = async () => {
      await featureflags.initialize(experimentUser);
    };

    initExperiment();
  }, [user]);
}
