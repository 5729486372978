import { addMonths, isAfter, isBefore, isFuture, isToday } from 'date-fns';

import { PSP } from 'app/typings/PSP';
import { BookingStatus } from 'app/typings/adminBookings/bookings';
import {
  BookingRefundStatus,
  Refund,
} from 'app/typings/extranetBookings/booking';
import {
  DEACTIVATE_AFTER_X_MONTHS,
  VccStatus,
  VccStatusData,
  VccStatusDisplayInfo,
} from 'app/typings/vcc';

import { formatDayOfMonthWithHour, isPassed } from './dates';
import { parseUppercaseSnakeTextToCapitalize } from './string';

export type BookingInfo = {
  checkin?: string;
  status: BookingStatus;
  psp?: PSP;
  refunds: Array<Refund>;
  hotelAmount: number;
  issuingTransactionId?: string;
  issuingChargedAt?: string;
  vccStatus?: VccStatus;
};

type VccHistoryType = { date: string; text: string }[];

export const getVCCStatus = (
  booking: BookingInfo,
  currencyFormatter: (value: number) => string
): VccStatusData => {
  const formattedCheckIn = booking.checkin
    ? new Date(booking.checkin)
    : new Date();
  const history = getVCCHistory(booking, currencyFormatter);

  if (booking.vccStatus) {
    switch (booking.vccStatus) {
      case VccStatus.Inactive:
        return {
          status: VccStatus.Inactive,
          text: parseUppercaseSnakeTextToCapitalize(VccStatus.Inactive),
          color: 'default',
          history,
        };
      case VccStatus.Cancelled:
        return {
          status: VccStatus.Cancelled,
          text: parseUppercaseSnakeTextToCapitalize(VccStatus.Cancelled),
          color: 'red',
          history,
        };
      case VccStatus.ReadyToCharge:
        return {
          status: VccStatus.ReadyToCharge,
          text: parseUppercaseSnakeTextToCapitalize(VccStatus.ReadyToCharge),
          color: 'blue',
          history,
        };
      case VccStatus.FullyCharged:
        return {
          status: VccStatus.FullyCharged,
          text: parseUppercaseSnakeTextToCapitalize(VccStatus.FullyCharged),
          color: 'green',
          history,
        };
      case VccStatus.PendingRefund:
        return {
          status: VccStatus.PendingRefund,
          text: parseUppercaseSnakeTextToCapitalize(VccStatus.PendingRefund),
          color: 'gold',
          history,
        };
      case VccStatus.PartiallyRefunded:
        return {
          status: VccStatus.PartiallyRefunded,
          text: parseUppercaseSnakeTextToCapitalize(
            VccStatus.PartiallyRefunded
          ),
          color: 'purple',
          history,
        };
      case VccStatus.FullyRefunded:
        return {
          status: VccStatus.FullyRefunded,
          text: parseUppercaseSnakeTextToCapitalize(VccStatus.FullyRefunded),
          color: 'purple',
          history,
        };
      case VccStatus.Deactivated:
        return {
          status: VccStatus.Deactivated,
          text: parseUppercaseSnakeTextToCapitalize(VccStatus.Deactivated),
          color: 'default',
          history,
        };
    }
  }

  const sumOfRefunds = getSumOfRefunds(
    booking.refunds.filter(
      (refund) => refund.status === BookingRefundStatus.ACCEPTED
    )
  );

  const allRefundsCharged = !booking.refunds.filter(
    (refund) => refund.status === BookingRefundStatus.PENDING_HOTEL
  ).length;

  const isFutureCheckIn =
    isFuture(formattedCheckIn) && !isToday(formattedCheckIn);
  const isPendingRefund = !!booking.refunds.length && !allRefundsCharged;
  const isFullyRefunded =
    !!booking.refunds.length &&
    allRefundsCharged &&
    sumOfRefunds === booking.hotelAmount;
  const isPartiallyRefunded =
    !!booking.refunds.length &&
    allRefundsCharged &&
    sumOfRefunds !== booking.hotelAmount;
  const isFullyCharged =
    !!booking.issuingTransactionId && !booking.refunds.length;
  const isDeactivated =
    !isFullyCharged &&
    isAfter(new Date(), addMonths(formattedCheckIn, DEACTIVATE_AFTER_X_MONTHS));
  const isCancelled =
    !booking.issuingChargedAt &&
    booking.refunds.length === 1 &&
    sumOfRefunds === booking.hotelAmount &&
    allRefundsCharged;
  const isReadyToCharge =
    (isToday(formattedCheckIn) || isPassed(formattedCheckIn)) &&
    isBefore(
      new Date(),
      addMonths(formattedCheckIn, DEACTIVATE_AFTER_X_MONTHS)
    ) &&
    !booking.issuingTransactionId;

  if (isCancelled) {
    return {
      status: VccStatus.Cancelled,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.Cancelled),
      color: 'red',
      history,
    };
  }

  if (isFutureCheckIn) {
    return {
      status: VccStatus.Inactive,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.Inactive),
      color: 'default',
      history,
    };
  }

  if (isFullyCharged) {
    return {
      status: VccStatus.FullyCharged,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.FullyCharged),
      color: 'green',
      history,
    };
  }

  if (isDeactivated) {
    return {
      status: VccStatus.Deactivated,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.Deactivated),
      color: 'default',
      history,
    };
  }

  if (isReadyToCharge) {
    return {
      status: VccStatus.ReadyToCharge,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.ReadyToCharge),
      color: 'blue',
      history,
    };
  }

  if (isPendingRefund) {
    return {
      status: VccStatus.PendingRefund,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.PendingRefund),
      color: 'gold',
      history,
    };
  }

  if (isFullyRefunded) {
    return {
      status: VccStatus.FullyRefunded,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.FullyRefunded),
      color: 'purple',
      history,
    };
  }

  if (isPartiallyRefunded) {
    return {
      status: VccStatus.PartiallyRefunded,
      text: parseUppercaseSnakeTextToCapitalize(VccStatus.PartiallyRefunded),
      color: 'purple',
      history,
    };
  }

  return {
    status: VccStatus.Inactive,
    text: parseUppercaseSnakeTextToCapitalize(VccStatus.Inactive),
    color: 'default',
    history,
  };
};

export const getVCCStatusDisplayInfo = (
  vccStatus: VccStatus
): VccStatusDisplayInfo => {
  const text = parseUppercaseSnakeTextToCapitalize(vccStatus);

  switch (vccStatus) {
    case VccStatus.Cancelled:
      return {
        text,
        color: 'red',
      };
    case VccStatus.Inactive:
      return {
        text,
        color: 'default',
      };
    case VccStatus.ReadyToCharge:
      return {
        text,
        color: 'blue',
      };
    case VccStatus.FullyCharged:
      return {
        text,
        color: 'green',
      };
    case VccStatus.PartiallyRefunded:
      return {
        text,
        color: 'purple',
      };
    case VccStatus.PendingRefund:
      return {
        text,
        color: 'gold',
      };
    case VccStatus.Deactivated:
      return {
        text,
        color: 'default',
      };
    case VccStatus.FullyRefunded:
      return {
        text,
        color: 'purple',
      };
    default:
      return { text: 'Bank transfer', color: 'default' };
  }
};

const getVCCHistory = (
  booking: BookingInfo,
  currencyFormatter: (value: number) => string
): string[] => {
  const initialHistory: VccHistoryType = booking.issuingChargedAt
    ? [
        {
          date: booking.issuingChargedAt,
          text: `${currencyFormatter(
            booking.hotelAmount
          )} charged on the ${formatDayOfMonthWithHour(
            booking.issuingChargedAt
          )}`,
        },
      ]
    : [];

  const history: VccHistoryType = booking.refunds.flatMap(
    (refund) =>
      refund.issuingRefunds?.map((issuingRefund) => ({
        date: issuingRefund.createdAt,
        text: `${currencyFormatter(
          issuingRefund.amount
        )} refunded on the ${formatDayOfMonthWithHour(
          issuingRefund.createdAt
        )}`,
      })) || []
  );

  return history
    .concat(initialHistory)
    .sort((a, b) => (isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1))
    .map((entry) => entry.text);
};

const getTotalIssuingRefundAmount = (refund: Refund) => {
  return (
    refund.issuingRefunds?.reduce(
      (sum, issuingRefund) => sum + issuingRefund.amount,
      0
    ) || 0
  );
};

export const getTotalRefundsExcludingIssuings = (refunds: Refund[]): number => {
  return refunds.reduce((total, refund) => {
    return (
      total +
      (refund.refundedFromHotel ?? 0) -
      getTotalIssuingRefundAmount(refund)
    );
  }, 0);
};

export const getSumOfIssuingRefunds = (refunds: Refund[]): number => {
  return refunds.reduce((total, refund) => {
    return total + getTotalIssuingRefundAmount(refund);
  }, 0);
};

// we multiply by 100 and divide by 100 to avoid floating point issues with float numbers
export const getSumOfRefunds = (refunds: Refund[]): number => {
  return (
    refunds.reduce((total, refund) => {
      return total + (refund.refundedFromHotel ?? 0) * 100;
    }, 0) / 100
  );
};
