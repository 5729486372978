import { FolderOpenOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import VCCBillingTuto from 'assets/VCCBillingTuto.png';

import { TextWithIcon } from 'app/components/commons/TextWithIcon/TextWithIcon';

export const VCCBillingStep = () => {
  return (
    <div className="content-wrapper">
      <div className="grey-square">
        <TextWithIcon
          icon={<FolderOpenOutlined />}
          text={<span className="font-weight-600">Bills</span>}
        />
        <p className="content-text-line">
          At the end of each month, an invoice will be sent with a{' '}
          <span className="font-weight-600">
            detailed breakdown of all VCC amounts
          </span>{' '}
          and <span className="font-weight-600">Staycation commissions.</span>
        </p>
      </div>
      <div>
        <Divider className="img-divider"></Divider>
        <img
          className="width-100"
          src={VCCBillingTuto}
          alt="Virtual Cards Billing tutorial"
        />
      </div>
    </div>
  );
};
