import { useMemo } from 'react';

import { useCountries } from 'app/hooks/data/useCountries';
import { GiftCard, PhysicalGiftCardProperties } from 'app/typings/giftCard';
import { capitalizeFirstLetter } from 'app/utils/string';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & PhysicalGiftCardProperties;
};

const PhysicalProperties = ({ giftCard }: Props) => {
  const {
    giftCardType,
    senderName,
    beneficiaryName,
    shippingFirstName,
    shippingLastName,
    shippingStreet1,
    shippingStreet2,
    shippingZipCode,
    shippingCity,
    shippingCountryId,
  } = giftCard;

  const { data: countries } = useCountries();

  const shippingCountryName = useMemo(
    () => countries?.find(({ id }) => id === shippingCountryId)?.name,
    [countries, shippingCountryId]
  );

  return (
    <>
      <Line title="Type" value={capitalizeFirstLetter(giftCardType)} />
      <Line title="From" value={senderName} />
      <Line title="To" value={beneficiaryName} />
      <Line title="First name (shipping)" value={shippingFirstName} />
      <Line title="Last name (shipping)" value={shippingLastName} />
      <Line title="Street 1" value={shippingStreet1} />
      <Line title="Street 2" value={shippingStreet2} />
      <Line title="Zip code" value={shippingZipCode} />
      <Line title="City" value={shippingCity} />
      <Line title="Country" value={shippingCountryName} />
    </>
  );
};

export default PhysicalProperties;
