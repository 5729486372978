import { GiftCardStatus, GiftCardType } from 'app/typings/giftCard';

export function isValidStatus(
  maybeStatus: string
): maybeStatus is GiftCardStatus {
  return Object.values(GiftCardStatus).some((status) => status === maybeStatus);
}

export function isValidType(maybeType: string): maybeType is GiftCardType {
  return Object.values(GiftCardType).some((status) => status === maybeType);
}
