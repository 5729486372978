import { Collapse } from 'antd';
import { FC, useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import Line from 'app/components/pages/GiftCards/details/Line/Line';
import { type Refund } from 'app/typings/adminBookings/bookingRefund';
import { dateAndHour } from 'app/utils/dates';

import './Refunds.scss';

const { Panel } = Collapse;

export const Refunds: FC<Props> = ({ refunds }) => {
  const { currencyFormatter } = useContext(CurrencyContext);

  const renderHeader = (refund: Refund) =>
    `Refund - ${dateAndHour(refund.createdAt)} - ${currencyFormatter(
      refund.amountCb + refund.amountGiftCard + refund.amountCredit
    )}`;

  return (
    <div className="bloc refunds">
      <Collapse accordion>
        {refunds.map((refund, index) => {
          const issuingValue =
            refund.issuingRefunds?.reduce(
              (sum, issuingRefund) => sum + issuingRefund.amount,
              0
            ) ?? 0;

          return (
            <Panel header={renderHeader(refund)} key={refund.id}>
              <Line
                title="Credit card refund"
                value={currencyFormatter(refund.amountCb)}
              />
              <Line
                title="Gift card refund"
                value={currencyFormatter(refund.amountGiftCard)}
              />
              <Line
                title="Credit refund"
                value={currencyFormatter(refund.amountCredit)}
              />
              <Line
                title="Total Refund"
                value={currencyFormatter(
                  refund.amountCb + refund.amountGiftCard + refund.amountCredit
                )}
              />
              <Line
                title="Amount due by hotel"
                value={currencyFormatter(refund.refundedFromHotel)}
              />
              {refund.issuingRefunds && refund.issuingRefunds?.length > 0 && (
                <Line
                  title="Amount paid by VCC"
                  value={currencyFormatter(issuingValue)}
                />
              )}
              <Line title="Paid by" value={refund.source} />
              <Line title="Created at" value={dateAndHour(refund.createdAt)} />
              <Line title="Created by" value={refund.userName} />
              <Line title="Reason type" value={refund.reason} />
              <Line title="Comment" value={refund.comment} />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

type Props = {
  refunds: Array<Refund>;
};

export default Refunds;
