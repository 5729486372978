import { ReactNode } from 'react';

import './TextWithIcon.scss';

type CheckedLineProps = {
  text: string | ReactNode;
  icon: ReactNode;
};

export const TextWithIcon = ({ text, icon }: CheckedLineProps) => {
  return (
    <div className="text-with-icon">
      {icon}
      <div>{text}</div>
    </div>
  );
};
