import { BillingInterval } from 'app/typings/billingIntervals';

import { getBillingIntervalUnit } from '../../utils';

type BankTransferCardContentProps = {
  billingInterval?: BillingInterval;
};

export const BankTransferCardContent = ({
  billingInterval,
}: BankTransferCardContentProps) => {
  if (!billingInterval) {
    return <></>;
  }

  const billingIntervalString = billingInterval.toLowerCase();

  return (
    <ul className="info-list">
      <li className="info-line">
        <span className="font-weight-600">Delayed payment: </span>
        {`receive a single payout at the end of the ${getBillingIntervalUnit(
          billingInterval
        )}.`}
      </li>
      <li className="info-line">
        <span className="font-weight-600">KYC verification required: </span>a
        mandatory identity check is needed before receiving payments.
      </li>
      <li className="info-line">
        <span className="font-weight-600">Net payment: </span>
        {billingIntervalString} transfers are sent after deducting Staycation’s
        commission.
      </li>
    </ul>
  );
};
