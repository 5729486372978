import { Form, Input, Layout } from 'antd';
import { Location } from 'history';
import { ReactNode, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useGetPackageCover } from 'app/hooks/data/packages/usePackages';
import { useSavePackageCover } from 'app/hooks/data/packages/useSavePackage';
import { Picture } from 'app/typings';
import { Package } from 'app/typings/packages';

import './Cover.scss';

const { Content } = Layout;

type FormValues = {
  coverLabel1?: string;
  coverLabel2?: string;
  horizontalCoverPicture: Array<Picture>;
  verticalCoverPicture: Array<Picture>;
  coverPicture: Array<Picture>;
  appPicture: Array<Picture>;
  sliderPicture: Array<Picture>;
};

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  pkg: Package;
};

const Cover = ({ headerMenu, headerExtra, pkg }: Props) => {
  const { state } = useLocation<Location>();

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [form] = Form.useForm();

  const { data: cover } = useGetPackageCover(pkg?.id);
  const { mutateAsync: saveCover } = useSavePackageCover();

  const onFinish = useCallback(
    async (values: FormValues) => {
      if (pkg && pkg.id) {
        const formattedValues = {
          coverLabel1: values.coverLabel1,
          coverLabel2: values.coverLabel2,
          horizontalCoverPicture: values.horizontalCoverPicture[0],
          verticalCoverPicture: values.verticalCoverPicture[0],
          coverPicture: values.coverPicture[0],
          appPicture: values.appPicture[0],
          sliderPicture: values.sliderPicture[0],
        };

        await saveCover({ packageId: pkg.id, payload: formattedValues });

        setMode('view');
      }
    },
    [pkg, saveCover]
  );

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  if (!pkg || !cover) {
    return null;
  }

  const formattedValues = {
    coverLabel1: cover.coverLabel1,
    coverLabel2: cover.coverLabel2,
    horizontalCoverPicture: cover.horizontalCoverPicture
      ? [cover.horizontalCoverPicture]
      : [],
    verticalCoverPicture: cover.verticalCoverPicture
      ? [cover.verticalCoverPicture]
      : [],
    coverPicture: cover.coverPicture ? [cover.coverPicture] : [],
    appPicture: cover.appPicture ? [cover.appPicture] : [],
    sliderPicture: cover.sliderPicture ? [cover.sliderPicture] : [],
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={formattedValues}
      className="cover-form"
    >
      {(values, { resetFields, isFieldsTouched, submit }) => (
        <SDetailLayout
          title={
            pkg.aliasForHotel?.trim() && !!pkg.aliasForHotel.length
              ? pkg.aliasForHotel
              : `Package #${pkg.id}`
          }
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={submit}
          onCancel={onCancel}
          reset={resetFields}
          isDirty={isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length > 0)}
          backRoute={state?.pathname}
        >
          <Content className="package-detail__content">
            <SCard
              title="Label"
              subtitle={
                'Text displayed over the cover images (formerly called "Package name")'
              }
              className="scard-with-pictures"
            >
              <Form.Item label="First line" name={['coverLabel1']}>
                {mode === 'edit' || !values ? (
                  <Input />
                ) : (
                  <div className="view-text">{values?.coverLabel1}</div>
                )}
              </Form.Item>
              <Form.Item label="Second line" name={['coverLabel2']}>
                {mode === 'edit' || !values ? (
                  <Input />
                ) : (
                  <div className="view-text">{values?.coverLabel2}</div>
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Horizontal format"
              subtitle="Image used to display the cover in most cases, notably the package cards and the cover on the package page on desktop"
              className="scard-with-pictures"
            >
              <Form.Item name="horizontalCoverPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Cover"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the cover"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.horizontalCoverPicture.length
                        ? values.horizontalCoverPicture
                        : formattedValues.horizontalCoverPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Vertical format"
              subtitle="Image used to display the cover in some cases, notably for daytime packages and the cover on the package page on mobile"
              className="scard-with-pictures"
            >
              <Form.Item name="verticalCoverPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Cover"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the cover"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.verticalCoverPicture.length
                        ? values.verticalCoverPicture
                        : formattedValues.verticalCoverPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Cover (deprecated)"
              subtitle="Former main cover, now used only for backwards compatibility"
              className="scard-with-pictures deprecated"
            >
              <Form.Item name="coverPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Cover"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the cover"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.coverPicture.length
                        ? values.coverPicture
                        : formattedValues.coverPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="App (deprecated)"
              subtitle="Former cover for mobile, now used only for backwards compatibility"
              className="scard-with-pictures deprecated"
            >
              <Form.Item name="appPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="App"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the app"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.appPicture.length
                        ? values.appPicture
                        : formattedValues.appPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Slider (deprecated)"
              subtitle="Former cover used for the (now removed) slider, now used only for backwards compatibility"
              className="scard-with-pictures staycation-card-last deprecated"
            >
              <Form.Item name="sliderPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Slider"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the slider"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.sliderPicture.length
                        ? values.sliderPicture
                        : formattedValues.sliderPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

export default Cover;
