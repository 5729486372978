import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { TextWithIcon } from 'app/components/commons/TextWithIcon/TextWithIcon';

type ExtranetPaymentCardProps = {
  icon: ReactNode;
  title: string | ReactNode;
  description: string;
  content: ReactNode;
  action: ReactNode;
  errorMessage?: ReactNode;
  extra?: ReactNode;
  disabled?: boolean;
  buttonInfo?: string;
  active?: boolean;
};

export const ExtranetPaymentCard = ({
  icon,
  title,
  description,
  content,
  action,
  errorMessage,
  disabled = false,
  extra,
  buttonInfo,
  active,
}: ExtranetPaymentCardProps) => {
  return (
    <Card
      className={classNames([
        'extranet-payment__card',
        {
          disabled,
          active,
        },
      ])}
    >
      <div className="extranet-payment__card__body">
        <div className="extranet-payment__card__top">
          <div className="extranet-payment__card__header">
            <Row
              justify={'space-between'}
              align={'middle'}
              className="extranet-payment__card__header__top"
            >
              <Col className="extranet-payment__card__header__icon">{icon}</Col>
              <Col>{extra}</Col>
            </Row>
            <div>
              <Title className="extranet-payment__title">{title}</Title>
              <Paragraph className="extranet-payment__description">
                {description}
              </Paragraph>
            </div>
          </div>
          <Content className="extranet-payment__card__content">
            <div className="grey-square">{content}</div>
            {!!errorMessage && <div>{errorMessage}</div>}
          </Content>
        </div>
        <div>
          {disabled ? (
            <TextWithIcon
              icon={<InfoCircleOutlined />}
              text="This method is not available in your country yet"
            />
          ) : (
            <div className="extranet-payment__card-action">{action}</div>
          )}
          {!!buttonInfo && (
            <div className="extranet-payment__button-info">{buttonInfo}</div>
          )}
        </div>
      </div>
    </Card>
  );
};
