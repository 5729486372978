import { Button } from 'antd';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

import { ExtranetPaymentDisplayProps } from '../../utils';

type BankTransferActionButtonProps = {
  displayProps: ExtranetPaymentDisplayProps;
  apiUrl?: string;
  hotelId?: string | null;
  token?: string;
};

const getButtonText = (buttonProps: ExtranetPaymentDisplayProps) => {
  if (buttonProps.stripeAccountError) {
    return 'Update my Stripe KYC';
  } else if (buttonProps.newHotel) {
    return 'Configure my Stripe account';
  }

  return 'Access my Stripe account';
};

export const BankTransferActionButton = ({
  displayProps,
  apiUrl,
  hotelId,
  token,
}: BankTransferActionButtonProps) => {
  const { track } = useAmplitude();

  if (
    !apiUrl ||
    !hotelId ||
    !token ||
    displayProps.adyenPayout ||
    !displayProps.stripeAllowed ||
    (!displayProps.stripeAccountConfigured && displayProps.VCCActive)
  ) {
    return <></>;
  }

  return (
    <Button
      type={
        displayProps.stripeAccountConfigured && !displayProps.stripeAccountError
          ? undefined
          : 'primary'
      }
      disabled={!displayProps.hotelUser}
      onClick={() => {
        window.open(
          `${apiUrl}/hotels/${hotelId}/stripe-session?token=${token}`
        );
        track('Click Access Stripe account');
      }}
    >
      {getButtonText(displayProps)}
    </Button>
  );
};
