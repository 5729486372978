import { Button, Layout, PageHeader, Popconfirm } from 'antd';
import formatDate from 'date-fns/format';
import _ from 'lodash';
import { ReactNode, useState } from 'react';

import Pic from 'app/components/commons/Pic';
import DefaultProfile from 'app/components/commons/icons/DefaultProfile';
import { useCountries } from 'app/hooks/data/useCountries';
import { useGetUser } from 'app/hooks/data/users/useGetUsers';
import { useDeleteUser, useSaveUser } from 'app/hooks/data/users/useUser';
import { FormUser, USER_ROLES } from 'app/typings';
import { BookingStatus } from 'app/typings/adminBookings/bookings';
import { dateAndHour } from 'app/utils/dates';
import { formatCurrency } from 'app/utils/strings';

import 'styles/pages/Users/user-details.scss';

import BookingsLinked from './BookingsLinked';
import GiftCardsLinked from './GiftCardsLinked';
import EditUserModal from './Modals/EditUserModal';

const GIFT_CARD_PAYED_STATUSES = ['active', 'expired', 'empty', 'cancelled'];
const MAX_SHOWN_REFEREES = 7;

const UserDetails = ({ history, match }: Props) => {
  const userId = parseInt(match.params?.id ?? '', 10);

  const { data: userDetails } = useGetUser(userId);
  const { data: countries } = useCountries();
  const { mutate: saveUser } = useSaveUser();
  const { mutate: deleteUser } = useDeleteUser();

  const countrySlug = userDetails?.user?.countrySlug || '';
  const country = countries?.find((c) => c.slug === countrySlug);
  const currency = country?.currency;

  const [showAllReferrees, setShowAllReferrees] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const handleDeleteUser = () => {
    deleteUser(userId);
  };

  const handleSaveUser = (data: FormUser) => {
    saveUser(data);
    setEditModalVisible(false);
  };

  const mean = (arr: number[]) =>
    !!arr.length ? arr.reduce((acc, num) => acc + num, 0) / arr.length : 0;

  const back = () => {
    history.goBack();
  };

  if (userDetails && userDetails.user.id === userId) {
    const line = (title: string, value: ReactNode, className = '') => (
      <div className="line">
        <span className="left-title">{title}</span>
        <span
          style={{ marginLeft: 20, textAlign: 'end' }}
          className={className}
        >
          {value}
        </span>
      </div>
    );

    const { referres, user, userBookings, userGiftCards } = userDetails;
    const userAcceptedBookings = userBookings.filter(
      (booking) => booking.status === BookingStatus.Accepted
    );
    const { gender, firstName, lastName, id, deletedAt, referralCode } =
      userDetails?.user;

    const filteredReferrees = showAllReferrees
      ? referres
      : referres.slice(0, MAX_SHOWN_REFEREES);

    const getUserName = () => {
      if (!firstName || !lastName) {
        return `#${id}`;
      }

      return gender === undefined || gender === 'Other'
        ? `${firstName} ${lastName}`
        : `${gender} ${firstName} ${lastName}`;
    };

    const bookingsAmount = userAcceptedBookings.reduce(
      (acc, obj) => acc + obj.amount,
      0
    );

    const filteredGiftCards = userGiftCards.filter((giftCard) =>
      GIFT_CARD_PAYED_STATUSES.includes(giftCard.status)
    );

    const deleteConfirmTitle = 'User deletion is a definitive action';
    const User = (
      <div className="bloc">
        <div className="header-title-button">
          <div className="title-with-button">Profile</div>
          {!deletedAt && (
            <div className="delete-user">
              <Popconfirm
                title={deleteConfirmTitle}
                placement="bottomRight"
                onConfirm={handleDeleteUser}
                okText="Yes"
                cancelText="No"
              >
                <Button>Delete</Button>
              </Popconfirm>
            </div>
          )}
        </div>

        <div className="user-content">
          {user && user.avatarPath ? (
            <Pic
              className="avatar"
              style={{ borderRadius: '50%' }}
              pictureId={user.avatarPath}
            />
          ) : (
            <DefaultProfile />
          )}
          <div className="details">
            <div className="name">{getUserName()}</div>
            {user && user.birthDate && (
              <div className="birth-date">
                {formatDate(new Date(user.birthDate), 'dd/MM/yyy ')}
              </div>
            )}
          </div>
        </div>
        <div className="referral-code">
          {line('Referral code', <span>{referralCode}</span>)}
        </div>
      </div>
    );

    const Contact =
      user.email || user.phoneNumber ? (
        <div className="bloc">
          <div className="title">Contact</div>
          <div className="generic-content">
            {user.email && line('E-mail', user.email, 'e-mail')}
            {user.phoneNumber && line('Tel', user.phoneNumber)}
          </div>
        </div>
      ) : undefined;

    const Settings = (
      <div className="bloc">
        <div className="title">Settings</div>
        <div className="generic-content">
          {line('Club', user.club)}
          {line('Locality', user.locality)}
          {line('Language', user.langage)}
        </div>
      </div>
    );

    const Logs = (
      <div className="small bloc">
        <div className="title">Logs</div>
        <div className="generic-content">
          {line('ID', user.id)}
          {line('Role', _.capitalize(user.role))}
          {line('Created at', dateAndHour(user.createdAt))}
          {user.lastLogin && line('Last log-in', dateAndHour(user.lastLogin))}
        </div>
      </div>
    );

    const bookingsAverage = bookingsAmount / userAcceptedBookings.length;

    const SCORE_FIELDS = [
      'experienceScore',
      'serviceScore',
      'vibeScore',
      'roomScore',
      'breakfastScore',
      'globalScore',
    ];

    const scoresMeans = userBookings.flatMap((booking) => {
      const filteredKeys = SCORE_FIELDS.filter(
        // @ts-ignore
        (key) => booking[key] !== undefined
      );
      // @ts-ignore
      const filteredScores = filteredKeys.map((key) => booking[key]);

      if (!filteredScores.length) {
        return [];
      }

      const filteredMean = mean(filteredScores);

      return [filteredMean];
    }, []);

    const ratingMean = mean(scoresMeans);

    const getLink = (id: number) => (
      <a
        href={`/users/${`${id}`}/edit`}
        style={{ color: '#2D2D2D', textDecoration: 'underline' }}
      >
        {id}
      </a>
    );

    const Activity = (
      <div className="small bloc">
        <div className="title">Activity</div>
        <div className="generic-content">
          {line('Staycations', userAcceptedBookings.length)}
          {scoresMeans.length > 0 &&
            line('Average rating', `${ratingMean.toFixed(2)}`)}
          {userBookings.length > 0 &&
            line('Average amount', formatCurrency(bookingsAverage, currency))}
          {line('Total spending', formatCurrency(bookingsAmount, currency))}
          {user.referrerId !== undefined &&
            line('Referrer', getLink(user.referrerId))}
          {line(
            'Referrees',
            <span style={{ fontWeight: 500 }}>{referres.length}</span>
          )}
          {filteredReferrees.map((id) => (
            <div className="line" style={{ marginTop: 0 }} key={`${id}`}>
              <span className="left-title" />
              <span style={{ marginLeft: 20, textAlign: 'end' }}>
                {getLink(id)}
              </span>
            </div>
          ))}
          {referres.length > MAX_SHOWN_REFEREES && (
            <div className="line" style={{ marginTop: 10 }}>
              <span className="left-title" />
              <Button onClick={() => setShowAllReferrees(!showAllReferrees)}>
                {showAllReferrees ? 'Show less' : 'View more'}
              </Button>
            </div>
          )}
        </div>
      </div>
    );

    const renderEdit = (
      <div className="user-edit-btn">
        <Button size="large" onClick={() => setEditModalVisible(true)}>
          Edit
        </Button>
      </div>
    );

    return (
      <Layout className="user-details">
        <PageHeader
          className="header"
          onBack={back}
          title={`User ${user.id}`}
          extra={!deletedAt && renderEdit}
        />
        <div className="body">
          <div className="left-part">
            {User}
            {Contact}
            {Settings}
          </div>
          <div className="right-part">
            <div className="row">
              {Logs}
              {Activity}
            </div>
            <BookingsLinked bookings={userBookings} />
            <GiftCardsLinked giftCards={filteredGiftCards} />
          </div>
        </div>
        <EditUserModal
          visible={editModalVisible}
          onSave={handleSaveUser}
          onCancel={() => setEditModalVisible(false)}
          roles={USER_ROLES}
          userId={userId}
        />
      </Layout>
    );
  }

  return null;
};

type Props = {
  history: { goBack: () => void };
  match: {
    params: {
      id?: string;
    };
  };
};

export default UserDetails;
