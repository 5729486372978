import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { FormUser } from 'app/typings';
import { apiMutation } from 'app/utils/request/api';

import { userDetailPath } from './useGetUsers';

const savePath = 'admin/users/:id/account' as const;
const deletePath = 'admin/users/:id' as const;

export const useSaveUser = () => {
  const queryClient = useQueryClient();

  return useMutation<number, unknown, FormUser, unknown>({
    mutationFn: async (payload) =>
      apiMutation(
        'PUT',
        {
          path: savePath,
          params: { id: payload.id.toString() },
        },
        payload
      ),
    onSuccess: (_, payload) => {
      message.success(`User ${payload.id} saved`);
      queryClient.invalidateQueries([userDetailPath, { id: payload.id }]);
    },
    onError: (_, payload) => {
      message.error(`Error while saving user ${payload.id}`);
    },
  });
};

export function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) =>
      apiMutation('DELETE', {
        path: deletePath,
        params: { id: id.toString() },
      }) as Promise<void>,
    onSuccess: (_, id) => {
      message.success(`User ${id} deleted`);
      queryClient.invalidateQueries([userDetailPath, { id }]);
    },
    onError: () => {
      message.error('Error deleting user data');
    },
  });
}
