import { Col, Row } from 'antd';

import { BookingDetails } from 'app/typings/extranetBookings/booking';
import { formatDayMonthWithHoursMinutes } from 'app/utils/dates';
import { formatPaxAsString } from 'app/utils/pax';

import RenderInfo from '../RenderInfo/RenderInfo';

import './BookingInfo.scss';

type BookingInfoProps = {
  booking: BookingDetails;
};

const BookingInfo = ({ booking }: BookingInfoProps) => (
  <>
    <Row gutter={[24, 16]} justify="space-between" className="booking-info-row">
      <Col span={8}>
        <RenderInfo title="Guests" content={formatPaxAsString(booking.pax)} />
      </Col>
      <Col span={8}>
        <RenderInfo
          title="Check-in"
          content={formatDayMonthWithHoursMinutes(booking.checkin)}
        />
      </Col>
      <Col span={8}>
        <RenderInfo
          title="Check-Out"
          content={formatDayMonthWithHoursMinutes(booking.checkout)}
        />
      </Col>
    </Row>
    <Row gutter={[24, 16]} justify="space-between" className="booking-info-row">
      <Col span={8}>
        <RenderInfo title="Booking ID" content={booking.code} />
      </Col>
      <Col span={8}>
        <RenderInfo
          title="Phone number"
          content={booking.customer.phoneNumber}
        />
      </Col>
      <Col span={8} />
    </Row>
  </>
);

export default BookingInfo;
