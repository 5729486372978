import { Layout, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import ListPage from 'app/components/lists/ListPage';
import Pagination from 'app/components/lists/Pagination';
import Header from 'app/components/lists/TableHeader';
import { dateTimeCell2 } from 'app/components/lists/cells';
import { PAGE_LIMIT } from 'app/components/lists/constants';
import { useClubs } from 'app/hooks/data/useClubs';
import { useGetUsers } from 'app/hooks/data/users/useGetUsers';
import { USER_ROLES, UserList, UserRole } from 'app/typings/user';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import { SortOrder, formatSortingForAPI, getSortOrder } from 'app/utils/sort';
import { capitalizeFirstLetter } from 'app/utils/string';
import { toSnakeCase } from 'app/utils/strings';

import 'styles/lists/left-right-header.scss';
// TODO: use it's own css or use shared stylesheet
import 'styles/pages/Booking/booking.scss';

type UsersListQueryParam = {
  search: string;
  sortOrder: SortOrder;
  sortingField: string;
  clubSorting: string[];
  roleSorting: string[];
  pageNumber: number;
};

const queryParamKeys = {
  search: 's',
  sortOrder: 'so',
  sortingField: 'sf',
  clubSorting: 'cls',
  roleSorting: 'rs',
  pageNumber: 'p',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  createdAtSorting: getSortOrder,
  firstNameSorting: getSortOrder,
  lastNameSorting: getSortOrder,
  clubSorting: parseArrayParam((v) => v),
  roleSorting: parseArrayParam((v) => v),
};

const UsersList = () => {
  const history = useHistory();

  const { data: clubs } = useClubs();

  const queryParamInit = useMemo(
    () =>
      ({
        sortingField: 'createdAt',
        sortOrder: 'descend',
        clubSorting: clubs?.map((c) => c.id.toString()),
        roleSorting: USER_ROLES,
        pageNumber: 1,
      } as UsersListQueryParam),
    [clubs]
  );

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<UsersListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const pageNumber = queryParam.pageNumber || 1;

  const formatSorting = useCallback(() => {
    if (!queryParam.sortOrder) return undefined;

    return `${toSnakeCase(queryParam.sortingField)}:${formatSortingForAPI(
      queryParam.sortOrder
    )}`;
  }, [queryParam.sortOrder, queryParam.sortingField]);

  const debounceSearch = useDebounce(queryParam.search);
  const makeParams = useCallback(() => {
    if (clubs && clubs?.length > 0) {
      const clubsParam = !!queryParam.clubSorting?.length
        ? queryParam.clubSorting
        : queryParamInit.clubSorting;

      return {
        sorting: formatSorting() || undefined,
        clubs: clubsParam ?? [],
        roles: queryParam.roleSorting || [],
        offset: (pageNumber - 1) * PAGE_LIMIT,
        search: debounceSearch,
      };
    }

    return undefined;
  }, [
    clubs,
    debounceSearch,
    formatSorting,
    pageNumber,
    queryParam.clubSorting,
    queryParam.roleSorting,
    queryParamInit.clubSorting,
  ]);

  const { data: users, isLoading } = useGetUsers(makeParams());

  const handleOffset = (x: number) => {
    updateQueryParam({
      pageNumber: pageNumber + x,
    });
    scrollToTop();
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search,
      pageNumber: pageNumber,
    });
  };

  const handleRowActions = ({ id }: UserList) => ({
    onClick: () => history.push(`users/${id}/edit`),
  });

  return (
    <ListPage title="Users">
      <Layout className="booking">
        <Table
          title={() => (
            <Header
              handleSearch={handleSearch}
              searchValue={queryParam.search || ''}
              pageNumber={pageNumber}
              pageResultsLength={users?.length ?? 0}
              handleOffset={handleOffset}
            />
          )}
          loading={isLoading}
          onRow={handleRowActions}
          dataSource={users ?? []}
          pagination={false}
          rowKey="id"
          onChange={(__, filter, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            const roleSorting = (filter.role || []) as string[];
            const clubSorting = (filter.clubId || []) as string[];

            updateQueryParam({
              sortOrder: order,
              sortingField: field,
              roleSorting,
              clubSorting,
              pageNumber: 1,
            });
          }}
        >
          <Column
            key="id"
            title={<div style={{ paddingLeft: 16, minWidth: 92 }}>ID</div>}
            dataIndex="id"
            render={(elem) => <div style={{ fontWeight: 500 }}>{elem}</div>}
          />

          <Column
            key="createdAt"
            title="Created at"
            sorter
            dataIndex="createdAt"
            defaultSortOrder={'descend'}
            render={dateTimeCell2}
          />

          <Column
            key="firstName"
            title="Firstname"
            sorter
            dataIndex={'firstName'}
          />

          <Column
            key="lastName"
            title="Lastname"
            sorter
            dataIndex={'lastName'}
          />

          <Column key="email" title="Email" dataIndex={'email'} />

          <Column
            key="role"
            title="Role"
            dataIndex="role"
            filters={[
              { text: 'Superadmin', value: UserRole.SuperAdmin },
              { text: 'Admin', value: UserRole.Admin },
              { text: 'User', value: UserRole.User },
            ]}
            filteredValue={queryParam.roleSorting || []}
            render={capitalizeFirstLetter}
          />

          <Column
            key="clubId"
            title="Club"
            dataIndex="clubId"
            filters={clubs?.map((c) => ({
              text: c.name,
              value: c.id.toString(),
            }))}
            filteredValue={queryParam.clubSorting || []}
            defaultFilteredValue={clubs?.map((c) => c.id.toString())}
            render={(clubId) => clubs?.find((club) => club.id === clubId)?.name}
          />
        </Table>
        <Pagination
          className="booking__footer"
          pageNumber={pageNumber}
          pageResultsLength={users?.length ?? 0}
          handleOffset={handleOffset}
        />
      </Layout>
    </ListPage>
  );
};

export default UsersList;
