import { Button, Form, Layout, Menu, Popconfirm, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { SDetailLayout } from 'app/components/StaycationUI';
import Delete from 'app/components/commons/ExtraButtons/Delete/Delete';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import {
  useDeleteHotel,
  useDuplicateHotel,
  useGetHotel,
  useUpdateHotel,
} from 'app/hooks/data/hotels/useHotel';
import { ChannelManager } from 'app/typings/channelManagers';
import { Hotel } from 'app/typings/hotels/Hotel/Hotel';
import { RateMode } from 'app/typings/rateModes';

import MarketingBrief from '../HotelDetail/MarketingBrief/MarketingBrief';
import Services from '../HotelDetail/Services/Services';
import Spaces from '../HotelDetail/Spaces/Spaces';

import BasicInfo from './BasicInfo';
import Financial from './Financial';
import './HotelDetail.scss';

const { Content } = Layout;

enum MenuItemName {
  General = 'General',
  Services = 'Services',
  Spaces = 'Spaces',
  Brief = 'Brief',
}

type MenuItem = {
  name: MenuItemName;
};

const MENU_ITEMS = [
  { name: MenuItemName.General },
  { name: MenuItemName.Services },
  { name: MenuItemName.Spaces },
  { name: MenuItemName.Brief },
];

type MenuCustomProps = {
  menuItems: MenuItem[];
  selectedMenu: MenuItem;
  setSelectedMenu: (menuItem: MenuItem) => void;
};

const MenuCustom = ({
  menuItems,
  selectedMenu,
  setSelectedMenu,
}: MenuCustomProps) => (
  <Menu
    mode="horizontal"
    onClick={({ key }) => setSelectedMenu(menuItems[+key])}
    selectedKeys={[
      menuItems.findIndex((item) => item.name === selectedMenu.name).toString(),
    ]}
  >
    {menuItems.map((item, index: number) => (
      <Menu.Item key={index}>{item.name}</Menu.Item>
    ))}
  </Menu>
);

const HotelEdit = () => {
  const { id: hotelId } = useParams<{ id: string }>();
  const history = useHistory();
  const {
    data: hotel,
    isFetching: isFetchHotelLoading,
    refetch: reloadHotel,
  } = useGetHotel(+hotelId);
  const { mutateAsync: updateHotel } = useUpdateHotel();
  const { mutateAsync: deleteHotel } = useDeleteHotel(hotelId);
  const { mutateAsync: duplicateHotel } = useDuplicateHotel(hotelId);
  const [originalBillingEmails, setOriginalBillingsEmails] = useState(
    hotel?.billingEmails
  );

  const [form] = Form.useForm();

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [menu, setMenu] = useState<MenuItem>(MENU_ITEMS[0]);

  const toggleMode = useCallback(() => {
    setMode(mode === 'view' ? 'edit' : 'view');
  }, [setMode, mode]);

  useEffect(() => {
    if (!isFetchHotelLoading) {
      setOriginalBillingsEmails(hotel?.billingEmails);
    }
  }, [isFetchHotelLoading, hotel?.billingEmails]);

  const renderMenu = () => (
    <MenuCustom
      menuItems={MENU_ITEMS}
      selectedMenu={menu}
      setSelectedMenu={setMenu}
    />
  );

  const dispatchMenuSection = () => {
    switch (menu.name) {
      case 'Services':
        return (
          <Services
            hotel={hotel}
            headerMenu={renderMenu()}
            reloadHotel={reloadHotel}
          />
        );
      case 'Spaces':
        return (
          <Spaces
            hotel={hotel}
            headerMenu={renderMenu()}
            reloadHotel={reloadHotel}
          />
        );
      case 'Brief':
        return (
          <MarketingBrief
            hotel={hotel}
            headerMenu={renderMenu()}
            reloadHotel={reloadHotel}
          />
        );
      default:
        return null;
    }
  };

  const handleSubmit = useCallback(
    async (values: Hotel) => {
      const emails = values.notifEmails.map(({ email }) => email);
      const hasDuplicateEmails = new Set(emails).size !== emails.length;

      if (hasDuplicateEmails) {
        message.error('Duplicate emails not allowed');

        return;
      }

      await updateHotel({
        ...hotel,
        ...values,
        location: {
          ...hotel?.location,
          ...values.location,
        },
        address: {
          ...hotel?.address,
          ...values.address,
          subCity:
            values.address?.subCity === ''
              ? undefined
              : values.address?.subCity,
        },
        notifEmails: values.notifEmails,
        channelManager:
          values.channelManager === ChannelManager.NONE
            ? undefined
            : values.channelManager,
        rateMode:
          values.channelManager === undefined ||
          values.channelManager === ChannelManager.NONE
            ? undefined
            : values.rateMode || RateMode.MULTI_RATE,
      });

      await reloadHotel();

      toggleMode();
    },
    [updateHotel, hotel, reloadHotel, toggleMode]
  );

  const submitForm = () => form.submit();

  if (!hotel || hotelId !== `${hotel.id}`) {
    return null;
  }

  const renderDeleteButton = () => (
    <Delete
      key={'delete-button'}
      entityDescription={`Hotel #${hotelId}`}
      onDelete={async () => {
        await deleteHotel();
        history.push('/hotels');
      }}
    />
  );

  const renderDuplicateButton = () => (
    <div key={'duplicate-button'} className="duplicate-btn">
      <Popconfirm
        title={`Are you sure you want to duplicate this hotel ? (This action is irrevocable)`}
        placement="bottomRight"
        onConfirm={async () => {
          const { hotelId } = await duplicateHotel();

          history.push(`/hotels/${hotelId}/edit`);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button size="large">Duplicate</Button>
      </Popconfirm>
    </div>
  );

  return (
    <Layout className="hotel-detail">
      <FormLegacyRenderProp
        form={form}
        onReset={() => form.resetFields()}
        onFinish={handleSubmit}
        initialValues={hotel}
      >
        {(values, { resetFields, getFieldsError, setFieldsValue }) =>
          menu.name === 'General' ? (
            <SDetailLayout
              isDirty
              title={hotel.name}
              mode={mode}
              onEdit={toggleMode}
              onSave={submitForm}
              onCancel={toggleMode}
              reset={resetFields}
              headerMenu={renderMenu()}
              saveDisabled={
                !getFieldsError().every(({ errors }) => errors.length === 0) ||
                values.notifEmails.every(
                  ({ bookingConfirmation }) => !bookingConfirmation
                )
              }
              headerExtra={[renderDuplicateButton(), renderDeleteButton()]}
            >
              <Content className="hotel-detail__content">
                <BasicInfo
                  mode={mode}
                  values={values}
                  setFieldsValue={setFieldsValue}
                  originalBillingEmails={originalBillingEmails}
                />
                <Financial
                  mode={mode}
                  countryId={form.getFieldValue(['address', 'countryId'])}
                />
              </Content>
            </SDetailLayout>
          ) : (
            dispatchMenuSection()
          )
        }
      </FormLegacyRenderProp>
    </Layout>
  );
};

export default HotelEdit;
