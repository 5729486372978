import { Form } from 'antd';

import { Option, Select } from 'app/components/fields/Select';

import '../GiftCardEdit.scss';

const ColorFormItem = () => (
  <div className="input-row">
    <Form.Item
      name="color"
      label="Color"
      rules={[{ required: true, message: 'Required' }]}
    >
      <Select placeholder="Select a color" optionFilterProp="children">
        <Option key="blue" value="blue">
          Blue
        </Option>
        <Option key="pink" value="pink">
          Pink
        </Option>
        <Option key="white" value="white">
          White
        </Option>
      </Select>
    </Form.Item>
  </div>
);

export default ColorFormItem;
