import { Button, Form, StepProps, Switch } from 'antd';
import React, { useState } from 'react';

import {
  addMissingDates,
  useInventoryScrapping,
} from 'app/hooks/data/autoInventory/useScrapping';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

import {
  FormValues,
  checkIfBarIsValid,
  checkIfDiscountIsValid,
  checkIfRoomMappingIsValid,
} from '../Utils';

type FooterProps = {
  setCurrentStep: (step: number) => void;
  setAlertModalOpen: (value: boolean) => void;
  setScrappingInventory: (value: any) => void;
  setIsAlertForDisconnect: (value: boolean) => void;
  setFormData: React.Dispatch<React.SetStateAction<FormValues>>;
  currentStep: number;
  hasAutoInventoryActivated: boolean;
  values: FormValues;
  items: StepProps[];
  hotelId: number;
  discountStepHasError: boolean;
  setIsModalClosedBlock: (value: boolean) => void;
};

export const Footer = ({
  setCurrentStep,
  setScrappingInventory,
  setAlertModalOpen,
  setIsAlertForDisconnect,
  currentStep,
  hasAutoInventoryActivated,
  values,
  items,
  setFormData,
  hotelId,
  discountStepHasError,
  setIsModalClosedBlock,
}: FooterProps) => {
  const { track } = useAmplitude();
  const form = Form.useFormInstance();
  const [buttonLoading, setButtonLoading] = useState(false);

  const { getInventoryFromScrapper } = useInventoryScrapping();

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
    track('Click on previous step', { step: items[currentStep - 1].title });
  };

  const handleNextStep = async () => {
    if (currentStep === 2) {
      setButtonLoading(true);

      const fetchedInventory = await getInventoryFromScrapper(hotelId);

      if (fetchedInventory) {
        const fullInventory = addMissingDates(fetchedInventory);

        setButtonLoading(false);
        setScrappingInventory(fullInventory);
      }
    }

    setCurrentStep(currentStep + 1);
    track('Click on next step', { step: items[currentStep + 1].title });

    setFormData((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const checkIfNextButtonEnable = (step: number, values: FormValues) => {
    switch (step) {
      case 0:
        return checkIfRoomMappingIsValid(values);

      case 1:
        return checkIfBarIsValid(values);

      case 2:
        return checkIfDiscountIsValid(values, discountStepHasError);

      case 3:
        return checkIfRoomMappingIsValid(values);

      default:
        return false;
    }
  };

  const onClickSubmit = () => {
    track('Save Auto inventory');
    setButtonLoading(true);
    setIsModalClosedBlock(true);
    form.submit();
  };

  return (
    <div className="footer">
      <div>
        {currentStep > 0 && (
          <Button type="default" onClick={handlePreviousStep}>
            Back
          </Button>
        )}
      </div>
      {buttonLoading && (
        <p>This action could take a few seconds, please wait...</p>
      )}

      <div>
        {hasAutoInventoryActivated && (
          <Button
            type="default"
            className="disconnectButton"
            onClick={() => {
              track('Click on deactivate');
              setAlertModalOpen(true);
              setIsAlertForDisconnect(true);
            }}
          >
            Deactivate
          </Button>
        )}
        {currentStep === items.length - 1 ? (
          <>
            {currentStep === 3 &&
              !hasAutoInventoryActivated &&
              !buttonLoading && (
                <>
                  <div className="override-button ">Override manual data</div>
                  <Form.Item name={'overrideOpenings'} noStyle>
                    <Switch
                      className="override-button "
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          overrideOpenings: value,
                        }));
                      }}
                    />
                  </Form.Item>
                </>
              )}
            <Button
              type="primary"
              onClick={onClickSubmit}
              loading={buttonLoading}
            >
              {hasAutoInventoryActivated ? 'Update' : 'Activate'}
            </Button>
          </>
        ) : (
          <Button
            type="primary"
            onClick={handleNextStep}
            loading={buttonLoading}
            disabled={!checkIfNextButtonEnable(currentStep, values)}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};
