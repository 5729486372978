import { useContext } from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { IHotel, Room } from 'app/typings';
import { formatDateWithoutHour } from 'app/utils/dates';
import { assertNever } from 'app/utils/typing';

import { AutoInventoryModal } from '../../../ExtranetInventory/AutoInventory/AutoInventoryModal/AutoInventoryModal';
import { PricingRule } from '../../../ExtranetInventory/RoomRow/AutomatedDiscount/PricingRule';
import { Inventory } from '../../../ExtranetInventory/Types';
import { ExtraAddOn } from '../../ExtraAddOn/ExtraAddOn';
import { ExtraBedModal } from '../../ExtraBed/Modal/ExtraBedModal';
import { ExtraGuestModal } from '../../ExtraGuest/ExtraGuest';
import { BedTaskInformation, Task, TaskName } from '../Types';

type RenderModalProps = {
  task: Task;
  onCompleteTask: () => void;
  rooms?: Room[];
  currentRange: Interval;
  hotel: IHotel;
  inventory: Inventory | undefined;
};

const RenderModal = ({
  task,
  onCompleteTask,
  rooms,
  currentRange,
  hotel,
  inventory,
}: RenderModalProps) => {
  const { openTask, clearOpenTask } = useContext(TaskCenterContext);

  switch (task.taskName) {
    case TaskName.LastMinutePricingRule:
    case TaskName.PricingRule:
      return inventory ? (
        <PricingRule
          onClose={() => clearOpenTask()}
          hotelId={hotel.id}
          autoDiscountRate={hotel.autoDiscountRate}
          lastMinuteAutoDiscountRate={hotel.lastMinuteAutoDiscountRate}
          lastMinuteAutoDiscountDays={hotel.lastMinuteAutoDiscountDays}
          inventory={inventory}
          startCurrentRange={currentRange.start}
          isOpen={task.taskName === openTask}
          onCompleteTask={onCompleteTask}
        />
      ) : (
        <></>
      );
    case TaskName.BabyCot:
    case TaskName.GuestBed:
      return rooms ? (
        <ExtraBedModal
          hotel={hotel}
          bedTypeId={(task as Extract<Task, BedTaskInformation>).categoryId}
          rooms={rooms}
          isOpen={task.taskName === openTask}
          onCompleteTask={onCompleteTask}
        />
      ) : (
        <></>
      );
    case TaskName.AttractExtraGuests:
    case TaskName.ConfigureChildPrice:
    case TaskName.AttractSingleGuests:
      return rooms ? (
        <CurrencyWrapper hotelId={hotel.id} hotel={hotel}>
          <ExtraGuestModal
            hotel={hotel}
            taskName={task.taskName}
            isOpen={task.taskName === openTask}
            onCompleteTask={onCompleteTask}
            rooms={rooms}
            childrenAllowed={inventory?.pkg.childrenAllowed}
          />
        </CurrencyWrapper>
      ) : (
        <></>
      );

    case TaskName.EarlyCheckin:
    case TaskName.LateCheckout:
      return (
        <CurrencyWrapper hotelId={hotel.id} hotel={hotel}>
          <ExtraAddOn
            hotel={hotel}
            isOpen={task.taskName === openTask}
            task={task}
            onCompleteTask={onCompleteTask}
          />
        </CurrencyWrapper>
      );
    case TaskName.AutoInventory:
      return (
        <CurrencyWrapper hotelId={hotel.id} hotel={hotel}>
          {inventory && (
            <AutoInventoryModal
              hotel={hotel}
              inventory={inventory}
              hasAutoInventoryActivated={hotel.stockScrapperEnabled ?? false}
              onClose={clearOpenTask}
              isAutoInventoryModalOpen={task.taskName === openTask}
              startDate={formatDateWithoutHour(new Date(currentRange.start))}
            />
          )}
        </CurrencyWrapper>
      );
    case TaskName.VirtualCards:
      return <></>;
    default:
      assertNever(task.taskName);
  }
};

export default RenderModal;
