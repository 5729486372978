import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useMemo } from 'react';

import {
  GiftCard,
  GiftCardDetails,
  GiftCardListParams,
} from 'app/typings/giftCard';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/gift-cards' as const;

export const pathDetails = '/admin/gift-cards/:code' as const;

export function useGetGiftCards(giftCardListParams: GiftCardListParams) {
  const stringifiedParams = queryString.stringify(giftCardListParams);

  const key = useMemo(() => {
    return [
      path,
      {
        giftCardListParams,
      },
    ];
  }, [giftCardListParams]);

  return useQuery({
    queryKey: key,
    queryFn: () =>
      apiRequest({ path: `${path}?${stringifiedParams}` }) as Promise<
        GiftCard[]
      >,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export function useGiftCardDetails(code: string) {
  return useQuery({
    queryKey: [pathDetails, { code }],
    queryFn: () =>
      apiRequest({
        path: pathDetails,
        params: { code },
      }) as Promise<GiftCardDetails>,
    staleTime: Infinity,
  });
}
