import { ShoppingOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import VCCBookingTuto from 'assets/VCCBookingTuto.png';

import { TextWithIcon } from 'app/components/commons/TextWithIcon/TextWithIcon';

export const VCCBookingStep = () => {
  return (
    <div className="content-wrapper">
      <div className="grey-square">
        <TextWithIcon
          icon={<ShoppingOutlined />}
          text={<span className="font-weight-600">Bookings</span>}
        />
        <>
          <p className="content-text-line">
            For each booking, a VCC (Virtual Credit Card) will be issued with
            the{' '}
            <span className="font-weight-600">
              net amount after Staycation commission.
            </span>
          </p>
          <p className="content-text-line">
            It will be{' '}
            <span className="font-weight-600">
              active on the day of check-in{' '}
            </span>
            and for 6 months. Use your Payment Terminal to receive the VCC
            payment.
          </p>
        </>
      </div>
      <div>
        <Divider className="img-divider"></Divider>
        <img
          className="width-100"
          src={VCCBookingTuto}
          alt="Virtual Cards Booking tutorial"
        />
      </div>
    </div>
  );
};
