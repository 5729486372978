import { Menu, MenuProps } from 'antd';
import { useEffect, useState } from 'react';

import { BookingDetails } from 'app/typings/extranetBookings/booking';

import type { BookingDetailAnchor } from '../../Types';

type BookingDetailHeaderProps = {
  booking: BookingDetails;
  scrollVCC: boolean;
};

enum BookingMenuItemKey {
  Booking = 'booking',
  PaymentDetails = 'payment-details',
  Help = 'help',
  VirtualCard = 'virtual-card',
}

const BookingDetailHeader = ({
  booking,
  scrollVCC,
}: BookingDetailHeaderProps) => {
  const vccItems = [
    { label: 'Virtual Card', key: BookingMenuItemKey.VirtualCard },
    { label: 'Help', key: BookingMenuItemKey.Help },
  ];

  const items = [
    { label: 'Booking', key: BookingMenuItemKey.Booking },
    { label: 'Payment details', key: BookingMenuItemKey.PaymentDetails },
    ...(booking.issuingCardId ? vccItems : []),
  ];

  const [currentAnchor, setCurrentAnchor] = useState<BookingDetailAnchor>(
    BookingMenuItemKey.Booking
  );

  const onClick: MenuProps['onClick'] = (e) => {
    const element = document.getElementById(e.key);

    setCurrentAnchor(e.key as BookingDetailAnchor);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    if (document.readyState !== 'complete' || !scrollVCC) {
      return;
    }

    const element = document.getElementById(BookingMenuItemKey.VirtualCard);

    if (element) {
      setCurrentAnchor(BookingMenuItemKey.VirtualCard);
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollVCC]);

  return (
    <Menu
      onClick={onClick}
      mode="horizontal"
      items={items}
      selectedKeys={[currentAnchor]}
      className="booking-detail-menu"
    />
  );
};

export default BookingDetailHeader;
