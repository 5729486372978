import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, PageHeader, Table } from 'antd';
import fileDownload from 'js-file-download';
import { useState } from 'react';

import { renderBillsColumnStatus } from 'app/components/commons/Bills/Bills';
import Pagination from 'app/components/lists/Pagination';
import Header from 'app/components/lists/TableHeader';
import { PriceCell } from 'app/components/lists/cells';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import { useDowloadBill } from 'app/hooks/data/bills/useDowloadBill';
import {
  GetBillsQueryParams,
  useGetBills,
} from 'app/hooks/data/bills/useGetBills';
import { PSP } from 'app/typings/PSP';
import { Bill, PayoutStatus } from 'app/typings/bills';
import { dateAndHour, formatDateWithoutHour } from 'app/utils/dates';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import { isProvider } from 'app/utils/providers';
import { formatPSP } from 'app/utils/psp';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
  isValidSortOrder,
} from 'app/utils/sort';

import './Bills.scss';

export type BillsQueryParam = {
  search: string;
  pageNumber: number;
  dateSorting: SortOrder;
  psp: PSP[];
};

const { Content } = Layout;
const { Column } = Table;

const PAGE_SIZE = 50;

const getProvider = (maybeProvider: string): PSP | undefined => {
  return isProvider(maybeProvider) ? maybeProvider : undefined;
};

const providerDefaultValue: PSP[] = [PSP.Adyen, PSP.Stripe, PSP.StripeIssuing];
const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
  psp: providerDefaultValue,
} as const;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
  psp: 'pr',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
  psp: parseArrayParam(getProvider),
};

const formatDate = (date: string) => {
  return date && dateAndHour(date);
};

const makePayload = (
  queryParam: Partial<BillsQueryParam>
): GetBillsQueryParams => {
  const validSortOrder = isValidSortOrder(queryParam.dateSorting);

  return {
    search: queryParam.search,
    sortingColumn: validSortOrder ? 'createdAt' : undefined,
    sortingOrder: validSortOrder
      ? formatSortingForAPI(queryParam.dateSorting)
      : undefined,
    psp: queryParam.psp,
    offset: ((queryParam.pageNumber || 1) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  };
};

export const Bills = () => {
  const { queryParam, updateQueryParam } =
    useQueryParamHistory<BillsQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );
  const [currentBillId, setCurrentBillId] = useState<number | undefined>(
    undefined
  );

  const { isLoading: isDownloading, refetch: downloadBill } =
    useDowloadBill(currentBillId);

  const debouncedQueryParam = useDebounce(queryParam, 500);

  const { data: bills, isFetching: isLoading } = useGetBills(
    makePayload(debouncedQueryParam)
  );

  const pageNumber = queryParam.pageNumber || 1;

  const handleDownload = async (billId: number) => {
    if (!isDownloading) {
      await setCurrentBillId(billId);

      const { data: dowloadedBillPdf } = await downloadBill();

      dowloadedBillPdf !== undefined &&
        fileDownload(
          dowloadedBillPdf.pdf,
          dowloadedBillPdf.filename,
          'application/pdf'
        );
      setCurrentBillId(undefined);
    }
  };

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam?.pageNumber || 1) + x });

    scrollToTop();
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const handleProviderQuery = (providers: PSP[]) => {
    updateQueryParam({
      psp: providers,
      pageNumber: 1,
    });
  };

  const handleProviderFilter = (providers: PSP[]) => {
    console.log(providers);

    handleProviderQuery(!!providers.length ? providers : providerDefaultValue);
  };

  const handleRowActions = ({ id }: Bill) => ({
    onClick: () => handleDownload(id),
  });

  const actionMenu = ({ id }: Bill) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => handleDownload(id)}>Download</Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  return (
    <Layout className="bills-list">
      <PageHeader className="header" title="Bills" />
      <Content className="body">
        <Table
          dataSource={bills ?? []}
          pagination={false}
          title={() => (
            <Header
              handleSearch={handleSearch}
              searchValue={queryParam.search || ''}
              pageNumber={pageNumber}
              pageResultsLength={bills?.length ?? 0}
              handleOffset={handleOffset}
            />
          )}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { order } = sorter;

            dateSortingHandler(convertFromAntdSortOrder(order));
          }}
        >
          <Column title="Hotel" dataIndex="hotelName" />
          <Column title="Invoice name #" dataIndex="reference" />
          <Column
            title="Total net amount"
            dataIndex="totalAmount"
            render={(price, entity) => (
              <PriceCell price={price} entity={entity} defaultText="Missing" />
            )}
          />
          <Column
            title="Total comission"
            dataIndex="commissionAmount"
            render={(price, entity) => (
              <PriceCell price={price} entity={entity} defaultText="Missing" />
            )}
          />
          <Column
            title="Creation date"
            dataIndex="createdAt"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={formatDate}
          />

          <Column
            title="Period"
            render={({ fromDate, toDate }) => {
              if (fromDate && toDate) {
                const from = formatDateWithoutHour(new Date(fromDate));
                const to = formatDateWithoutHour(new Date(toDate));

                return `${from} - ${to}`;
              }

              return null;
            }}
          />
          <Column
            title={
              <ModalCheckBox
                title="Provider"
                defaultValue={providerDefaultValue}
                onChange={handleProviderFilter}
                items={[
                  {
                    value: PSP.Adyen,
                    label: 'Adyen Payout',
                  },
                  {
                    value: PSP.Stripe,
                    label: 'Stripe Payout',
                  },
                  {
                    value: PSP.StripeIssuing,
                    label: 'Stripe VCC',
                  },
                ]}
                value={queryParam.psp}
              />
            }
            dataIndex="provider"
            render={formatPSP}
          />
          <Column
            title="Payout Status"
            dataIndex="status"
            render={(status: PayoutStatus, bill: Bill) =>
              renderBillsColumnStatus(status, bill, false)
            }
          />
          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        <Pagination
          className="footer"
          pageNumber={pageNumber}
          pageResultsLength={bills?.length ?? 0}
          handleOffset={handleOffset}
        />
      </Content>
    </Layout>
  );
};

export default Bills;
