import RenderInfo from '../../RenderInfo/RenderInfo';

type CardRefundsProps = {
  amount: string;
};

const CardRefunds = ({ amount }: CardRefundsProps) => {
  return (
    <div className="card-refund">
      <RenderInfo
        title="Amount to refund"
        content={amount}
        hasAmountDescription
      />
    </div>
  );
};

export default CardRefunds;
