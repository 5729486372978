import {
  GiftCard,
  InternalGiftCardProperties,
  VoucherGiftCardProperties,
} from 'app/typings/giftCard';
import { capitalizeFirstLetter } from 'app/utils/string';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & (VoucherGiftCardProperties | InternalGiftCardProperties);
};

const VoucherProperties = ({ giftCard }: Props) => {
  const { giftCardType, beneficiaryName, minAmount } = giftCard;

  return (
    <>
      <Line title="Type" value={capitalizeFirstLetter(giftCardType)} />
      <Line title="Beneficiary name" value={beneficiaryName} />
      <Line title="Minimum code use amount" value={minAmount} />
    </>
  );
};

export default VoucherProperties;
