import { Divider, Modal, StepProps, Steps } from 'antd';
import VCCBillingTuto from 'assets/VCCBillingTuto.png';
import VCCBookingTuto from 'assets/VCCBookingTuto.png';
import { useEffect, useState } from 'react';

import './VCCModal.scss';
import { VCCBillingStep } from './_components/Steps/VCCBillingStep';
import { VCCBookingStep } from './_components/Steps/VCCBookingStep';
import { VCCSummaryStep } from './_components/Steps/VCCSummaryStep';
import { VCCModalFooter } from './_components/VCCModalFooter';

const steps: StepProps[] = [
  {
    title: 'Booking',
  },
  {
    title: 'Billing',
  },
  {
    title: 'Summary',
  },
];

const renderSteps = (currentStep: number, hasChannelManager: boolean) => {
  switch (currentStep) {
    case 0:
      return <VCCBookingStep />;
    case 1:
      return <VCCBillingStep />;
    case 2:
      return <VCCSummaryStep hasChannelManager={hasChannelManager} />;
    default:
      return <></>;
  }
};

const preloadImage = (url: string) => {
  const img = new Image();

  img.src = url;
};

type VCCModalProps = {
  onClose: () => void;
  onSubmit: () => Promise<void>;
  activateButtonLoading: boolean;
  hasChannelManager: boolean;
};

export const VCCModal = ({
  onClose,
  onSubmit,
  activateButtonLoading = false,
  hasChannelManager = false,
}: VCCModalProps) => {
  useEffect(() => {
    preloadImage(VCCBillingTuto);
    preloadImage(VCCBookingTuto);
  }, []);

  const [currentStep, setCurrentStep] = useState(0);

  return (
    <Modal
      className="vcc-modal"
      centered
      destroyOnClose={true}
      width={684}
      open={true}
      title={'How does it work ?'}
      footer={
        <VCCModalFooter
          onSubmit={onSubmit}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          steps={steps}
          activateButtonLoading={activateButtonLoading}
        />
      }
      onCancel={onClose}
    >
      <>
        <Steps
          type="navigation"
          size="small"
          current={currentStep}
          items={steps}
        />
        <Divider />
        {renderSteps(currentStep, hasChannelManager)}
      </>
    </Modal>
  );
};
