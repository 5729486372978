import { RuleObject } from 'antd/lib/form';

export const generateVatValidator =
  (countrySlug = 'FR', isVATRequired = false) =>
  (_: RuleObject, value: string) => {
    const isValidVAT =
      vatPatternsByCountrySlug[countrySlug]?.test(value) ?? false;

    if (isValidVAT || (value.length === 0 && !isVATRequired)) {
      return Promise.resolve();
    }

    return Promise.reject('Must be a valid VAT number');
  };

export const generateVatMessage = (countrySlug?: string) => {
  const vatMessageBase = 'Must be a valid VAT number';

  if (countrySlug && !!vatExamplesByCountrySlug[countrySlug]) {
    return `${vatMessageBase} (ex: ${vatExamplesByCountrySlug[countrySlug]})`;
  }

  return vatMessageBase;
};

const vatPatternsByCountrySlug: Record<string, RegExp> = {
  FR: RegExp(/^FR[A-HJ-NP-Z0-9]{2}\d{9}$/),
  GB: RegExp(/^GB\d{9}$|^GB\d{12}$|^GBGD\d{3}$|^GBHA\d{3}$/),
  MC: RegExp(/^FR[A-HJ-NP-Z0-9]{2}\d{9}$/),
  BE: RegExp(/^BE[01]\d{9}$/),
  CH: RegExp(/^(CHE)-?(\d{9}|(\d{3}.\d{3}.\d{3}))( ?)(MWST|TVA|IVA)?$/),
  ES: RegExp(/^ES[A-Z]\d{7}[A-Z]$|^ES[A-Z][0-9]{7}[0-9A-Z]$|^ES[0-9]{8}[A-Z]$/),
  LU: RegExp(/^LU\d{8}$/),
  NL: RegExp(/^NL\d{9}B\d{2}$/),
  PT: RegExp(/^PT\d{9}$/),
  DE: RegExp(/^DE\d{9}$/),
  IT: RegExp(/^IT\d{11}$/),
};

export const vatExamplesByCountrySlug: Record<string, string> = {
  FR: 'FR12345678901',
  GB: 'GB405438043932',
  MC: 'FRAN439464141',
  BE: 'BE0227772522',
  CH: 'CHE771703312',
  ES: 'ESU4797130M',
  LU: 'LU90488433',
  NL: 'NL834525207B55',
  PT: 'PT266930107',
  DE: 'DE962504080',
  IT: 'IT12345678901',
};
