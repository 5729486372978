import {
  BankOutlined,
  CheckCircleOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import { Alert, Col, Layout, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import { useContext, useEffect, useState } from 'react';

import AlertModal from 'app/components/commons/AlertModal/AlertModal';
import { ConfigContext } from 'app/context/ConfigContext/ConfigContext';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { UserContext } from 'app/context/UserContext/UserContext';
import {
  useActivateIssuingForHotel,
  useGetHotel,
} from 'app/hooks/data/hotels/useHotel';
import { useCountries } from 'app/hooks/data/useCountries';
import {
  useAmplitude,
  useAmplitudeViewPage,
} from 'app/hooks/useAmplitude/useAmplitude';
import { useSearchParams } from 'app/utils/searchParams';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import './ExtranetPayment.scss';
import { BankTransferActionButton } from './_components/CardActionButton/BankTransferActionButton';
import { VCCActionButton } from './_components/CardActionButton/VCCActionButton';
import { BankTransferCardContent } from './_components/CardContent/BankTransferCardContent';
import { VCCCardContent } from './_components/CardContent/VCCCardContent';
import { ExtranetPaymentCard } from './_components/ExtranetPaymentCard';
import { PaymentMethodTag } from './_components/PaymentMethodTag';
import { VCCModal } from './_components/VCCModal/VCCModal';
import { getBillingIntervalUnit, getDisplayProps } from './utils';

const { Content } = Layout;
const PAGE = 'Payment';

export const ExtranetPayment = () => {
  const { setPage } = useContext(TaskCenterContext);
  const { getSearchParam } = useSearchParams();
  const hotelId = getSearchParam('hotelId');
  const [VCCModalOpened, setVCCModalOpened] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const {
    data: hotel,
    refetch: reloadHotel,
    isLoading: hotelLoading,
  } = useGetHotel(!!hotelId ? +hotelId : undefined);
  const { data: countries } = useCountries();

  const country = countries?.find((c) => c.id === hotel?.address?.countryId);

  const { mutateAsync: activateIssuing, isLoading: activationInProgress } =
    useActivateIssuingForHotel(hotelId ?? '');

  const userContext = useContext(UserContext);
  const configContext = useContext(ConfigContext);

  useEffect(() => {
    setPage(PAGE);
  });

  useAmplitudeViewPage({ page: PAGE.toLowerCase() });

  const { track } = useAmplitude();

  const onSubmit = async () => {
    track('Click Configure Activate Cards');

    await activateIssuing().then(
      () => {
        setAlertModalOpen(true);
        setVCCModalOpened(false);
      },
      () => setVCCModalOpened(false)
    );
    await reloadHotel();
  };

  const onClose = () => {
    setVCCModalOpened(false);
  };

  if (!hotel || !country) {
    return <></>;
  }

  const unit = getBillingIntervalUnit(hotel.billingInterval);

  const displayProps = getDisplayProps(hotel, country, userContext.user);

  const bankTransferButtonInfo = !displayProps.hotelUser
    ? 'This button is only active for hotels.'
    : displayProps.VCCActive && !displayProps.stripeAccountError
    ? 'To activate this payment method please contact your Account Manager.'
    : undefined;

  const hasChannelManager = !!(
    hotel?.channelManager && hotel?.channelManager !== 'NONE'
  );

  return (
    <Layout className="extranet-payment">
      <ExtranetHeader page={PAGE} />
      <Content className="extranet-payment__content">
        <div>
          <Title className="extranet-payment__title">Payment Methods</Title>
          <Paragraph className="extranet-payment__description">
            Choose the payment method that best suits your establishment's
            needs.
          </Paragraph>
        </div>
        <Row justify={'center'} gutter={[24, 24]}>
          <Col span={12}>
            <ExtranetPaymentCard
              icon={<CreditCardOutlined />}
              title={
                <div>
                  Virtual Credit Cards
                  {!hotel.issuingActivated && (
                    <span className="new-tag">New</span>
                  )}
                </div>
              }
              description="Get paid immediately on the day of check-in."
              content={<VCCCardContent />}
              action={
                <VCCActionButton
                  displayProps={displayProps}
                  setVCCModalOpened={setVCCModalOpened}
                />
              }
              extra={hotel?.issuingActivated && <PaymentMethodTag />}
              disabled={!country?.issuingAllowed}
              active={hotel?.issuingActivated}
            />
          </Col>
          <Col span={12}>
            <ExtranetPaymentCard
              icon={<BankOutlined />}
              title="Bank transfer"
              description={`Get paid at the end of the ${unit}.`}
              content={
                <BankTransferCardContent
                  billingInterval={hotel?.billingInterval}
                />
              }
              errorMessage={
                displayProps.stripeAccountError && (
                  <Alert
                    message={
                      <>
                        <span className="font-weight-600">
                          Action required:
                        </span>{' '}
                        {`${
                          !hotel?.issuingActivated
                            ? 'in order to keep using bank transfers '
                            : ''
                        }please update your
                        Stripe KYC.`}
                      </>
                    }
                    type="error"
                    showIcon
                  />
                )
              }
              action={
                <BankTransferActionButton
                  displayProps={displayProps}
                  apiUrl={configContext?.apiUrl}
                  hotelId={hotelId}
                  token={userContext.token}
                />
              }
              extra={
                !hotel?.issuingActivated &&
                !displayProps.newHotel && <PaymentMethodTag />
              }
              buttonInfo={bankTransferButtonInfo}
              active={!hotel?.issuingActivated && !displayProps.newHotel}
            />
          </Col>
        </Row>
      </Content>
      {VCCModalOpened && (
        <VCCModal
          onSubmit={onSubmit}
          onClose={onClose}
          activateButtonLoading={activationInProgress || hotelLoading}
          hasChannelManager={hasChannelManager}
        />
      )}
      {alertModalOpen && (
        <AlertModal
          type="success"
          icon={<CheckCircleOutlined />}
          message={'Payment method updated'}
          description={'Your payment method is now virtual credit cards.'}
          isOpen={alertModalOpen}
          closable
          onClose={() => setAlertModalOpen(false)}
        />
      )}
    </Layout>
  );
};

export default ExtranetPayment;
