import { Button } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { addMonths } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { useCallback } from 'react';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const DatePickerWithHelper = ({
  value,
  onChange,
  ...props
}: DatePickerWithHelperProps) => {
  const handleChange = useCallback(
    (date: Date | null) => {
      if (onChange) {
        onChange(date || new Date());
      }
    },
    [onChange]
  );

  return (
    <>
      <DatePicker value={value} onChange={handleChange} format={'EEE dd MMM'} />
      <Button onClick={() => handleChange(addMonths(value ?? new Date(), 1))}>
        +1 month
      </Button>
      <Button onClick={() => handleChange(addMonths(value ?? new Date(), 3))}>
        +3 months
      </Button>
    </>
  );
};

type DatePickerWithHelperProps = Omit<DatePickerProps, 'value' | 'onChange'> & {
  value?: Date;
  onChange?: (date: Date) => void;
};

export default DatePickerWithHelper;
