import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { AlertProps, Button, Form, Modal, StepProps, message } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

import AlertModal from 'app/components/commons/AlertModal/AlertModal';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useDisconnect } from 'app/hooks/data/autoInventory/useDisconnect';
import { useSave } from 'app/hooks/data/autoInventory/useSave';
import { useRooms } from 'app/hooks/data/useRooms';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import {
  resetDirtyOpenings,
  tryFetchHotels,
  tryFetchInventory,
} from 'app/redux/actions/hotelAdmin';
import { useAppDispatch } from 'app/redux/hooks';
import { IHotel, Room } from 'app/typings';
import {
  ModifierType,
  RoomScrapped,
  RoomsWithInventory,
  SyncStatus,
} from 'app/typings/autoInventory';

import { Inventory } from '../../Types';

import './AutoInventoryModal.scss';
import {
  FormValues,
  checkIfBarIsValid,
  checkIfRoomMappingIsValid,
} from './Utils';
import { AutoInventoryConfiguration } from './_components/AutoInventoryConfiguration/AutoInventoryConfiguration';
import { AutoInventoryExplanation } from './_components/AutoInventoryExplanation/AutoInventoryExplanation';
import { Footer } from './_components/Footer';

type AutoInventoryModalProps = {
  hotel: IHotel;
  hasAutoInventoryActivated: boolean;
  inventory: Inventory;
  isAutoInventoryModalOpen: boolean;
  startDate: string;
  onClose: () => void;
};

export const AutoInventoryModal = ({
  hotel,
  hasAutoInventoryActivated,
  inventory,
  isAutoInventoryModalOpen,
  onClose,
  startDate,
}: AutoInventoryModalProps) => {
  const { track } = useAmplitude();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const hotelId = hotel.id;
  const { mutate: disconnectAutoInventory, isLoading: isDisconnectLoading } =
    useDisconnect(hotelId);

  const [initConfiguration, setInitConfiguration] = useState(
    !hotel.stockScrapperEnabled
  );
  const { data: rooms } = useRooms(hotelId);

  const { mutate: saveBookingConfiguration } = useSave(hotelId);

  const [currentStep, setCurrentStep] = useState(0);
  const [discountStepHasError, setDiscountStepHasError] = useState(false);
  const [isModalClosedBlock, setIsModalClosedBlock] = useState(false);

  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [isAlertForDisconnect, setIsAlertForDisconnect] = useState(false);

  const [scrappingRooms, setScrappingRooms] = useState<RoomScrapped[]>([]);
  const [syncStatus, setSyncStatus] = useState<SyncStatus>(SyncStatus.Idle);

  const [scrappingInventory, setScrappingInventory] = useState<
    RoomsWithInventory[]
  >([]);

  const initialValue: FormValues = {
    rooms:
      rooms?.reduce((acc, room, idx) => {
        if (room.bookingcomId && room.bookingcomName) {
          acc[idx] = {
            ...room,
            AutoInventoryId: room.bookingcomId,
            AutoInventoryName: room.bookingcomName,
          };
        }

        return acc;
      }, {} as { [index: number]: Room & { AutoInventoryId?: number; AutoInventoryName?: string } }) ??
      {},
    matchOTAPrice: !hasAutoInventoryActivated
      ? undefined
      : hotel.stockScrapperBarModifierValue
      ? false
      : true,
    customBar: {
      value:
        (hotel.stockScrapperBarModifierType === ModifierType.RATE
          ? Math.round((hotel.stockScrapperBarModifierValue ?? 0) * 100)
          : hotel.stockScrapperBarModifierValue ?? 0) ?? undefined,
      suffix: hotel.stockScrapperBarModifierType ?? ModifierType.RATE,
    },
    discount: Math.round((hotel.autoDiscountRate ?? 0) * 100) ?? undefined,
    lastMinuteDays: hotel.lastMinuteAutoDiscountDays ?? undefined,
    lastMinuteDiscountValue:
      Math.round((hotel.lastMinuteAutoDiscountRate ?? 0) * 100) ?? undefined,
    lastMinuteIsActivated: !!(
      hotel.lastMinuteAutoDiscountRate && hotel.lastMinuteAutoDiscountDays
    ),
    discountIsActivated: !!initConfiguration || !!hotel.autoDiscountRate,
    overrideOpenings: false,
  };

  const [formData, setFormData] = useState<FormValues>(initialValue);

  const resetStore = () => {
    dispatch(tryFetchInventory({ hotelId, start: startDate }));
    dispatch(resetDirtyOpenings());
    dispatch(tryFetchHotels());
    queryClient.invalidateQueries({
      queryKey: ['/hotels/:id/rooms', { id: hotelId }],
    });
  };

  const onDisconnect = () => {
    track('Click disconnect');
    disconnectAutoInventory(undefined, {
      onSuccess: () => {
        resetStore();
        onClose();
        setAlertModalOpen(false);
      },
      onError: () => {
        message.error('Error while disconnecting Auto inventory');
      },
    });
  };

  const onFinish = async () => {
    const formattedValues = {
      roomMappings: Object.values(formData.rooms).map((room) => ({
        id: room.id,
        scrappedRoomId: room.AutoInventoryId,
        scrappedRoomName: room.AutoInventoryName,
      })),
      discount: formData.discountIsActivated
        ? (formData.discount ?? 0) / 100
        : undefined,
      barModifier: !formData.matchOTAPrice
        ? {
            value:
              formData.customBar.suffix === ModifierType.RATE
                ? (formData.customBar.value ?? 0) / 100
                : formData.customBar.value ?? 0,
            modifierType:
              formData.customBar.suffix === ModifierType.RATE
                ? ModifierType.RATE
                : ModifierType.FLAT,
          }
        : undefined,
      lastMinutePricingRule: formData.lastMinuteIsActivated
        ? {
            discount: (formData.lastMinuteDiscountValue ?? 0) / 100,
            days: formData.lastMinuteDays ?? 0,
          }
        : undefined,
      overrideOpenings: formData.overrideOpenings,
    };

    saveBookingConfiguration(formattedValues, {
      onSuccess: () => {
        resetStore();
        onClose();
      },
      onError: () => {
        message.error('Error while saving the data');
      },
    });
  };

  const handleClose = async () => {
    track('Close Auto inventory modal');
    onClose();
  };

  const alertModalInfo: AlertProps = {
    type: isAlertForDisconnect ? 'warning' : 'success',
    icon: isAlertForDisconnect ? (
      <InfoCircleOutlined />
    ) : (
      <CheckCircleOutlined />
    ),
    message: isAlertForDisconnect
      ? 'Deactivate Auto inventory?'
      : 'Demand successfully sent!',
    className: isAlertForDisconnect ? 'alert-disconnect' : '',
    description: isAlertForDisconnect ? (
      <>
        <div className="alertBody">
          By deactivating Auto inventory, only your manual data will remain.
        </div>
        <div className="alert-footer">
          <Button
            loading={isDisconnectLoading}
            type="default"
            className="disconnect-button"
            onClick={onDisconnect}
            disabled={isDisconnectLoading}
          >
            Deactivate
          </Button>
          <Button
            disabled={isDisconnectLoading}
            type="primary"
            onClick={() => {
              track('Click Stay connected');
              setAlertModalOpen(false);
            }}
          >
            Keep activated
          </Button>
        </div>
      </>
    ) : (
      'Your Account Manager has been contacted.'
    ),
    closable: !isAlertForDisconnect,
    onClose: () => {
      track('Close notification');
      setAlertModalOpen(false);
      handleClose();
    },
    style: isAlertForDisconnect ? { backgroundColor: '#FFF', border: 0 } : {},
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={initialValue}
    >
      {(values) => {
        const items: StepProps[] = [
          {
            title: 'Room mapping',
            disabled: false,
          },
          {
            title: 'BAR',
            disabled: !checkIfRoomMappingIsValid(values),
          },
          {
            title: 'Discount',
            disabled: !checkIfBarIsValid(values),
          },
          {
            title: 'Preview',
            disabled: true,
          },
        ];

        return (
          <>
            <Modal
              centered
              destroyOnClose={true}
              width={initConfiguration ? 496 : 684}
              maskClosable={!isModalClosedBlock}
              open={isAutoInventoryModalOpen}
              className={classNames([
                'auto-inventory-modal',
                {
                  'explication-part': initConfiguration,
                },
              ])}
              title={initConfiguration ? <></> : 'Auto inventory'}
              footer={
                initConfiguration ? (
                  <></>
                ) : (
                  <Footer
                    setCurrentStep={setCurrentStep}
                    setAlertModalOpen={setAlertModalOpen}
                    setScrappingInventory={setScrappingInventory}
                    setIsAlertForDisconnect={setIsAlertForDisconnect}
                    setFormData={setFormData}
                    currentStep={currentStep}
                    items={items}
                    hasAutoInventoryActivated={hasAutoInventoryActivated}
                    values={values}
                    hotelId={hotelId}
                    discountStepHasError={discountStepHasError}
                    setIsModalClosedBlock={setIsModalClosedBlock}
                  />
                )
              }
              onCancel={handleClose}
            >
              {initConfiguration ? (
                <AutoInventoryExplanation
                  setInitConfiguration={setInitConfiguration}
                  onClose={onClose}
                  hotelId={hotelId}
                  setScrappingRooms={setScrappingRooms}
                  setSyncStatus={setSyncStatus}
                  syncStatus={syncStatus}
                  setAlertModalOpen={setAlertModalOpen}
                  setIsAlertForDisconnect={setIsAlertForDisconnect}
                />
              ) : (
                <AutoInventoryConfiguration
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  items={items}
                  hotelId={hotelId}
                  values={values}
                  inventory={inventory}
                  setFormData={setFormData}
                  scrappingRooms={scrappingRooms}
                  scrappingInventory={scrappingInventory}
                  setDiscountStepHasError={setDiscountStepHasError}
                  setScrappingRooms={setScrappingRooms}
                  setSyncStatus={setSyncStatus}
                  setInitConfiguration={setInitConfiguration}
                />
              )}
            </Modal>
            {alertModalOpen && (
              <AlertModal {...alertModalInfo} isOpen={alertModalOpen} />
            )}
          </>
        );
      }}
    </FormLegacyRenderProp>
  );
};
