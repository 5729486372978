import { BookingDetails } from 'app/typings/extranetBookings/booking';

import RenderInfo from '../../RenderInfo/RenderInfo';

type CardDetailsProps = {
  booking: BookingDetails;
  currencyFormatter: (price?: number) => string;
  cardIsBlur: boolean;
  refundedAmount: number;
};

const CardDetails = ({
  booking,
  currencyFormatter,
  cardIsBlur,
  refundedAmount,
}: CardDetailsProps) => {
  return (
    <div className="card-detail">
      <RenderInfo
        title={booking.issuingChargedAt ? 'Amount charged' : 'Amount to charge'}
        content={currencyFormatter(booking.hotelAmount)}
      />
      {refundedAmount > 0 && (
        <RenderInfo
          title="Amount refunded"
          content={currencyFormatter(refundedAmount)}
        />
      )}
      {!cardIsBlur && <RenderInfo title="PIN" id="card-pin" />}
    </div>
  );
};

export default CardDetails;
