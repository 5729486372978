import { Button, Popconfirm } from 'antd';
import formatDate from 'date-fns/format';
import React, { useCallback, useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { StatusGiftCardBadge } from 'app/components/lists/cells';
import { useResendConfirmationGiftCard } from 'app/hooks/data/giftCards/useGiftCard';
import { GiftCard, GiftCardBooking } from 'app/typings/giftCard';
import { dateAndHour } from 'app/utils/dates';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard;
  giftCardBookings: GiftCardBooking[];
};

export const Summary = ({ giftCard, giftCardBookings }: Props) => {
  const {
    id,
    code,
    status,
    createdAt,
    expirationDate,
    initialValue,
    remainingValue,
    billingEmail,
    giftCardType,
  } = giftCard;

  const { currencyFormatter } = useContext(CurrencyContext);

  const { mutateAsync: resendConfirmationGiftCard } =
    useResendConfirmationGiftCard();

  const nbBookingsConfirmed = giftCardBookings.filter(
    ({ status }) => status === 'accepted'
  ).length;

  const resendConfirmation = useCallback(async () => {
    await resendConfirmationGiftCard({ code });
  }, [resendConfirmationGiftCard, code]);

  return (
    <div className="bloc">
      <div className="header-title-button">
        <div className="title-with-button">Summary</div>
        <div>
          {giftCardType === 'email' && (
            <Popconfirm
              title={`You're going to resend the confirmation to ${billingEmail}`}
              onConfirm={resendConfirmation}
              okText="Send"
              cancelText="Cancel"
              placement="bottom"
              getPopupContainer={(trigger) => trigger.parentElement || trigger}
            >
              <Button className="button">Resend confirmation</Button>
            </Popconfirm>
          )}
        </div>
      </div>
      <div className="generic-content">
        <Line title="ID" value={`${id}`} />
        <Line title="Code" value={code} />
        <Line title="Status" value={StatusGiftCardBadge(status)} />
        <Line title="Created at" value={dateAndHour(createdAt)} />
        <Line
          title="Expiry date"
          value={`${
            expirationDate
              ? formatDate(new Date(expirationDate), 'dd/MM/yyy')
              : 'Not set'
          }`}
        />
        <Line title="Initial amount" value={currencyFormatter(initialValue)} />
        <Line
          title="Remaining amount"
          value={currencyFormatter(remainingValue)}
        />
        <Line
          title="Number of bookings confirmed"
          value={nbBookingsConfirmed}
        />
        {giftCardType === 'incentive' && (
          <Line
            title="Total cost"
            value={currencyFormatter(nbBookingsConfirmed * (initialValue || 0))}
          />
        )}
      </div>
    </div>
  );
};

export default Summary;
