import { PSP } from 'app/typings/PSP';

export type Bill = {
  readonly id: number;
  readonly hotelName: string;
  readonly reference: string;
  readonly commissionAmount: number;
  readonly totalAmount: number;
  readonly payoutAmount: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly fromDate: string;
  readonly toDate: string;
  readonly provider: string;
  readonly status: string;
  readonly errorMessage: string | undefined;
};

export type Adyen = 'ADYEN';
export type Stripe = 'STRIPE';
export type Provider = Adyen | Stripe;

export const adyenValue = 'ADYEN';
export const stripeValue = 'STRIPE';

export function createBill(values: any): Bill {
  const {
    id,
    hotelName,
    reference,
    commissionAmount,
    totalAmount,
    payoutAmount,
    createdAt,
    updatedAt,
    fromDate,
    toDate,
    provider,
    status,
    errorMessage,
  } = values;

  return {
    id,
    hotelName,
    reference,
    commissionAmount,
    totalAmount,
    payoutAmount,
    createdAt,
    updatedAt,
    fromDate,
    toDate,
    provider,
    status,
    errorMessage,
  };
}

export function isProvider(maybeProvider: string): maybeProvider is PSP {
  return [PSP.Adyen, PSP.Stripe].some((e) => e === maybeProvider);
}
