import { Button, Form, Input, Modal } from 'antd';
import { validate } from 'email-validator';
import { useContext, useState } from 'react';

import { Option, Select } from 'app/components/fields/Select';
import { UserContext } from 'app/context/UserContext/UserContext';
import { useGetUser } from 'app/hooks/data/users/useGetUsers';
import { FormUser } from 'app/typings';

import './EditUserModal.scss';

const RULES = [{ required: true, message: 'Required' }];

export const EditUserModal = ({
  visible,
  onSave,
  onCancel,
  roles,
  userId,
}: Props) => {
  const [form] = Form.useForm();
  const [formValues, handleFormValues] = useState<FormUser>({ id: userId });
  const { data: userDetail } = useGetUser(userId);
  const { user } = useContext(UserContext);

  const filteredRoles = roles.filter(
    (role) => role !== 'superadmin' || user?.role === 'superadmin'
  );

  const initialValues = {
    email: userDetail?.user.email,
    phoneNumber: userDetail?.user.phoneNumber,
    role: userDetail?.user.role,
  };

  const merge = (values: FormUser) => {
    handleFormValues({ ...formValues, ...values });
  };

  const handleSave = () => {
    onSave(formValues);
  };

  const isEmailValid = formValues.email && validate(formValues.email);

  const isFormInvalid =
    !isEmailValid ||
    !form.isFieldsTouched() ||
    form.getFieldsError().filter(({ errors }) => errors.length > 0).length > 0;

  return (
    <Modal
      width={560}
      open={visible}
      title="Edit user"
      onCancel={onCancel}
      okText="Save"
      className="edit-user-modal"
      wrapClassName="admin-modal"
      centered
      footer={
        <Button type="primary" disabled={isFormInvalid} onClick={handleSave}>
          Save
        </Button>
      }
    >
      <Form
        form={form}
        onValuesChange={(v, h) => merge(h)}
        colon={false}
        initialValues={initialValues}
      >
        <div className="input-row">
          <Form.Item name="email" label="Email" rules={RULES}>
            <Input placeholder="email" />
          </Form.Item>
        </div>
        <div className="input-row">
          <Form.Item name="phoneNumber" label="Phone number">
            <Input placeholder="phone number" />
          </Form.Item>
        </div>
        <div className="input-row">
          <Form.Item name="role" label="Role" rules={RULES}>
            <Select placeholder="Choose a reason">
              {filteredRoles.map((role) => (
                <Option key={role} value={role}>
                  {role}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

type Props = {
  visible: boolean;
  onSave: (data: FormUser) => void;
  onCancel: () => void;
  roles: Array<string>;
  userId: number;
};

export default EditUserModal;
