import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQueryClient } from '@tanstack/react-query';

import { apiRequest } from 'app/utils/request/api';

const pathCardDisplay =
  '/extranet/:hotelId/bookings/:code/card-display-key' as const;

export function useGetEphemeralKey(hotelId: number, code: string) {
  const queryClient = useQueryClient();

  const getEphemeralKey = (nonce: string, cardId: string) => {
    return queryClient.fetchQuery<
      { secret: string },
      unknown,
      { secret: string }
    >({
      queryKey: [pathCardDisplay, { hotelId, code, nonce, cardId }],
      queryFn: () =>
        apiRequest({
          path: pathCardDisplay,
          params: { hotelId: hotelId.toString(), code: code.toString() },
          queryParams: { nonce, cardId },
        }),
      staleTime: 5 * MILLISECONDS_IN_MINUTE,
    });
  };

  return {
    getEphemeralKey,
  };
}
