export type TaskInformation = {
  title: string;
  description: string;
  icon: JSX.Element;
  redirect: string | null;
  max: number;
  min: number;
  priority: number;
  taskName: TaskName;
  isMomentAvailable: boolean;
  buttonLabel?: string;
  closeTaskCenter?: boolean;
};

export type TaskCompletion = {
  value: number;
  percent: number;
};

export type Task = (TaskInformation | BedTaskInformation) & TaskCompletion;
export type BedTaskInformation = TaskInformation & { categoryId: number };

export enum TaskName {
  BabyCot = 'Baby cot',
  GuestBed = 'Guest bed',
  PricingRule = 'Pricing rule',
  LastMinutePricingRule = 'Last minute pricing rule',
  AttractExtraGuests = 'Attract extra guests',
  AttractSingleGuests = 'Attract single guests',
  ConfigureChildPrice = 'Configure child price',
  EarlyCheckin = 'Early Check-in',
  LateCheckout = 'Late Check-out',
  AutoInventory = 'Auto inventory',
  VirtualCards = 'Virtual Cards',
}

export type TaskSource = 'drawer' | 'preview';
export type GuestTask =
  | TaskName.AttractExtraGuests
  | TaskName.AttractSingleGuests
  | TaskName.ConfigureChildPrice;

export const TASK_NAMES = Object.values(TaskName);
