import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { dateTimeCell2 } from 'app/components/lists/cells';
import { UserGiftCard } from 'app/typings';
import { sortDate } from 'app/utils/sort';

import 'styles/pages/Users/user-details.scss';

import ActionCellMenu from '../../../lists/ActionCellMenu';

const STATUS_FILTER_VALUES = [
  {
    value: 'active',
    text: 'Active',
  },
  {
    value: 'empty',
    text: 'Empty',
  },
  {
    value: 'expired',
    text: 'Expired',
  },
  {
    value: 'cancelled',
    text: 'Cancelled',
  },
];

const sortGiftCardsByDateField = (valueA: UserGiftCard, valueB: UserGiftCard) =>
  sortDate(valueA.date, valueB.date, 'ascend');

type GiftCardsLinkedProps = {
  giftCards: UserGiftCard[];
};

const GiftCardsLinked: React.FC<GiftCardsLinkedProps> = ({
  giftCards,
}: GiftCardsLinkedProps) => {
  const history = useHistory();

  const handleActionClick = ({ code }: UserGiftCard) => {
    history.push(`/gift-cards/${code}`);
  };

  const handleRowClick = ({ code }: UserGiftCard) => {
    return {
      onClick: () => history.push(`/gift-cards/${code}`),
    };
  };

  return (
    <div className="bloc" style={{ minWidth: '800px' }}>
      <div className="title">Gift cards</div>
      <Table
        dataSource={giftCards}
        pagination={false}
        rowKey="code"
        onRow={handleRowClick}
      >
        <Column key="code" className="strong" title="Code" dataIndex="code" />
        <Column
          key="date"
          title="Date"
          sorter={sortGiftCardsByDateField}
          defaultSortOrder="descend"
          render={dateTimeCell2}
          dataIndex="date"
        />
        <Column
          key="cardType"
          title="Type"
          dataIndex="cardType"
          render={_.capitalize}
        />
        <Column
          key="amount"
          title="Amount"
          render={(amount) => <div>{`${amount}€`}</div>}
          dataIndex="amount"
        />
        <Column
          className="strong"
          title="Status"
          key="status"
          dataIndex="status"
          render={(value) => (
            <div>
              {STATUS_FILTER_VALUES.find((status) => status.value === value)
                ?.text ?? ''}
            </div>
          )}
        />
        <Column
          key="actions"
          className="actions-column"
          onCell={() => ({ onClick: (e) => e.stopPropagation() })}
          render={(entity) => (
            <ActionCellMenu
              entity={entity}
              handleActionClick={handleActionClick}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default GiftCardsLinked;
