import {
  ApiOutlined,
  CreditCardOutlined,
  HistoryOutlined,
  ThunderboltOutlined,
  UserAddOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import DoubleThunderbolt from 'app/components/commons/DoubleThunderbolt/DoubleThunderbolt';
import {
  BABY_COT_BED_CATEGORY_ID,
  GUEST_BED_BED_CATEGORY_ID,
} from 'app/typings';
import { assertNever } from 'app/utils/typing';

import { Inventory } from '../../ExtranetInventory/Types';
import { isValidDiscount } from '../../ExtranetInventory/Utils';

import {
  BedTaskInformation,
  TASK_NAMES,
  Task,
  TaskInformation,
  TaskName,
} from './Types';

export const getTaskInformations = (
  taskName: TaskName
): TaskInformation | BedTaskInformation => {
  switch (taskName) {
    case TaskName.BabyCot:
      return {
        taskName: TaskName.BabyCot,
        priority: 6,
        categoryId: BABY_COT_BED_CATEGORY_ID,
        title: 'Complete your baby cots',
        description: 'Extend your offer to families coming with babies.',
        icon: <UserAddOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: false,
      };

    case TaskName.GuestBed:
      return {
        taskName: TaskName.GuestBed,
        categoryId: GUEST_BED_BED_CATEGORY_ID,
        priority: 7,
        title: 'Complete your guest beds',
        description: 'Extend your offer to users coming with children.',
        icon: <UserAddOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: false,
      };

    case TaskName.PricingRule:
      return {
        taskName: TaskName.PricingRule,
        priority: 5,
        title: 'Set a standard discount',
        description:
          'Automatically publish your connected stocks according to your rules.',
        icon: <ThunderboltOutlined />,
        redirect: 'inventory',
        max: 1,
        min: 0,
        isMomentAvailable: false,
      };

    case TaskName.LastMinutePricingRule:
      return {
        taskName: TaskName.LastMinutePricingRule,
        priority: 4,
        title: 'Set a last minute discount',
        description:
          'Set a different default discount to your last minute stocks.',
        icon: <DoubleThunderbolt />,
        redirect: 'inventory',
        max: 1,
        min: 0,
        isMomentAvailable: false,
      };
    case TaskName.AttractExtraGuests:
      return {
        taskName: TaskName.AttractExtraGuests,
        priority: 2,
        title: 'Attract extra guests',
        description:
          'Adapt the price of your package for users travelling with more than 2 adults.',
        icon: <UsergroupAddOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: true,
      };
    case TaskName.ConfigureChildPrice:
      return {
        taskName: TaskName.ConfigureChildPrice,
        priority: 2,
        title: 'Configure child price',
        description:
          'Adapt the price of your package for families travelling with children.',
        icon: <UsergroupAddOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: true,
      };
    case TaskName.AttractSingleGuests:
      return {
        taskName: TaskName.AttractSingleGuests,
        priority: 3,
        title: 'Attract single guests',
        description:
          'Adapt the price of your package for users travelling alone.',
        icon: <UserOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: true,
      };

    case TaskName.EarlyCheckin:
      return {
        taskName: TaskName.EarlyCheckin,
        priority: 1,
        title: 'Boost your revenues with an Early Check-in',
        description:
          'Add an optional Early Check-in to improve your page visibility, conversion and average basket.',
        icon: <HistoryOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: false,
      };
    case TaskName.LateCheckout:
      return {
        taskName: TaskName.LateCheckout,
        priority: 1,
        title: 'Boost your revenues with a Late Check-out',
        description:
          'Add an optional Late Check-out to improve your page visibility, conversion and average basket.',
        icon: <HistoryOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: false,
      };
    case TaskName.AutoInventory:
      return {
        taskName: TaskName.AutoInventory,
        priority: 0,
        title: 'Set up Auto inventory',
        description:
          'Try out stock synchronization with other online platforms',
        icon: <ApiOutlined />,
        redirect: null,
        max: 1,
        min: 0,
        isMomentAvailable: false,
      };
    case TaskName.VirtualCards:
      return {
        taskName: TaskName.VirtualCards,
        priority: -1,
        title: 'Configure Virtual Cards',
        description: 'Get paid on the day of check-in.',
        icon: <CreditCardOutlined />,
        redirect: `payment`,
        closeTaskCenter: true,
        max: 1,
        min: 0,
        isMomentAvailable: true,
        buttonLabel: 'Discover',
      };
    default:
      assertNever(taskName);
  }
};

export const getProgressPercent = (actual: number, max: number) => {
  if (max === 0) {
    return 0;
  }

  return (actual / max) * 100;
};

export const validateDiscountInput = (
  isRuleActive: boolean,
  inputDiscount: number,
  inventory: Inventory
) => {
  const discount = Math.trunc(inputDiscount);

  if (!isRuleActive) {
    return Promise.resolve('');
  }

  if (
    discount === null ||
    (inventory && !isValidDiscount(-discount, inventory.pkg.dayPackage))
  ) {
    return Promise.reject(new Error('Invalid discount'));
  }

  return Promise.resolve('');
};

export const createTaskInformationsLists = (
  hasBabyCot: boolean,
  hasGuestBed: boolean,
  hasChannelManager: boolean,
  hasActivatedPricingRule: boolean,
  hasActivatedLastMinutePricingRule: boolean,
  hasActivatedAdditionalAdult: boolean,
  hasActivatedSingleCustomerDiscount: boolean,
  hasConfiguredAdditionalChildPrice: boolean,
  hasEarlyCheckInExp: boolean,
  hasLateCheckoutExp: boolean,
  hasActivatedAutoInventory: boolean,
  issuingAllowed: boolean,
  issuingActivated: boolean,
  isMoment?: boolean,
  childrenAllowed?: boolean
) => {
  const doneTasksInformations: TaskInformation[] = [];

  const missingTaskInformations: TaskInformation[] = [];

  TASK_NAMES.forEach((taskName) =>
    handleTasks(
      taskName,
      hasBabyCot,
      doneTasksInformations,
      missingTaskInformations,
      hasGuestBed,
      hasChannelManager,
      hasActivatedPricingRule,
      hasActivatedLastMinutePricingRule,
      hasActivatedAdditionalAdult,
      hasActivatedSingleCustomerDiscount,
      hasConfiguredAdditionalChildPrice,
      hasEarlyCheckInExp,
      hasLateCheckoutExp,
      hasActivatedAutoInventory,
      issuingAllowed,
      issuingActivated,
      isMoment,
      childrenAllowed
    )
  );

  return { doneTasksInformations, missingTaskInformations };
};

const handleTasks = (
  taskName: TaskName,
  hasBabyCot: boolean,
  doneTasksInformations: TaskInformation[],
  missingTaskInformations: TaskInformation[],
  hasGuestBed: boolean,
  hasChannelManager: boolean,
  hasActivatedPricingRule: boolean,
  hasActivatedLastMinutePricingRule: boolean,
  hasActivatedAdditionalAdult: boolean,
  hasActivatedSingleCustomerDiscount: boolean,
  hasConfiguredAdditionalChildPrice: boolean,
  hasEarlyCheckInExp: boolean,
  hasLateCheckoutExp: boolean,
  hasActivatedAutoInventory: boolean,
  issuingAllowed: boolean,
  issuingActivated: boolean,
  isMoment?: boolean,
  childrenAllowed = true
) => {
  const TaskInformations = getTaskInformations(taskName);

  if (isMoment && !TaskInformations.isMomentAvailable) return;

  switch (taskName) {
    case TaskName.BabyCot:
      hasBabyCot
        ? doneTasksInformations.push(TaskInformations)
        : missingTaskInformations.push(TaskInformations);

      break;
    case TaskName.GuestBed:
      hasGuestBed
        ? doneTasksInformations.push(TaskInformations)
        : missingTaskInformations.push(TaskInformations);

      break;
    case TaskName.PricingRule:
      hasChannelManager &&
        (hasActivatedPricingRule
          ? doneTasksInformations.push(TaskInformations)
          : missingTaskInformations.push(TaskInformations));

      break;
    case TaskName.LastMinutePricingRule:
      hasChannelManager &&
        (hasActivatedLastMinutePricingRule
          ? doneTasksInformations.push(TaskInformations)
          : missingTaskInformations.push(TaskInformations));

      break;
    case TaskName.AttractExtraGuests:
      hasActivatedAdditionalAdult
        ? doneTasksInformations.push(TaskInformations)
        : missingTaskInformations.push(TaskInformations);

      break;
    case TaskName.AttractSingleGuests:
      hasActivatedSingleCustomerDiscount
        ? doneTasksInformations.push(TaskInformations)
        : missingTaskInformations.push(TaskInformations);

      break;
    case TaskName.ConfigureChildPrice:
      childrenAllowed &&
        (hasConfiguredAdditionalChildPrice
          ? doneTasksInformations.push(TaskInformations)
          : missingTaskInformations.push(TaskInformations));

      break;
    case TaskName.EarlyCheckin:
      !hasEarlyCheckInExp && missingTaskInformations.push(TaskInformations);

      break;
    case TaskName.LateCheckout:
      !hasLateCheckoutExp && missingTaskInformations.push(TaskInformations);

      break;
    case TaskName.AutoInventory:
      !hasChannelManager &&
        (hasActivatedAutoInventory
          ? doneTasksInformations.push(TaskInformations)
          : missingTaskInformations.push(TaskInformations));

      break;
    case TaskName.VirtualCards:
      issuingAllowed &&
        (issuingActivated
          ? doneTasksInformations.push(TaskInformations)
          : missingTaskInformations.push(TaskInformations));

      break;
    default:
      assertNever(taskName);
  }
};

export const createTaskListsFromTasksInformations = (
  doneTasksInformations: TaskInformation[],
  missingTaskInformations: TaskInformation[]
) => {
  const doneTasks: Task[] = doneTasksInformations
    .map((task) => ({
      ...task,
      value: task.max,
      percent: getProgressPercent(task.max, task.max),
    }))
    .sort((taskA, taskB) => taskA.priority - taskB.priority);

  const missingTasks: Task[] = missingTaskInformations
    .map((task) => ({
      ...task,
      value: task.min,
      percent: getProgressPercent(task.min, task.max),
    }))
    .sort((taskA, taskB) => taskA.priority - taskB.priority);

  return { doneTasks, missingTasks };
};

export const isDoneTask = (taskName: TaskName, doneTasks: Task[]) =>
  doneTasks.map((task) => task.taskName).includes(taskName);
