import { useContext } from 'react';
import { Link } from 'react-router-dom';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import Line from 'app/components/pages/GiftCards/details/Line/Line';
import { BookingSummary } from 'app/typings/adminBookings/bookings';
import { formatPaxAsString } from 'app/utils/pax';

import { numberToStars } from '../NumberToStars';
import SummaryExtra from '../SummaryExtra/SummaryExtra';

type Props = {
  bookingSummary: BookingSummary;
};

const Summary = ({ bookingSummary }: Props) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const { pkg, totalAmount, room, experiences, basePackageDiscountPrice } =
    bookingSummary;
  const {
    id: packageId,
    hotelId,
    hotelName,
    aliasForHotel: packageName,
    stars,
  } = pkg;
  const { category, name: roomName } = room;
  const exp = experiences.filter(
    (e) =>
      !e.included && e.price !== undefined && e.price > 0.01 && e.quantity !== 0
  );

  return (
    <div className="bloc">
      <div className="title">Summary</div>
      <div className="generic-content">
        <Line
          title="Hotel"
          value={
            <div>
              <Link
                className="link"
                to={`/hotels/${hotelId}/edit#communication`}
                target="_blank"
              >{`${hotelName} `}</Link>
              {numberToStars(stars)}
            </div>
          }
        />
        <Line
          title="Package"
          value={
            <div>
              <Link
                className="link"
                to={`/packages/${packageId}/edit`}
                target="_blank"
              >
                {packageName}
              </Link>
              {` • ${currencyFormatter(basePackageDiscountPrice)}`}
            </div>
          }
        />
        <Line title="Guest" value={formatPaxAsString(bookingSummary.pax)} />
        <Line title="Room category" value={category} />
        <Line title="Room name" value={roomName} />
        {exp.length > 0 && (
          <Line title="Extras" value={<SummaryExtra exp={exp} />} />
        )}
        <Line title="Total price" value={currencyFormatter(totalAmount)} />
      </div>
    </div>
  );
};

export default Summary;
