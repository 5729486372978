import { BookingStatus } from './adminBookings/bookings';
import { GiftCardStatus } from './giftCard';
import { ILocation } from './location';

export type UserHotjar = {
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  birthDate: string;
  avatarPath: string;
  id: number;
  countrySlug: string;
  hotelIds: number[];
};

export type AdminUser = {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  role: UserRole;
  avatarId: string;
};

export enum UserRole {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  User = 'user',
}

export const USER_ROLES = Object.values(UserRole);

export type Gender = 'Mr' | 'Mrs' | 'Other' | 'Miss';

export type User = {
  id: number;
  clubId: number;
  email: string;
  locale: string;
  role: string;
  countrySlug: string;
  location: ILocation;
  createdAt: string;
  token: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  birthDate?: string;
  avatarPath?: string;
  avatarId?: string;
  hotelIds: number[];
  hotelRole?: string;
};

export type UserList = {
  id: number;
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  clubId: string;
};

export type UsersListParams = {
  offset: number;
  search?: string;
  sorting?: string;
  roles: string[];
  clubs: string[];
};

export type FormUser = {
  id: number;
  email?: string;
  phoneNumber?: string;
  role?: string;
};

export type UserDetailData = {
  birthDate: string;
  club: string;
  createdAt: string;
  email: string;
  firstName: string;
  gender: string;
  id: number;
  langage: string;
  lastLogin: string;
  lastName: string;
  locality: string;
  phoneNumber: string;
  role: string;
  referrerId: number;
  avatarPath: string;
  deletedAt: string;
  countrySlug: string;
  referralCode: string;
};

export type UserBooking = {
  code: string;
  status: BookingStatus;
  hotelName: string;
  checkin: string;
  createdAt: string;
  amount: number;
  experienceScore: number;
};

export type UserGiftCard = {
  code: string;
  cardType: string;
  date: string;
  amount: number;
  status: GiftCardStatus;
};

export type UserDetail = {
  referres: number[];
  user: UserDetailData;
  userBookings: UserBooking[];
  userGiftCards: UserGiftCard[];
};
