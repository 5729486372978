import { Divider, Form, FormListFieldData, Input } from 'antd';
import { PropsWithChildren } from 'react';

import { requiredRule } from 'app/utils/rules';

import { HeaderWrapper } from '../../../../HeaderWrapper/HeaderWrapper';

import './ActionContainer.scss';

interface Props {
  field: FormListFieldData;
}

export const ActionContainer = ({
  children,
  field: { name },
}: PropsWithChildren<Props>) => {
  return (
    <div className="actionContainer">
      <div className="arrow"></div>
      <HeaderWrapper title="Style" gapTitleChildren={16}>
        <div className="commonForm">
          <HeaderWrapper isBold={false} title="Name">
            <Form.Item name={[name, 'label']} rules={[requiredRule]}>
              <Input placeholder="ex. Expériences avec piscine" />
            </Form.Item>
          </HeaderWrapper>
        </div>
      </HeaderWrapper>

      <Divider />
      {children}
    </div>
  );
};
