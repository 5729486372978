import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { useMemo, useState } from 'react';

import {
  InputRow,
  InputTextAreaRow,
} from 'app/components/commons/CustomFields/CustomFields';
import {
  SortableCollapse,
  SortablePanel,
} from 'app/components/layout/Sortable/Collapse';
import { usePackages } from 'app/hooks/data/packages/usePackages';
import type { Guide } from 'app/typings/guides';
import { formatPackageName } from 'app/utils/packages';

import './GuideSections.scss';
import SectionPackages from './SectionPackages/SectionPackages';

type Field = {
  name: number;
  key: number;
};

type Props = {
  fields: Field[];
  add: (defaultValue?: any, insertIndex?: number) => void;
  remove: (index: number) => void;
  move: (from: number, to: number) => void;
  values: Partial<Guide>;
};

const GuideSections = ({ fields, add, remove, move, values }: Props) => {
  const [activeKeys, setActiveKeys] = useState(['section-0']);

  const { data: packages } = usePackages();

  const formattedPackages = useMemo(
    () =>
      packages?.map((pkg) => ({
        id: pkg.id,
        name: formatPackageName({
          name: pkg.aliasForHotel,
          hotelName: pkg.hotelName,
        }),
        coverPicture: pkg.coverPicture,
        searchName: pkg.aliasForUser ?? pkg.aliasForHotel,
      })),
    [packages]
  );

  const handleAdd = () => {
    setActiveKeys((prevActiveKeys) => [
      ...prevActiveKeys,
      `section-${fields.length}`,
    ]);
    add({ packages: [{}] });
  };

  const handleRemove = (index: number) => {
    remove(index);

    const field = values.sections?.[index];

    if (field?.id) {
      add({ ...field, id: -field.id }, index);
    }
  };

  return (
    <div className="guide-sections">
      <SortableCollapse
        activeKey={activeKeys}
        onToggle={setActiveKeys}
        onSortEnd={({ oldIndex, newIndex }) => move(oldIndex, newIndex)}
        useDragHandle
      >
        {fields.map(({ key, name }, index) => {
          const guideSection = values.sections?.[name];

          if (guideSection && guideSection?.id < 0) {
            return null;
          }

          const defaultName = `Section ${index + 1}`;
          const sectionTitle = guideSection?.title;
          const title = sectionTitle || defaultName;

          return (
            <SortablePanel
              index={index}
              key={`section-${key}`}
              title={title}
              onRemove={() => handleRemove(index)}
              className="guide-sections__panel"
              card
            >
              <h3>Header</h3>
              <InputRow
                label="Title"
                name={[name, 'title']}
                placeholder="Pool & spa: bring the warmest pool to your birthday"
                required
              />
              <InputRow
                label="Menu Title"
                name={[name, 'menuTitle']}
                placeholder="Pool & spa"
                required
              />
              <InputTextAreaRow
                label="Description"
                name={[name, 'description']}
                placeholder="Be the king of your weekend with this perfect collections of luxury spa hotels"
                rows={2}
              />
              <h3>Packages</h3>
              <Form.List name={[name, 'packages']}>
                {(
                  fieldsPackages,
                  { add: _add, remove: _remove, move: _move }
                ) => (
                  <SectionPackages
                    fields={fieldsPackages}
                    add={_add}
                    remove={_remove}
                    move={_move}
                    guideSection={guideSection}
                    formattedPackages={formattedPackages ?? []}
                  />
                )}
              </Form.List>
            </SortablePanel>
          );
        })}
      </SortableCollapse>
      <div className="guide-sections__add">
        <Button icon={<PlusOutlined />} onClick={handleAdd}>
          Add Section
        </Button>
      </div>
    </div>
  );
};

export default GuideSections;
