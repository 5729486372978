import { Col, Form, Input, Row, Select } from 'antd';
import { useMemo } from 'react';

import { useCountries } from 'app/hooks/data/useCountries';

const RULES = [{ required: true, message: 'Required' }];

const PhysicalEditForm = () => {
  const form = Form.useFormInstance();
  const { data: countries } = useCountries();
  const countriesSupportingPhysicalGiftCards = useMemo(() => {
    return countries
      ?.filter(
        ({ id, physicalGiftCardAllowed }) =>
          form.getFieldValue('shippingCountryId') === id ||
          physicalGiftCardAllowed
      )
      .map(({ name, id }) => ({ label: name, value: id }));
  }, [countries, form]);

  return (
    <>
      <div className="input-row">
        <Row
          style={{
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <Col span={11}>
            <Form.Item
              name="shippingFirstName"
              label="First name (delivery)"
              rules={RULES}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="shippingLastName"
              label="Last name (delivery)"
              rules={RULES}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="input-row">
        <Form.Item name="shippingStreet1" label="Street 1" rules={RULES}>
          <Input />
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item name="shippingStreet2" label="Street 2">
          <Input />
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item name="shippingZipCode" label="Zip code" rules={RULES}>
          <Input />
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item name="shippingCity" label="City" rules={RULES}>
          <Input />
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item name="shippingCountryId" label="Country" rules={RULES}>
          <Select options={countriesSupportingPhysicalGiftCards} />
        </Form.Item>
      </div>
    </>
  );
};

export default PhysicalEditForm;
