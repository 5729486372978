import { FilterFilled } from '@ant-design/icons';
import { Popover, Tree } from 'antd';
import { Key } from 'antd/es/table/interface';
import { useState } from 'react';

import './ModalCheckBoxGroup.scss';

const ModalCheckBoxGroup = ({
  value,
  onChange,
  title,
  items,
  isCenter = true,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleCheck = (
    checkedKeys: Array<Key> | { checked: Key[]; halfChecked: Key[] }
  ) => {
    if (Array.isArray(checkedKeys))
      onChange(checkedKeys.map((key) => key.toString()));
    else {
      onChange([checkedKeys.toString()]);
    }
  };

  const treeContent = (
    <div>
      <Tree
        treeData={items}
        checkable
        checkedKeys={value}
        onCheck={handleCheck}
        selectable={false}
        defaultExpandAll={true}
        switcherIcon={null}
      />
    </div>
  );

  return (
    <Popover
      content={treeContent}
      trigger="click"
      open={isVisible}
      onOpenChange={setIsVisible}
    >
      <div
        className="sort-button min-width-90"
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: isCenter ? 'center' : 'space-between',
          cursor: 'pointer',
        }}
      >
        {title}
        <FilterFilled style={{ color: '#BFBFBF', marginLeft: '10px' }} />
      </div>
    </Popover>
  );
};

type Value = string;

type Item = {
  title: React.ReactNode;
  key: Value;
  children?: Item[];
  disabled?: boolean;
  checkable?: boolean;
  isDivider?: boolean;
  selectable?: boolean;
  className?: string;
};

type Props = {
  value?: Array<Value>;
  onChange: (checkedValue: Array<Value>) => void;
  title: string;
  items: Item[];
  isCenter?: boolean;
};

export default ModalCheckBoxGroup;
