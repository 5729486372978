import { parse } from 'date-fns';
import formatDate from 'date-fns/format';

import { EmailGiftCardProperties, GiftCard } from 'app/typings/giftCard';
import { capitalizeFirstLetter } from 'app/utils/string';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & EmailGiftCardProperties;
};

const EmailProperties = ({ giftCard }: Props) => {
  const {
    giftCardType,
    color,
    emailSendDate,
    senderName,
    billingEmail,
    beneficiaryName,
    beneficiaryEmail,
    message,
  } = giftCard;

  return (
    <>
      <Line title="Type" value={capitalizeFirstLetter(giftCardType)} />
      <Line title="Color" value={capitalizeFirstLetter(color)} />
      <Line
        title="Send date"
        value={`${formatDate(
          parse(emailSendDate, 'yyyy-MM-dd', new Date()),
          'dd/MM/yyyy'
        )}`}
      />
      <Line title="Sender name" value={senderName} />
      <Line title="Sender email" value={billingEmail} />
      <Line title="Beneficiary name" value={beneficiaryName} />
      <Line title="Beneficiary email" value={beneficiaryEmail} />
      <Line title="Message" value={message} />
    </>
  );
};

export default EmailProperties;
