import { Form } from 'antd';
import { FC } from 'react';

import Financial from 'app/components/pages/Hotels/Form/Financial';

import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';

const FinancialForm: FC<Props> = ({
  handleSubmit,
  initialValues,
  countryId,
}) => {
  const [form] = Form.useForm();

  const handleNext = () => {
    form.submit();
  };

  const onFormFinish = (payload: any) => {
    handleSubmit(payload);
  };

  return (
    <HeaderWrapper nextDisabled={false} handleNext={handleNext}>
      <Form form={form} onFinish={onFormFinish} initialValues={initialValues}>
        <Financial
          mode="edit"
          displayStripe={false}
          isIbanRequired
          isBuilder
          isVATRequired
          countryId={countryId}
        />
      </Form>
    </HeaderWrapper>
  );
};

type Props = {
  handleSubmit: (payload: any) => void;
  initialValues: {};
  countryId?: number;
};

export default FinancialForm;
