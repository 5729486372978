import { Layout, Table } from 'antd';
import fileDownload from 'js-file-download';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { renderBillsColumnStatus } from 'app/components/commons/Bills/Bills';
import { PriceCell } from 'app/components/lists/cells';
import { PaginationButton } from 'app/components/pages/Extranet/commons/PaginationButton/PaginationButton';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useAmplitudeViewPage } from 'app/hooks/useAmplitude/useAmplitude';
import {
  downloadBill,
  resetBillObjectPdf,
  tryFetchBills,
} from 'app/redux/actions/hotelAdmin';
import type { HotelAdminTryFetchBillsPayload } from 'app/redux/actions/hotelAdmin';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { Bill } from 'app/redux/models/Bill/Bill';
import {
  selectHotelAdminBills,
  selectHotelAdminBillsIsDownloading,
  selectHotelAdminBillsIsLoading,
  selectHotelAdminBillsPdfObject,
  selectHotelAdminHotelId,
} from 'app/redux/selectors/hotelAdmin';
import { PayoutStatus } from 'app/typings/bills';
import { dateAndHour } from 'app/utils/dates';
import { useQueryParamHistory } from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
  isValidSortOrder,
} from 'app/utils/sort';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import './ExtranetBills.scss';
import { BillsActionMenu } from './_components/BillsActionMenu/BillsActionMenu';

type BillsQueryParam = {
  pageNumber: number;
  dateSorting: SortOrder;
};

const { Content } = Layout;
const { Column } = Table;

const PAGE_SIZE = 50;

const makePayload = (queryParam: Partial<BillsQueryParam>) => {
  const validSortOrder = isValidSortOrder(queryParam.dateSorting);

  return {
    sortingColumn: validSortOrder ? 'createdAt' : undefined,
    sortingOrder: validSortOrder
      ? formatSortingForAPI(queryParam.dateSorting)
      : undefined,
    offset: ((queryParam.pageNumber || 1) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  };
};

const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
} as BillsQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
};

const PAGE = 'Bills';

export const ExtranetBills = () => {
  const { setPage } = useContext(TaskCenterContext);

  const dispatch = useAppDispatch();
  const bills = useAppSelector(selectHotelAdminBills);
  const isLoading = useAppSelector(selectHotelAdminBillsIsLoading);
  const billPdfObject = useAppSelector(selectHotelAdminBillsPdfObject);
  const isDownloading = useAppSelector(selectHotelAdminBillsIsDownloading);
  const hotelId = useAppSelector(selectHotelAdminHotelId);

  useEffect(() => {
    setPage(PAGE);
  });

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<BillsQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: HotelAdminTryFetchBillsPayload) =>
          dispatch(tryFetchBills(params)),
        500
      ),
    [dispatch]
  );

  const handleDownload = useCallback(
    (billId: number) => {
      if (!isDownloading) {
        dispatch(downloadBill({ billId, hotelId: hotelId as number }));
      }
    },
    [dispatch, isDownloading, hotelId]
  );

  useEffect(() => {
    const payload = makePayload(queryParam);

    debounceFetch({ ...payload, hotelId: hotelId as number });
  }, [queryParam, debounceFetch, hotelId]);

  useEffect(() => {
    if (billPdfObject) {
      fileDownload(
        billPdfObject.pdf,
        billPdfObject.filename,
        'application/pdf'
      );
      dispatch(resetBillObjectPdf());
    }
  }, [billPdfObject, dispatch]);

  useAmplitudeViewPage({ page: 'bills' });

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam?.pageNumber || 1) + x });

    scrollToTop();
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });
  };

  const handleRowActions = ({ id }: any) => ({
    onClick: () => handleDownload(id),
  });

  const paginationLeftButtonDisabled = queryParam.pageNumber === 1;
  const paginationRightButtonDisabled = bills.length < 50;

  return (
    <Layout className="bills-list">
      <ExtranetHeader page={PAGE} />
      <Content className="body">
        <Table
          dataSource={bills}
          pagination={false}
          title={() => (
            <div className="billsHeader">
              <PaginationButton
                leftButtonDisabled={paginationLeftButtonDisabled}
                rightButtonDisabled={paginationRightButtonDisabled}
                onPrev={() => handleOffset(-1)}
                onNext={() => () => handleOffset(1)}
                pageNumber={queryParam.pageNumber as number}
              />
            </div>
          )}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { order } = sorter;

            dateSortingHandler(convertFromAntdSortOrder(order));
          }}
        >
          <Column title="Invoice name #" dataIndex="reference" />
          <Column
            title="Total net amount"
            dataIndex="totalAmount"
            render={(price, entity) => (
              <PriceCell price={price} entity={entity} defaultText="Missing" />
            )}
          />

          <Column
            title="Creation date"
            dataIndex="createdAt"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={(date) => date && dateAndHour(date)}
          />
          <Column
            title="Period"
            render={({ fromDate, toDate }) => {
              if (fromDate && toDate) {
                const from = moment(fromDate).format('YYYY-MM-DD');
                const to = moment(toDate).format('YYYY-MM-DD');

                return `${from} - ${to}`;
              }

              return null;
            }}
          />
          <Column
            title="Payout Status"
            dataIndex="status"
            render={(status: PayoutStatus, bill: Bill) =>
              renderBillsColumnStatus(status, bill, true)
            }
          />
          <Column
            render={({ id }) => (
              <BillsActionMenu onActionClick={() => handleDownload(id)} />
            )}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        <PaginationButton
          className="footer"
          leftButtonDisabled={paginationLeftButtonDisabled}
          rightButtonDisabled={paginationRightButtonDisabled}
          onPrev={() => handleOffset(-1)}
          onNext={() => () => handleOffset(1)}
          pageNumber={queryParam.pageNumber as number}
        />
      </Content>
    </Layout>
  );
};

export default ExtranetBills;
