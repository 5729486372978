import { emptySection } from 'app/components/pages/Edito/Event/Detail/EditoEventDetail';
import { Tag } from 'app/redux/models/Tag/Tag';
import { config } from 'app/redux/store';
import {
  FilterCategory,
  IEditoEventForm,
  IEditoEventRequest,
  IEditoEventResponse,
  SearchFilters,
  TagCriteria,
} from 'app/typings/edito';
import { SearchFiltersParams } from 'app/typings/filters';
import { formatToLocalDateTime } from 'app/utils/dates';
import { filterCategoryByCriteria } from 'app/utils/edito';
import { DEFAULT_PAX, parseRange } from 'app/utils/filters/filters';
import {
  decodeSearchString,
  encodeToSearchString,
} from 'app/utils/filters/queryParams';
import { isDefined } from 'app/utils/typing';

const buildQueryParams = (searchFilters: SearchFilters) => {
  const hotelServices = searchFilters.accessibility
    ?.map((accessibility) => parseInt(accessibility, 10))
    .filter((accessibility) => !Number.isNaN(accessibility));

  const searchParams: SearchFiltersParams = {
    coords: searchFilters.coords,
    adults: searchFilters.pax?.adults,
    babies: searchFilters.pax?.babies,
    children: searchFilters.pax?.children,
    dates: searchFilters.dates,
    distances: searchFilters.distances
      ? searchFilters.distances.map((distance) => parseRange(distance))
      : undefined,
    prices: searchFilters.prices
      ? searchFilters.prices.map((price) => parseRange(price))
      : undefined,
    hotelServices,
    pmr: searchFilters.accessibility?.includes('pmr'),
    nightCount: searchFilters.nightCount,
    sort: searchFilters.sort,
    around: searchFilters.around,
    tagIds: [
      ...(searchFilters.experience ?? []),
      ...(searchFilters.room ?? []),
      ...(searchFilters.style ?? []),
      ...(searchFilters.situation ?? []),
    ],
  };

  return encodeToSearchString(searchParams);
};

const buildAppLinkFromSearchFilters = (searchFilters: SearchFilters) => {
  const appLinkQueryParams = buildQueryParams(searchFilters);
  const locality = searchFilters.location?.toLowerCase();

  return `https://www.staycation.co/fr/search${
    locality ? `/${locality}` : ''
  }?${appLinkQueryParams}`;
};

const buildWebLinkFromSearchFilters = (searchFilters: SearchFilters) => {
  const webLinkQueryParams = buildQueryParams({
    ...searchFilters,
    around: searchFilters.coords ? undefined : true,
  });

  const locality = searchFilters.location?.toLowerCase();

  return `${config.appUrl}/search${
    locality ? `/${locality}` : ''
  }?${webLinkQueryParams}`;
};

const tagIdsByCriteria = (tags: Tag[], criteria: TagCriteria) => {
  return tags
    .filter(
      (tag) =>
        tag.filterCategory &&
        filterCategoryByCriteria[criteria].includes(
          tag.filterCategory as FilterCategory
        )
    )
    .map((tag) => tag.id);
};

const selectedCriteriaTagIds = (
  tags: Tag[],
  criteria: TagCriteria,
  tagIds?: number[]
) => {
  if (!tagIds) {
    return undefined;
  }

  const selectedTagIds = tagIds.filter((tagId) => {
    return tagIdsByCriteria(tags, criteria).includes(tagId);
  });

  return selectedTagIds.length ? selectedTagIds : undefined;
};

const buildSearchFiltersFromWebLink = (
  webLink: string,
  tags: Tag[]
): SearchFilters | undefined => {
  const searchFiltersParams = decodeSearchString(webLink);

  if (searchFiltersParams) {
    const experienceType =
      searchFiltersParams.nightCount === 0 ? 'moment' : 'staycation';

    const pax =
      searchFiltersParams.babies ||
      searchFiltersParams.children ||
      searchFiltersParams.adults
        ? {
            babies: searchFiltersParams.babies ?? DEFAULT_PAX.babies,
            children: searchFiltersParams.children ?? DEFAULT_PAX.children,
            adults: searchFiltersParams.adults ?? DEFAULT_PAX.adults,
          }
        : undefined;

    const distances = searchFiltersParams.distances
      ? searchFiltersParams.distances.map(
          (distance) => `${distance.start ?? ''}-${distance.end ?? ''}`
        )
      : undefined;

    const prices = searchFiltersParams.prices
      ? searchFiltersParams.prices.map(
          (price) => `${price.start ?? ''}-${price.end ?? ''}`
        )
      : undefined;

    const accessibility =
      searchFiltersParams.hotelServices?.map((service) => `${service}`) ?? [];

    if (searchFiltersParams.pmr) {
      accessibility.push('pmr');
    }

    return {
      dates: searchFiltersParams.dates,
      distances,
      prices,
      accessibility: accessibility.length ? accessibility : undefined,
      sort: searchFiltersParams.sort,
      nightCount: searchFiltersParams.nightCount,
      pax,
      experienceType: isDefined(searchFiltersParams.nightCount)
        ? experienceType
        : undefined,
      coords: searchFiltersParams.coords,
      experience: selectedCriteriaTagIds(
        tags,
        'experience',
        searchFiltersParams.tagIds
      ),
      room: selectedCriteriaTagIds(tags, 'room', searchFiltersParams.tagIds),
      situation: selectedCriteriaTagIds(
        tags,
        'situation',
        searchFiltersParams.tagIds
      ),
      style: selectedCriteriaTagIds(tags, 'style', searchFiltersParams.tagIds),
    };
  }
};

export const formatEditoEventResponseToForm = (
  editoEvent: IEditoEventResponse,
  tags: Tag[]
): IEditoEventForm => {
  return {
    id: editoEvent.id,
    countryId: editoEvent.countryId,
    published: editoEvent.published,
    title: editoEvent.title,
    catchphrase: editoEvent.catchphrase,
    clubId: editoEvent.clubId ? editoEvent.clubId : 'empty',
    picture: editoEvent.picture,
    description: editoEvent.description,
    endDate: editoEvent.endDate ? new Date(editoEvent.endDate) : undefined,
    publishedAt: editoEvent.publishedAt,
    startDate: editoEvent.startDate
      ? new Date(editoEvent.startDate)
      : undefined,
    sections: editoEvent.sections.length
      ? editoEvent.sections.map((section) => ({
          picture: section.picture,
          title: section.title,
          description: section.description,
          actionType: section.actionType ? section.actionType : 'empty',
          label: section.label,
          webLink: section.webLink,
          appLink: section.appLink,
          searchFilters:
            section.actionType === 'search' && section.webLink
              ? buildSearchFiltersFromWebLink(section.webLink, tags)
              : undefined,
        }))
      : [emptySection],
  };
};

export const formatEditoEventFormToRequest = (
  editoEvent: IEditoEventForm
): IEditoEventRequest => {
  return {
    id: editoEvent.id,
    countryId: editoEvent.countryId,
    published: editoEvent.published,
    sections: editoEvent.sections.map((section) => ({
      title: section.title,
      description: section.description,
      actionType:
        section.actionType === 'empty' ? undefined : section.actionType,
      pictureId: section.picture?.id,
      label: section.label,
      webLink:
        section.actionType === 'search' && section.searchFilters
          ? buildWebLinkFromSearchFilters(section.searchFilters)
          : section.webLink,
      appLink:
        section.actionType === 'search' && section.searchFilters
          ? buildAppLinkFromSearchFilters(section.searchFilters)
          : section.appLink,
    })),
    title: editoEvent.title.trim(),
    catchphrase: editoEvent.catchphrase?.trim(),
    clubId: editoEvent.clubId === 'empty' ? undefined : editoEvent.clubId,
    pictureId: editoEvent.picture?.id,
    description: editoEvent.description?.trim(),
    endDate: editoEvent.endDate
      ? formatToLocalDateTime(editoEvent.endDate)
      : undefined,
    publishedAt: editoEvent.publishedAt,
    startDate: editoEvent.startDate
      ? formatToLocalDateTime(editoEvent.startDate)
      : undefined,
  };
};
