import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { getNbNight } from 'app/components/pages/Extranet/ExtranetBookings/BookingDetail/utils';
import { useRoomCategories } from 'app/hooks/data/rooms/useRoomCategories';
import { DEFAULT_ADULTS_PAX, DEFAULT_SOLO_ADULT } from 'app/typings/PAX';
import { BookingDetails } from 'app/typings/extranetBookings/booking';

import { RenderPaymentDetail } from './RenderPaymentDetail';

type RenderPaxOptionsProps = {
  booking: BookingDetails;
};

const RenderPaxOptions = ({ booking }: RenderPaxOptionsProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const { data: roomCategories } = useRoomCategories();

  const nbNight = getNbNight(booking);

  const roomCat = roomCategories?.find(
    (rc) => rc.id === booking.room.categoryId
  );

  const nightMultiplicator = roomCat?.name === 'Day Experience' ? 1 : nbNight;

  const hasAdditionnalBed =
    !!booking.room.bedPricePerNight && booking.room.bedPricePerNight > 0;

  const hasSingleCustomer =
    !!booking.room.singleCustomerDiscountPerNight &&
    booking.pax.adults === DEFAULT_SOLO_ADULT;

  const hasAdditionnalAdult =
    !!booking.room.additionalAdultPricePerNight &&
    booking.pax.adults > DEFAULT_ADULTS_PAX;

  const hasChildrenPrice =
    !!booking.room.additionalChildPricePerNight && booking.pax.children > 0;

  const showPaxOptions =
    hasAdditionnalBed ||
    hasSingleCustomer ||
    hasAdditionnalAdult ||
    hasChildrenPrice;

  if (!showPaxOptions) {
    return <></>;
  }

  return (
    <div className="payment-detail">
      {hasAdditionnalBed ? (
        <RenderPaymentDetail
          title="PAX Options"
          content="Fee for additional bed in room"
          value={currencyFormatter(
            (booking.room.bedPricePerNight ?? 0) * nightMultiplicator || 0
          )}
          removeDivider={hasAdditionnalAdult}
        />
      ) : (
        <></>
      )}

      {hasAdditionnalAdult ? (
        <RenderPaymentDetail
          title={!hasAdditionnalBed ? 'PAX Options' : undefined}
          content={`${
            booking.pax.adults - DEFAULT_ADULTS_PAX
          } x Package for additional guest (${currencyFormatter(
            (booking.room.additionalAdultPricePerNight ?? 0) *
              nightMultiplicator || 0
          )})`}
          value={currencyFormatter(
            (booking.room.additionalAdultPricePerNight ?? 0) *
              nightMultiplicator *
              (booking.pax.adults - DEFAULT_ADULTS_PAX) || 0
          )}
          removeDivider={hasChildrenPrice}
        />
      ) : (
        <></>
      )}

      {hasChildrenPrice ? (
        <RenderPaymentDetail
          title={!hasAdditionnalBed ? 'PAX Options' : undefined}
          content={`${
            booking.pax.children
          } x Package for additional child (${currencyFormatter(
            (booking.room.additionalChildPricePerNight ?? 0) *
              nightMultiplicator || 0
          )})`}
          value={currencyFormatter(
            (booking.room.additionalChildPricePerNight ?? 0) *
              nightMultiplicator || 0
          )}
          removeDivider={hasSingleCustomer}
        />
      ) : (
        <></>
      )}

      {hasSingleCustomer ? (
        <RenderPaymentDetail
          content="Price discount for single guest"
          value={currencyFormatter(
            -1 *
              ((booking.room.singleCustomerDiscountPerNight ?? 0) *
                nightMultiplicator) || 0
          )}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default RenderPaxOptions;
