import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Layout,
  Menu,
  PageHeader,
  Table,
  TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Pagination from 'app/components/lists/Pagination';
import { PublishedCell } from 'app/components/lists/cells';
import { ConfigContext } from 'app/context/ConfigContext/ConfigContext';
import { UserContext } from 'app/context/UserContext/UserContext';
import { usePackages } from 'app/hooks/data/packages/usePackages';
import {
  useHidePackage,
  usePublishPackage,
} from 'app/hooks/data/packages/useSavePackage';
import { PackageListItem } from 'app/typings/packages';
import { dateAndHour } from 'app/utils/dates';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  getSortOrder,
  sortDate,
  sortPrimitive,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

const { Content } = Layout;
const { Column } = Table;

type PackagesListQueryParam = {
  search: string;
  pageNumber: number;
  nameSorting: SortOrder;
  updatedAtSorting: SortOrder;
  publishedFilter: string[];
};

const LIMIT = 50;

const filterStatus = (val: string) =>
  ['published', 'unpublished', 'hidden'].includes(val) ? val : undefined;

const queryParamInit = {
  pageNumber: 1,
  nameSorting: 'none',
  updatedAtSorting: 'none',
  publishedFilter: ['published', 'unpublished', 'hidden'],
} as PackagesListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  nameSorting: 'ns',
  updatedAtSorting: 'us',
  publishedFilter: 'pu',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  nameSorting: getSortOrder,
  updatedAtSorting: getSortOrder,
  publishedFilter: parseArrayParam(filterStatus),
};

const PackagesList = () => {
  const history = useHistory();
  const location = useLocation();
  const config = useContext(ConfigContext);
  const { user } = useContext(UserContext);

  const { data: packages, isLoading: packagesLoading } = usePackages();
  const { mutateAsync: hidePackage } = useHidePackage();
  const { mutateAsync: publishPackage } = usePublishPackage();

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<PackagesListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam.pageNumber || 1) + x });
  };

  const handleOnChange = (
    _: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<PackageListItem> | SorterResult<PackageListItem>[]
  ) => {
    const { field, order } = (sorter = Array.isArray(sorter)
      ? sorter[0]
      : sorter || {});

    const adaptedOrder = convertFromAntdSortOrder(order);

    const nameSorting = field === 'name' ? adaptedOrder : 'none';
    const updatedAtSorting = field === 'updatedAt' ? adaptedOrder : 'none';

    updateQueryParam({
      publishedFilter: (filter.published as string[]) ?? [],
      nameSorting,
      updatedAtSorting,
      pageNumber: 1,
    });
  };

  const handleRowActions = (pkg: PackageListItem) => ({
    onClick: () =>
      history.push({
        pathname: `/packages/${pkg.id}/edit`,
        state: { pathname: location.pathname + location.search },
      }),
  });

  const filteredBySearch = useMemo(
    () =>
      packages?.filter(
        (pkg) =>
          pkg.aliasForHotel
            .toLowerCase()
            .includes(queryParam.search?.toLowerCase() || '') ||
          pkg.hotelName
            .toLowerCase()
            .includes(queryParam.search?.toLowerCase() || '')
      ) ?? [],
    [queryParam.search, packages]
  );

  const filteredByPublished = useMemo(
    () =>
      filteredBySearch.filter((pkg) => {
        if (queryParam.publishedFilter?.includes('hidden') && pkg.hidden) {
          return true;
        }

        if (
          queryParam.publishedFilter?.includes('published') &&
          pkg.published
        ) {
          return true;
        }

        if (
          queryParam.publishedFilter?.includes('unpublished') &&
          !pkg.published &&
          !pkg.hidden
        ) {
          return true;
        }

        return false;
      }),
    [queryParam.publishedFilter, filteredBySearch]
  );

  const packagesWithDefaultNames = useMemo(
    () =>
      filteredByPublished.map((pkg) => ({
        ...pkg,
        name: pkg.aliasForHotel.trim() || `Package #${pkg.id}`,
      })),
    [filteredByPublished]
  );

  const sortedPackages = useMemo(
    () =>
      packagesWithDefaultNames.sort((packageA, packageB) => {
        if (queryParam.nameSorting && queryParam.nameSorting !== 'none') {
          return sortPrimitive(
            packageA.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
            packageB.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
            queryParam.nameSorting
          );
        }

        if (
          queryParam.updatedAtSorting &&
          queryParam.updatedAtSorting !== 'none'
        ) {
          return sortDate(
            packageA.updatedAt,
            packageB.updatedAt,
            queryParam.updatedAtSorting
          );
        }

        return 0;
      }),
    [
      queryParam.nameSorting,
      queryParam.updatedAtSorting,
      packagesWithDefaultNames,
    ]
  );

  const startIndex = ((queryParam.pageNumber || 1) - 1) * LIMIT;
  const paginatedHotels = sortedPackages.slice(startIndex, startIndex + LIMIT);

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      <Pagination
        className="button-wrapper"
        pageNumber={queryParam.pageNumber ?? 1}
        pageResultsLength={paginatedHotels.length}
        handleOffset={handleOffset}
      />
    </div>
  );

  const newPackagesButton = (
    <Button
      type="primary"
      size="large"
      onClick={() =>
        history.push({
          pathname: 'packages/new',
          state: { pathname: location.pathname + location.search },
        })
      }
    >
      New
    </Button>
  );

  const actionMenu = (pkg: PackageListItem) => (
    <div className="actions-menu">
      <Dropdown
        dropdownRender={() => (
          <Menu>
            <Menu.Item
              key="preview"
              onClick={() => window.open(`${config?.appUrl}/${pkg.url}`)}
            >
              Preview
            </Menu.Item>
            <Menu.Item
              key="edit"
              onClick={() => handleRowActions(pkg).onClick()}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              key="publish"
              onClick={async () =>
                await publishPackage({
                  packageId: pkg.id,
                  published: !pkg.published,
                })
              }
              disabled={pkg.hidden}
            >
              {pkg.published ? 'Unpublish' : 'Publish'}
            </Menu.Item>
            <Menu.Item
              key="hide"
              onClick={async () =>
                await hidePackage({
                  packageId: pkg.id,
                  hidden: !pkg.hidden,
                })
              }
              disabled={pkg.published || user?.role !== 'superadmin'}
            >
              {pkg.hidden ? 'Show' : 'Hide'}
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  return (
    <Layout className="page-list">
      <PageHeader
        className="header"
        title="Packages"
        extra={newPackagesButton}
      />
      <Content className="body">
        <Table
          dataSource={paginatedHotels}
          pagination={false}
          title={header}
          loading={packagesLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={handleOnChange}
        >
          <Column
            key="name"
            title="Name"
            dataIndex="name"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.nameSorting)}
          />
          <Column key="hotelName" title="Hotel" dataIndex="hotelName" />
          <Column
            key="updatedAt"
            title="Last modified"
            dataIndex="updatedAt"
            sorter
            render={dateAndHour}
            sortOrder={convertToAntdSortOrder(queryParam.updatedAtSorting)}
          />
          <Column
            key="published"
            title="Published"
            dataIndex="published"
            render={PublishedCell}
            filters={[
              { text: 'Published', value: 'published' },
              { text: 'Unpublished', value: 'unpublished' },
              { text: 'Hidden', value: 'hidden' },
            ]}
            filteredValue={queryParam.publishedFilter || []}
          />

          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        <Pagination
          className="footer"
          pageNumber={queryParam.pageNumber ?? 1}
          pageResultsLength={paginatedHotels.length}
          handleOffset={handleOffset}
        />
      </Content>
    </Layout>
  );
};

export default PackagesList;
