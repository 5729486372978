import { Modal } from 'antd';
import React, { FC } from 'react';

import Pic from 'app/components/commons/Pic';
import type { Picture } from 'app/components/commons/Uploader/Types';
import { useEmojis } from 'app/hooks/data/useEmojis';

import './EmojiModal.scss';

const EmojiModal: FC<Props> = ({ visible, onSelect, onCancel }) => {
  const { data: emojis } = useEmojis();

  if (!emojis) {
    return null;
  }

  return (
    <Modal
      width={800}
      onCancel={onCancel}
      open={visible}
      title="Choose emoji"
      centered
      footer={[]}
      className="emoji-modal"
    >
      <div className="emoji-modal__container">
        {emojis.map((emoji) => (
          <div
            onClick={() => onSelect(emoji)}
            key={emoji.pictureId}
            className="emoji-modal__thumbnail"
          >
            <Pic {...emoji} width={40} />
          </div>
        ))}
      </div>
    </Modal>
  );
};

type Props = {
  visible: boolean;
  onSelect: (emoji: Picture) => void;
  onCancel: () => void;
};

export default EmojiModal;
