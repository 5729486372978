import { InfoCircleOutlined } from '@ant-design/icons';

import { TextWithIcon } from 'app/components/commons/TextWithIcon/TextWithIcon';

type VCCSummaryStepProps = {
  hasChannelManager: boolean;
};

export const VCCSummaryStep = ({ hasChannelManager }: VCCSummaryStepProps) => (
  <div className="content-wrapper">
    <div className="grey-square">
      <TextWithIcon
        icon={<InfoCircleOutlined />}
        text={
          <>
            Virtual Credit Cards will be live
            <span className="font-weight-600">
              {' '}
              immediately after activation.
            </span>
          </>
        }
      />
    </div>
    <div className="info-card">
      <span className="font-weight-600">
        Where will I find my virtual card ?
      </span>
      <ul className="info-list">
        <li>In your Staycation Extranet booking details</li>
        <li>Through a link in the booking confirmation email</li>
        {hasChannelManager && (
          <li>Through a link in your channel manager booking informations</li>
        )}
      </ul>
    </div>
  </div>
);
