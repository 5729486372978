import { useEffect } from 'react';

import { useSearchParams } from 'app/utils/searchParams';

import { useGetHotel } from '../data/hotels/useHotel';
import { useCountries } from '../data/useCountries';

const LOCALE_FR = 'fr';

const zendeskExtranetKey = process.env.REACT_APP_ZENDESK_EXTRANET_KEY as
  | string
  | undefined;

export const useZendeskWidget = () => {
  const { data: countries } = useCountries();
  const { getSearchParam } = useSearchParams();

  const hotelId = parseInt(getSearchParam('hotelId') ?? '', 10);

  const { data: hotel } = useGetHotel(hotelId);

  const hotelCountry = countries?.find(
    (c) => c.id === hotel?.address.countryId
  );

  useEffect(() => {
    if (
      zendeskExtranetKey === undefined ||
      hotelCountry?.locale !== LOCALE_FR
    ) {
      return;
    }

    const script = document.createElement('script');

    script.id = 'ze-snippet';
    script.defer = true;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskExtranetKey}`;

    document.head.appendChild(script);

    return () => {
      script.remove();
    };
  }, [hotelCountry?.locale]);
};
