import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Badge, Form } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';

import { SListItem } from 'app/components/StaycationUI/List';
import { ExperienceType } from 'app/components/commons/Package/General/Types';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import { Option, Select } from 'app/components/fields/Select';
import SortableHandle from 'app/components/layout/Sortable/SortableHandle';

import './ExperienceItem.scss';

const RULES = [{ required: true, message: 'Required' }];

const INCLUDE_OPTIONS = ['Included', 'Add-on'];

type ExperienceItemTitleProps = {
  experience: ExperienceType;
};

const ExperienceItemTitle = ({ experience }: ExperienceItemTitleProps) => {
  return (
    <div className="experienceItemTitle">
      {experience.title}
      {experience.published ? (
        <Badge status="success" text="Published" />
      ) : (
        <Badge status="warning" text="Unpublished" />
      )}
    </div>
  );
};

export const ExperienceItem = ({ field, experience, mode, remove }: Props) => {
  if (!field || !experience) {
    return null;
  }

  const avatar =
    (experience.included === 'Included'
      ? experience.pictures?.[0]
      : experience.emoji) || experience.avatar;

  const renderAvatar = () => (
    <div
      className={`experience-item__avatar ${
        avatar?.name.includes('emoji') ? 'experience-item__avatar-emoji' : ''
      }`}
    >
      {avatar && <PictureComponent {...avatar} width={56} />}
    </div>
  );

  const renderIncluded = () => (
    <Form.Item name={[field.name, 'included']} rules={RULES} noStyle>
      {mode === 'edit' ? (
        <Select
          optionFilterProp="children"
          placeholder="Select"
          dropdownClassName="dropdown-experiences"
        >
          {INCLUDE_OPTIONS.map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      ) : (
        <div className="view-text">{experience.included}</div>
      )}
    </Form.Item>
  );

  const renderEdit = () => (
    <div className="edit-link">
      <Link to={`/experiences/${experience.id}/edit`} target="_blank">
        <EditOutlined />
      </Link>
    </div>
  );

  const renderDelete = () => <DeleteOutlined onClick={remove} />;

  const renderDragHandler = () => <SortableHandle key="handle" />;

  const renderActions = () => {
    if (mode === 'edit') {
      return [
        renderIncluded(),
        renderEdit(),
        renderDelete(),
        renderDragHandler(),
      ];
    }

    return [renderIncluded()];
  };

  return (
    <SListItem
      title={<ExperienceItemTitle experience={experience} />}
      description={experience.description}
      avatar={renderAvatar()}
      actions={renderActions()}
      className="experience-item"
    />
  );
};

const SortableItem = SortableElement(ExperienceItem);

const SortableExperienceItem = ({ disabled, ...props }: SortableProps) => (
  <SortableItem disabled={disabled} {...props} />
);

type Props = {
  field?: any;
  experience?: ExperienceType;
  mode: string;
  remove: () => void;
};

type SortableProps = Props & {
  index: number;
  disabled?: boolean;
};

export default SortableExperienceItem;
