import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { GiftCardEditForm, RefundForm } from 'app/typings/giftCard';
import { apiMutation } from 'app/utils/request/api';

import { pathDetails } from './useGetGiftCards';

const savePath = 'admin/gift-cards/:code' as const;
const refundPath = '/admin/gift-cards/:code/refund' as const;
const resendConfirmationPath =
  '/admin/gift-cards/resendConfMail/:code' as const;
const resendGiftCardPath = '/admin/gift-cards/resendGiftCard/:code' as const;

export const useSaveGiftCard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    number,
    unknown,
    { code: string; body: GiftCardEditForm },
    unknown
  >({
    mutationFn: async (payload) =>
      apiMutation(
        'PUT',
        {
          path: savePath,
          params: { code: payload.code },
        },
        payload.body
      ),
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries([pathDetails, { code: payload.code }]);
      message.success('Gift card edited');
    },
    onError: (_, payload) => {
      message.error(`Failed to edit gift card #${payload.code}`);
    },
  });
};

export const useRefundGiftCard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    number,
    unknown,
    { code: string; body: RefundForm },
    unknown
  >({
    mutationFn: async (payload) =>
      apiMutation(
        'POST',
        {
          path: refundPath,
          params: { code: payload.code },
        },
        payload.body
      ),
    onSuccess: (_, payload) => {
      message.success('Successfully refund gift card');
      queryClient.invalidateQueries([pathDetails, { code: payload.code }]);
    },
    onError: (_, payload) => {
      message.error(`Failed to refund gift card #${payload.code}`);
    },
  });
};

export const useResendConfirmationGiftCard = () => {
  return useMutation<number, unknown, { code: string }, unknown>({
    mutationFn: async (payload) =>
      apiMutation('POST', {
        path: resendConfirmationPath,
        params: { code: payload.code },
      }),
    onSuccess: () => {
      message.success('Confirmation resent');
    },
    onError: () => {
      message.error(`Failed to resend confirmation`);
    },
  });
};

export const useResendGiftCard = () => {
  return useMutation<number, unknown, { code: string }, unknown>({
    mutationFn: async (payload) =>
      apiMutation('POST', {
        path: resendGiftCardPath,
        params: { code: payload.code },
      }),
    onSuccess: () => {
      message.success('E-card resent');
    },
    onError: (_, payload) => {
      message.error(`Failed to resend e-card #${payload.code}`);
    },
  });
};
