import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import { Experience, ExperiencesFormQueryParam } from 'app/typings/experiences';
import { apiRequest } from 'app/utils/request/api';

const pathExperiencesCategories = '/experiences/categories' as const;

const pathExperiences = '/experiences' as const;

export const pathExperiencesByHotelId =
  '/hotels/:hotelId/experiences?includeStaycationExp=false' as const;

export function useExperiences(queryParams: ExperiencesFormQueryParam) {
  const queryKey = [pathExperiences, { queryParams }];

  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      apiRequest({
        path: pathExperiences,
        queryParams,
      }) as Promise<Experience[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export function useExperiencesByHotelId(hotelId: number) {
  return useQuery({
    queryKey: [pathExperiencesByHotelId],
    queryFn: () =>
      apiRequest({
        path: pathExperiencesByHotelId,
        params: { hotelId: hotelId.toString() },
      }) as Promise<Experience[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export function useExperiencesCategories() {
  return useQuery({
    queryKey: [pathExperiencesCategories],
    queryFn: () =>
      apiRequest({
        path: pathExperiencesCategories,
      }) as Promise<ExperienceCategory[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
