import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { useState } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import { SList } from 'app/components/StaycationUI/List';
import { RoomType } from 'app/components/commons/Package/General/Types';

import SortableRoomItem from './RoomItem/RoomItem';
import './RoomList.scss';
import RoomModal from './RoomModal/RoomModal';

export const RoomList = ({ mode, rooms, allRooms }: Props) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  const isEmpty = !rooms || !rooms.length;

  return (
    <div className="list-rooms">
      <Form.List name="rooms">
        {(fields, { add, remove }) => {
          const onSelect = (values: any) =>
            values
              .filter((room: any) => !rooms?.some((e) => e.id === room.id))
              .forEach((room: any) => add({ ...room, included: null }));

          return (
            <>
              <SList
                data={mode === 'edit' || !isEmpty ? fields : []}
                renderItem={(field, index) => (
                  <SortableRoomItem
                    field={field}
                    room={rooms?.[index]}
                    mode={mode}
                    remove={() => remove(index)}
                    key={index.toString()}
                    index={index}
                    disabled={mode === 'view'}
                  />
                )}
                bordered
              />
              {mode === 'edit' && (
                <div
                  onClick={() => setIsSelectorOpen(true)}
                  className="list-rooms__add-button"
                >
                  <PlusOutlined />
                  <span className="text">{`Add rooms`}</span>
                </div>
              )}
              <RoomModal
                visible={isSelectorOpen}
                onSelect={onSelect}
                onCancel={() => setIsSelectorOpen(false)}
                allRooms={allRooms}
              />
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

const SortableList = SortableContainer(RoomList);

const SortableRoomList = ({ move, ...props }: SortableProps) => (
  <SortableList axis="y" useDragHandle onSortEnd={move} {...props} />
);

type Props = {
  mode: string;
  rooms?: Array<RoomType>;
  allRooms?: Array<RoomType>;
};

type SortableProps = Props & {
  move: (indexes: { oldIndex: number; newIndex: number }) => void;
};

export default SortableRoomList;
