import { Button, Col, Row, StepProps } from 'antd';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

type VCCModalFooterProps = {
  setCurrentStep: (step: number) => void;
  currentStep: number;
  steps: StepProps[];
  onSubmit: () => Promise<void>;
  activateButtonLoading: boolean;
};

export const VCCModalFooter = ({
  setCurrentStep,
  currentStep,
  steps,
  onSubmit,
  activateButtonLoading,
}: VCCModalFooterProps) => {
  const { track } = useAmplitude();

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNextStep = async () => {
    setCurrentStep(currentStep + 1);
    track('Click on next step', { step: steps[currentStep + 1].title });
  };

  return (
    <Row justify={'space-between'}>
      <Col>
        {currentStep > 0 && !activateButtonLoading && (
          <Button type="default" onClick={handlePreviousStep}>
            Back
          </Button>
        )}
      </Col>
      <Col>
        {currentStep !== steps.length - 1 && (
          <Button onClick={handleNextStep}>Next</Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button
            type="primary"
            onClick={onSubmit}
            loading={activateButtonLoading}
          >
            Activate
          </Button>
        )}
      </Col>
    </Row>
  );
};
