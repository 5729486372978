import { CheckCircleOutlined } from '@ant-design/icons';

import TagWithIcon from 'app/components/commons/TagWithIcon/TagWithIcon';

export const PaymentMethodTag = () => (
  <TagWithIcon
    text={'Your payment method'}
    icon={<CheckCircleOutlined />}
    type={'success'}
  />
);
