import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

type PackageTagsPayload = {
  tagIds: number[];
  isGem: boolean;
  packageId: number;
};

type PackageTagsResponse = {
  tagIds: number[];
  isGem: boolean;
};

const path = '/packages/:packageId/tags' as const;

export function usePackageTagsSave() {
  return useMutation({
    mutationFn: async (payload: PackageTagsPayload) =>
      apiMutation(
        'PUT',
        {
          path,
          params: { packageId: `${payload.packageId}` },
        },
        payload
      ) as Promise<PackageTagsResponse>,
    onSuccess: (_, payload) => {
      message.success(`Package ${payload.packageId} tags updated`);
    },
    onError: (error, payload) => {
      message.error(
        `Error saving tags for package ${payload.packageId}: ${error}`
      );
    },
  });
}
