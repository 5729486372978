import { InfoCircleOutlined } from '@ant-design/icons';

import './RenderInfo.scss';

type RenderInfoProps = {
  title: string;
  content?: string;
  id?: string;
  hasAmountDescription?: boolean;
};

const RenderInfo = ({
  title,
  content,
  id,
  hasAmountDescription = false,
}: RenderInfoProps) => {
  return (
    <div className="render-info">
      <div className="render-info-title">{title}</div>
      <div className="render-info-content" id={id}>
        {content}
      </div>
      {hasAmountDescription && (
        <div className="render-info-description">
          <InfoCircleOutlined />
          Please credit the exact indicated amount.
        </div>
      )}
    </div>
  );
};

export default RenderInfo;
