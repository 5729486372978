import {
  EARLY_CHECKIN,
  LATE_CHECKOUT,
} from 'app/components/commons/Experience/Availability/utils';
import { useGetHotel } from 'app/hooks/data/hotels/useHotel';
import { useAdditionalPax } from 'app/hooks/data/useAdditionalPax';
import { useCountries } from 'app/hooks/data/useCountries';
import { useExperiencesByHotelId } from 'app/hooks/data/useExperience';
import { useRooms } from 'app/hooks/data/useRooms';
import { useAppSelector } from 'app/redux/hooks';
import {
  selectHotelAdminHotelId,
  selectHotelAdminInventory,
} from 'app/redux/selectors/hotelAdmin';
import {
  BABY_COT_BED_CATEGORY_ID,
  Bed,
  DAY_PACKAGE_ROOM_CATEGORY_ID,
  GUEST_BED_BED_CATEGORY_ID,
} from 'app/typings';

import { Task } from './Types';
import {
  createTaskInformationsLists,
  createTaskListsFromTasksInformations,
} from './Utils';

export const useTaskCenter = () => {
  const hotelId = useAppSelector(selectHotelAdminHotelId);

  const { data: hotel } = useGetHotel(hotelId);

  const { data: countries } = useCountries();

  const country = countries?.find((c) => c.id === hotel?.countryId);

  const inventory = useAppSelector(selectHotelAdminInventory);
  const isMoment = inventory?.pkg.dayPackage;
  const childrenAllowed = inventory?.pkg.childrenAllowed;

  const hasChannelManager = !!(
    hotel?.channelManager && hotel?.channelManager !== 'NONE'
  );
  const { data: rooms } = useRooms(hotelId as number);
  const { data: packageEG } = useAdditionalPax(hotelId as number);
  const { data: experiences } = useExperiencesByHotelId(hotelId as number);

  const filteredRooms = rooms?.filter(
    (room) => room.categoryId !== DAY_PACKAGE_ROOM_CATEGORY_ID
  );

  const hasActivatedPricingRule = !!hotel?.autoDiscountRate;
  const hasActivatedLastMinutePricingRule = !!hotel?.lastMinuteAutoDiscountRate;

  const hasBabyCot = !!(
    filteredRooms &&
    filteredRooms.length > 0 &&
    filteredRooms.some((room) =>
      room.beds.some((bed: Bed) => bed.categoryId === BABY_COT_BED_CATEGORY_ID)
    )
  );

  const hasGuestBed = !!(
    filteredRooms &&
    filteredRooms.length > 0 &&
    !!filteredRooms.some((room) =>
      room.beds.some((bed: Bed) => bed.categoryId === GUEST_BED_BED_CATEGORY_ID)
    )
  );
  const hasActivatedAdditionalAdult =
    packageEG?.additionalAdultPrice !== undefined;
  const hasActivatedSingleCustomerDiscount =
    packageEG?.singleCustomerDiscount !== undefined;

  const hasConfiguredAdditionalChildPrice =
    packageEG?.additionalChildPrice !== undefined;

  const hasEarlyCheckInExp = !!experiences?.some(
    (exp) => exp.categoryId === EARLY_CHECKIN
  );

  const hasLateCheckOutExp = !!experiences?.some(
    (exp) => exp.categoryId === LATE_CHECKOUT
  );

  const hasActivatedAutoInventory = !!hotel?.stockScrapperEnabled;

  const issuingAllowed = !!country?.issuingAllowed;
  const issuingActivated = !!hotel?.issuingActivated;

  const { doneTasksInformations, missingTaskInformations } =
    createTaskInformationsLists(
      hasBabyCot,
      hasGuestBed,
      hasChannelManager,
      hasActivatedPricingRule,
      hasActivatedLastMinutePricingRule,
      hasActivatedAdditionalAdult,
      hasActivatedSingleCustomerDiscount,
      hasConfiguredAdditionalChildPrice,
      hasEarlyCheckInExp,
      hasLateCheckOutExp,
      hasActivatedAutoInventory,
      issuingAllowed,
      issuingActivated,
      isMoment,
      childrenAllowed
    );

  const {
    doneTasks,
    missingTasks,
  }: { doneTasks: Task[]; missingTasks: Task[] } =
    createTaskListsFromTasksInformations(
      doneTasksInformations,
      missingTaskInformations
    );

  return {
    doneTasks,
    missingTasks,
  };
};
