export const CONTROL_GROUP = 'control' as const;
export const TREATMENT_GROUP = 'treatment' as const;

export const EXPERIMENT_GROUP = {
  'issuing-vcc': [TREATMENT_GROUP] as const,
} as const;

export type FeatureFlag = keyof typeof EXPERIMENT_GROUP;

export function assertValueIsSupported<T extends FeatureFlag>(
  key: T,
  value: string
): value is (typeof EXPERIMENT_GROUP)[T][number] {
  return (EXPERIMENT_GROUP[key] as unknown as string[]).includes(value);
}
