import { PSP } from 'app/typings/PSP';

import { assertNever } from './typing';

export const formatPSP = (psp: PSP) => {
  switch (psp) {
    case PSP.Adyen:
      return 'Adyen Payout';
    case PSP.Stripe:
      return 'Stripe Payout';
    case PSP.StripeIssuing:
      return 'Stripe VCC';

    default:
      assertNever(psp);
  }
};
