import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Input, Layout, Table, Tag } from 'antd';
import classNames from 'classnames';
import { differenceInCalendarDays } from 'date-fns';
import fileDownload from 'js-file-download';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import ModalCheckBoxGroup from 'app/components/commons/ModalCheckBoxGroup/ModalCheckBoxGroup';
import ActionCellMenu from 'app/components/lists/ActionCellMenu';
import { StatusCell } from 'app/components/lists/cells';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useGetBookingsList } from 'app/hooks/data/extranet/bookings/useBooking';
import { useGetHotel } from 'app/hooks/data/hotels/useHotel';
import {
  useAmplitude,
  useAmplitudeViewPage,
} from 'app/hooks/useAmplitude/useAmplitude';
import { tryFetchBookingsCSV } from 'app/redux/actions/hotelAdmin';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { ExtranetBookingStatus } from 'app/redux/models/ExtranetBooking/ExtranetBooking';
import { PAX } from 'app/typings/PAX';
import { BookingListItem } from 'app/typings/extranetBookings/booking';
import { VccStatus } from 'app/typings/vcc';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import { useSearchParams } from 'app/utils/searchParams';
import { formatSortingForAPI } from 'app/utils/sort';
import { parseUppercaseSnakeTextToCapitalize } from 'app/utils/string';
import { toSnakeCase } from 'app/utils/strings';
import { getVCCStatusDisplayInfo } from 'app/utils/vcc';

import 'styles/pages/Extranet/extranet-table.scss';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import BookingDetail from './BookingDetail/BookingDetail';
import ModalCheckBox from './ModalCheckBox';

const { Content } = Layout;
const { Column } = Table;

declare type SortOrder = 'descend' | 'ascend' | null;

const { RangePicker } = DatePicker;

const PAGE = 'Bookings';

const formatAdults = (adults: number) =>
  `${adults} ${adults === 1 ? 'adult' : 'adults'}`;
const formatChildren = (children: number) =>
  `${children} ${children === 1 ? 'child' : 'children'}`;
const formatBabies = (babies: number) =>
  `${babies} ${babies === 1 ? 'baby' : 'babies'}`;
const formatPax = (customer: PAX) =>
  `${formatAdults(customer.adults)}, ${formatChildren(
    customer.children
  )}, ${formatBabies(customer.babies)}`;
const formatDate = (date: string) => moment(date).format('DD/MM/YYYY');

const sortingIntitialState = {
  fieldName: 'checkin',
  sortOrder: 'DESC',
};
const BOOKING_CSV_FILE_NAME = 'bookings.csv';

const ExtranetBooking = () => {
  const hotelId = useAppSelector((state) => state.hotelAdmin.hotelId);
  const bookingsCSV = useAppSelector((state) => state.hotelAdmin.bookingsCSV);

  const { data: hotel } = useGetHotel(hotelId);

  const location = useLocation();
  const { setSearchParams, clearSearchParam } = useSearchParams();

  const dispatch = useAppDispatch();

  const { setPage } = useContext(TaskCenterContext);

  useEffect(() => {
    setPage(PAGE);
  });

  const { currencyFormatter } = useContext(CurrencyContext);
  const [bookingCode, setBookingCode] = useState<string | undefined>(undefined);
  const [scrollVCC, setScrollVCC] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [sortQueryParams, setSortQueryParams] = useState<
    | {
        fieldName: string;
        sortOrder: string;
      }
    | undefined
  >(sortingIntitialState);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const [statusFilter, setStatusFilter] = useState<ExtranetBookingStatus[]>([
    ExtranetBookingStatus.Accepted,
    ExtranetBookingStatus.Cancelled,
  ]);

  const defaultVccStatusFilters = [
    ...Object.values(VccStatus).map((value) => value),
    'BANK_TRANSFER',
  ];

  const [vccStatusFilter, setVccStatusFilter] = useState(
    defaultVccStatusFilters
  );

  const [askToDownloadCSV, setAskToDownloadCSV] = useState(false);
  const { track } = useAmplitude();

  const formatSorting = useCallback(
    () =>
      sortQueryParams
        ? `${sortQueryParams.fieldName}:${sortQueryParams.sortOrder}`
        : undefined,
    [sortQueryParams]
  );

  useEffect(() => {
    if (document.readyState === 'complete') {
      const query = new URLSearchParams(location.search);
      const valueVCC = query.get('bookingVCC');
      const valueBooking = query.get('booking');

      if (valueVCC) {
        setBookingCode(valueVCC);
        setScrollVCC(true);

        const element = document.getElementById('virtual-card');

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }

      if (valueBooking) {
        setBookingCode(valueVCC ?? valueBooking);
      }
    }
  }, [location.search]);

  const formatVccStatus = useCallback(() => {
    const vccStatusWithoutVCC = vccStatusFilter.filter(
      (value) => value !== 'VCC'
    );

    if (vccStatusWithoutVCC.length === defaultVccStatusFilters.length) {
      return [];
    }

    return vccStatusWithoutVCC;
  }, [defaultVccStatusFilters.length, vccStatusFilter]);

  const debouncedSearch = useDebounce(search);

  const makePayload = useCallback(
    () => ({
      hotelId: hotelId ?? 0,
      offset: (pageNumber - 1) * 50,
      search: debouncedSearch || undefined,
      sorting: formatSorting() || undefined,
      beginning: startDate,
      ending: endDate,
      status: statusFilter,
      vccStatus: formatVccStatus(),
    }),
    [
      debouncedSearch,
      endDate,
      formatSorting,
      formatVccStatus,
      hotelId,
      pageNumber,
      startDate,
      statusFilter,
    ]
  );

  const { data: bookingsData, isLoading: bookingsLoading } = useGetBookingsList(
    makePayload(),
    hotelId
  );

  useEffect(() => {
    setPageNumber(1);
  }, [
    setPageNumber,
    hotelId,
    search,
    startDate,
    endDate,
    statusFilter,
    vccStatusFilter,
    sortQueryParams,
  ]);

  useEffect(() => {
    if (askToDownloadCSV && !bookingsCSV.isLoading) {
      setAskToDownloadCSV(false);
      fileDownload(bookingsCSV.data, BOOKING_CSV_FILE_NAME);
    }
  }, [askToDownloadCSV, bookingsCSV]);

  useAmplitudeViewPage({ page: 'bookings' });

  const handleFilterDate = (interval: RangeValue<Moment>) => {
    if (!interval || !interval[0] || !interval[1]) {
      setStartDate(undefined);
      setEndDate(undefined);
    } else {
      setStartDate(interval[0].format('YYYY-MM-DD'));
      setEndDate(interval[1].format('YYYY-MM-DD'));
    }
  };

  const handleSorting = (field: string, sort: SortOrder) => {
    const newSortQueryParams = sort
      ? {
          fieldName: toSnakeCase(field),
          sortOrder: formatSortingForAPI(sort),
        }
      : undefined;

    setSortQueryParams(newSortQueryParams);
  };

  const downloadCSV = () => {
    track('Download Booking CSV');
    setAskToDownloadCSV(true);

    if (hotelId) {
      dispatch(tryFetchBookingsCSV(makePayload()));
    }
  };

  const handleStatusFilter = (statuses: ExtranetBookingStatus[]) => {
    if (!statuses.length) {
      setStatusFilter([
        ExtranetBookingStatus.Accepted,
        ExtranetBookingStatus.Cancelled,
      ]);
    } else {
      setStatusFilter(statuses);
    }
  };

  const pagination = (className: string) => (
    <div className={className}>
      <Button
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{pageNumber}</Button>
      <Button
        disabled={(bookingsData?.bookings?.length ?? 0) < 50}
        onClick={() => setPageNumber(pageNumber + 1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search here..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
        <div className="checkin-input-title">Check-in period:</div>
        <RangePicker
          onChange={handleFilterDate}
          format="ddd DD MMM"
          ranges={{
            Today: [moment(), moment()],
            'This Week': [
              moment().startOf('isoWeek'),
              moment().endOf('isoWeek'),
            ],
          }}
          className="range-picker"
          size="large"
        />
      </div>
      <div className="right">
        <Button
          onClick={downloadCSV}
          className="button"
          size="large"
          loading={bookingsCSV.isLoading}
        >
          Download .csv
        </Button>
        {pagination('button-wrapper')}
      </div>
    </div>
  );

  const handleRowActions = ({ code }: BookingListItem) => ({
    onClick: () => {
      setBookingCode(code);
      setSearchParams({ booking: code });
    },
  });

  const handleActionClick = ({ code }: BookingListItem) => {
    setBookingCode(code);
    setSearchParams({ booking: code });
  };

  const closeDrawer = () => {
    setBookingCode(undefined);
    clearSearchParam('booking');
    clearSearchParam('bookingVCC');
  };

  if (!hotelId) {
    return <></>;
  }

  return (
    <Layout className="extranet-table">
      <ExtranetHeader page={PAGE} />
      <Content className="body">
        <Table
          dataSource={bookingsData?.bookings ?? []}
          pagination={false}
          rowKey="code"
          onRow={handleRowActions}
          title={header}
          loading={bookingsLoading}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            handleSorting(field, order);
          }}
        >
          <Column
            title="Date"
            dataIndex="createdAt"
            render={formatDate}
            sorter
          />
          <Column
            title="Guest"
            render={({ customer, pax }) => (
              <div style={{ minWidth: 200 }}>
                <div>{`${customer.gender} ${customer.firstName} ${customer.lastName}`}</div>
                <div style={{ color: '#8C8C8C' }}>{formatPax(pax)}</div>
              </div>
            )}
          />
          <Column
            title="Check-in"
            dataIndex="checkin"
            render={formatDate}
            sorter
          />
          <Column title="Check-out" dataIndex="checkout" render={formatDate} />
          <Column
            title="Nights"
            dataIndex="nbNight"
            render={(_, record: BookingListItem) => {
              const nbNight = differenceInCalendarDays(
                new Date(record.checkout ?? ''),
                new Date(record.checkin ?? '')
              );

              return (
                <div
                  className={classNames([
                    { 'ug-multinight-bookings': nbNight > 1 },
                  ])}
                  nb-night={nbNight}
                >
                  {nbNight}
                </div>
              );
            }}
          />
          <Column
            title="Room"
            dataIndex="roomCategory"
            render={(roomCategory) => <div>{roomCategory}</div>}
          />
          <Column title="Extras" dataIndex="extrasCount" />
          <Column
            title="Total incl. VAT"
            dataIndex="totalAmount"
            render={(totalAmount) => `${currencyFormatter(totalAmount || 0)}`}
          />
          {hotel?.hasVccBookings && (
            <Column
              title={
                <ModalCheckBoxGroup
                  title="VCC status"
                  isCenter={false}
                  onChange={(statuses) =>
                    setVccStatusFilter(statuses as VccStatus[])
                  }
                  items={[
                    {
                      title: 'VCC',
                      children: [
                        ...Object.values(VccStatus).map((value) => ({
                          key: value,
                          title: parseUppercaseSnakeTextToCapitalize(value),
                        })),
                      ],
                      key: 'VCC',
                      selectable: false,
                    },
                    {
                      title: <Divider />,
                      key: 'divider',
                      className: 'divider-element',
                      disabled: true,
                      checkable: false,
                      isDivider: true,
                    },
                    { title: 'Bank transfer', key: 'BANK_TRANSFER' },
                  ]}
                  value={vccStatusFilter}
                />
              }
              dataIndex="code"
              render={(_, record: BookingListItem) => {
                if (record.issuingCardId) {
                  const status = getVCCStatusDisplayInfo(record.vccStatus);

                  return <Tag color={status.color}>{status.text}</Tag>;
                }

                return 'Bank transfer';
              }}
            />
          )}
          <Column title="Booking ID" dataIndex="code" />
          <Column
            title={
              <ModalCheckBox
                title="Status"
                defaultValue={[
                  ExtranetBookingStatus.Accepted,
                  ExtranetBookingStatus.Cancelled,
                ]}
                onChange={handleStatusFilter}
                items={[
                  {
                    value: ExtranetBookingStatus.Accepted,
                    label: 'Confirmed',
                  },
                  {
                    value: ExtranetBookingStatus.Cancelled,
                    label: 'Cancelled',
                  },
                ]}
                value={statusFilter}
              />
            }
            dataIndex="status"
            render={StatusCell}
            className="sort-column"
          />
          <Column
            key="actions"
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            render={(entity) => (
              <ActionCellMenu
                entity={entity}
                handleActionClick={handleActionClick}
              />
            )}
          />
        </Table>
        {pagination('footer')}
      </Content>
      <BookingDetail
        bookingCode={bookingCode}
        hotelId={hotelId}
        scrollVCC={scrollVCC}
        onClose={() => closeDrawer()}
      />
    </Layout>
  );
};

export default ExtranetBooking;
