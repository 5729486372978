import {
  HistoryOutlined,
  PlusSquareOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { Fragment } from 'react';

import { WeekDays } from 'app/components/commons/Experience/Availability/utils';
import BabyBedIcon from 'app/components/commons/icons/BabyBedIcon';
import BedIcon from 'app/components/commons/icons/BedIcon';
import { useBedCategories } from 'app/hooks/data/rooms/useBedCategories';
import {
  BABY_COT_BED_CATEGORY_ID,
  GUEST_BED_BED_CATEGORY_ID,
} from 'app/typings';
import { ExtranetExperience, ExtranetRoom } from 'app/typings/packages';
import { sortDaysOfWeek } from 'app/utils/dates';
import { parseUppercaseTextToCapitalize } from 'app/utils/string';
import { pluralize } from 'app/utils/strings';

import PackageDetailRoom from './PackageDetailRoom';
import PackageDetailRule, { Rule } from './PackageDetailRule';

export type PackageDetailProps =
  | {
      type: 'experience';
      data: ExtranetExperience;
    }
  | { type: 'room'; data: ExtranetRoom };

const PackageDetail = ({ type, data }: PackageDetailProps) => {
  const { data: bedCategories } = useBedCategories();

  if (type === 'experience') {
    const experience = data;

    const validRules = [
      experience.availableDates &&
        experience.availableDates.length > 0 && {
          type: 'Specific dates rule',
          value: experience.availableDates.join(', '),
          icon: <ScheduleOutlined />,
          description: (
            <p>
              This experience is only available on{' '}
              <span className="bold">specific dates.</span>
            </p>
          ),
        },
      experience.availableDays &&
        experience.availableDays.length > 0 &&
        experience.availableDays.length < Object.values(WeekDays).length && {
          type: 'Specific days',
          value: experience.availableDays.join(', '),
          icon: <ScheduleOutlined />,
          description: (
            <p>
              This experience is{' '}
              <span className="bold">only available on specific days : </span>
              {sortDaysOfWeek(
                experience.availableDays.map((day) =>
                  parseUppercaseTextToCapitalize(day)
                )
              ).join(', ')}
            </p>
          ),
        },
      experience.maxQuantity && {
        type: 'Maximum quantity',
        value: experience.maxQuantity,
        icon: <PlusSquareOutlined />,
        description: (
          <p>
            You decided to set your maximum quantity for this add-on at
            {
              <span className="bold">
                {' '}
                {experience.maxQuantity} per booking.
              </span>
            }
          </p>
        ),
      },
      experience.closedHoursBeforeCheckin && {
        type: 'Last minute restriction',
        value: experience.closedHoursBeforeCheckin,
        icon: <HistoryOutlined />,
        description: (
          <p>
            You decided to remove the availability of this add-on
            {
              <span className="bold">
                {' '}
                {experience.closedHoursBeforeCheckin} hours before check-in.
              </span>
            }
          </p>
        ),
      },
    ].filter(Boolean) as Rule[];

    return (
      <>
        {validRules.map((rule) => (
          <Fragment key={rule.value}>
            <PackageDetailRule rule={rule} />
          </Fragment>
        ))}
      </>
    );
  }

  const room = data;
  const additionalCategories = [
    BABY_COT_BED_CATEGORY_ID,
    GUEST_BED_BED_CATEGORY_ID,
  ];

  const listBeds = room.beds;

  const bedsWithCategory = listBeds.map((bed) => {
    const bedCategory = bedCategories?.find((cat) => cat.id === bed.categoryId);

    return { ...bed, category: bedCategory };
  });

  const defaultBeds = bedsWithCategory.filter(
    (bed) => !additionalCategories.includes(bed.categoryId ?? 0)
  );

  const additionalBeds = bedsWithCategory.filter((bed) =>
    additionalCategories.includes(bed.categoryId ?? 0)
  );

  return (
    <div className="package-detail-room-container">
      {defaultBeds.length > 0 && (
        <div className="detail-room-list">
          {defaultBeds.map((bed, index) => (
            <div className="detail-room-list-bed" key={bed.id}>
              {index === 0 && (
                <div className="package-detail-room-type">DEFAULT BEDS</div>
              )}
              <PackageDetailRoom
                icon={<BedIcon />}
                title={bed.category?.name ?? ''}
                description={pluralize(
                  bed.category?.capacity,
                  'guest',
                  'guests'
                )}
              />
            </div>
          ))}
        </div>
      )}
      {additionalBeds.length > 0 && (
        <div className="detail-room-list">
          {additionalBeds.map((bed, index) => (
            <div className="detail-room-list-bed" key={bed.id}>
              {index === 0 && (
                <div className="package-detail-room-type">ADDITIONAL</div>
              )}
              <PackageDetailRoom
                icon={
                  bed.categoryId === BABY_COT_BED_CATEGORY_ID ? (
                    <BabyBedIcon />
                  ) : (
                    <BedIcon />
                  )
                }
                title={bed.category?.name ?? ''}
                description={pluralize(
                  bed.category?.capacity,
                  'guest',
                  'guests'
                )}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PackageDetail;
