import './BookingDetailSection.scss';

type BookingDetailSectionProps = {
  title: string;
  name: string;
  children: React.ReactNode;
};

const BookingDetailSection = ({
  title,
  name,
  children,
}: BookingDetailSectionProps) => (
  <div className="booking-detail-section" id={name}>
    <div className="section-title">{title}</div>
    <div className="section-content">{children}</div>
  </div>
);

export default BookingDetailSection;
