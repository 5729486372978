import { Fragment, useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { BookingDetails } from 'app/typings/extranetBookings/booking';

import { RenderPaymentDetail } from './RenderPaymentDetail';

type RenderExtraProps = {
  booking: BookingDetails;
};

const RenderExtra = ({ booking }: RenderExtraProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);

  const extras = booking.experiences.filter(
    (exp) => !exp.included && (exp.quantity ?? 0) > 0 && exp.hotelId
  );

  const staycationExperiences = booking.experiences.filter(
    (exp) => !exp.hotelId
  );

  if (!staycationExperiences.length && !extras.length) {
    return <></>;
  }

  return (
    <div className="payment-detail">
      {staycationExperiences.map((stayExp, idx) => {
        const expQuantity = stayExp.included ? 1 : stayExp.quantity;

        const title = idx === 0 ? 'Extras' : undefined;
        const removeDivider =
          idx !== staycationExperiences.length - 1 || !!extras.length;

        return (
          <RenderPaymentDetail
            title={title}
            content={`${expQuantity} x ${stayExp.category.name}`}
            value="Included"
            removeDivider={removeDivider}
          />
        );
      })}
      {extras?.map((extra, idx) => {
        const title =
          idx === 0 && !staycationExperiences.length ? 'Extras' : undefined;
        const removeDivider = idx !== extras.length - 1;

        return (
          <Fragment key={extra.id}>
            <RenderPaymentDetail
              title={title}
              content={`${extra?.quantity} x ${extra.category.name}`}
              value={currencyFormatter(extra.quantity * extra.price)}
              removeDivider={removeDivider}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default RenderExtra;
