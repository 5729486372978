import { Experiment } from '@amplitude/experiment-js-client';
import type {
  ExperimentClient,
  ExperimentUser,
} from '@amplitude/experiment-js-client';

import {
  CONTROL_GROUP,
  EXPERIMENT_GROUP,
  FeatureFlag,
  TREATMENT_GROUP,
  assertValueIsSupported,
} from './featureFlags';

const amplitudeApiExperimentDeploymentKey =
  process.env.REACT_APP_AMPLITUDE_API_EXPERIMENT_DEPLOYMENT_KEY ??
  'AMPLITUDE_API_EXPERIMENT_DEPLOYMENT_KEY';

let experimentClient: ExperimentClient | undefined;

export async function initialize(user?: ExperimentUser) {
  if (
    amplitudeApiExperimentDeploymentKey ===
    'AMPLITUDE_API_EXPERIMENT_DEPLOYMENT_KEY'
  ) {
    // Avoid logging when running tests
    if (!process.env.JEST_WORKER_ID) {
      console.log('No Amplitude Experiments deployment key provided.');
    }

    return;
  }

  if (experimentClient) {
    return;
  }

  experimentClient = Experiment.initializeWithAmplitudeAnalytics(
    amplitudeApiExperimentDeploymentKey,
    {
      automaticFetchOnAmplitudeIdentityChange: true,
    }
  );

  await experimentClient.start(user);
}

export function getExperimentGroup<T extends FeatureFlag>(
  key: T
): (typeof EXPERIMENT_GROUP)[T][number] | typeof CONTROL_GROUP {
  if (!experimentClient) {
    return CONTROL_GROUP;
  }

  const { value } = experimentClient.variant(key);

  if (!value) {
    return CONTROL_GROUP;
  }

  if (!assertValueIsSupported(key, value)) {
    return CONTROL_GROUP;
  }

  return value;
}

export function isControlGroup(key: FeatureFlag): boolean {
  return getExperimentGroup(key) === CONTROL_GROUP;
}

export function isTreatmentGroup(key: FeatureFlag): boolean {
  return getExperimentGroup(key) === TREATMENT_GROUP;
}
