import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import {
  RefundForm,
  RefundParams,
} from 'app/typings/adminBookings/bookingRefund';
import { apiMutation, apiRequest } from 'app/utils/request/api';

const bookingPath = 'admin/bookings/:bookingCode' as const;
const refundBookingPath = 'admin/bookings/:bookingCode/refund' as const;

export function useRefundBooking(bookingCode?: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: !!bookingCode
      ? async (payload: RefundForm) =>
          apiMutation(
            'POST',
            {
              path: refundBookingPath,
              params: { bookingCode },
            },
            payload
          ) as Promise<void>
      : async () => {},
    onSuccess: () => {
      message.success(`Refunded booking #${bookingCode}!`);
      queryClient.invalidateQueries({
        queryKey: [bookingPath, { bookingCode }],
      });
    },
    onError: () => {
      message.error(`Failed to refund booking #${bookingCode}!`);
    },
  });
}

export function useBookingRefundParams(bookingCode: string) {
  return useQuery({
    queryKey: [refundBookingPath],
    queryFn: () =>
      apiRequest({
        path: refundBookingPath,
        params: { bookingCode },
      }) as Promise<RefundParams>,
  });
}
