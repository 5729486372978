import { Button } from 'antd';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

import { ExtranetPaymentDisplayProps } from '../../utils';

type VCCActionButtonProps = {
  displayProps: ExtranetPaymentDisplayProps;
  setVCCModalOpened: (opened: boolean) => void;
};

export const VCCActionButton = ({
  displayProps,
  setVCCModalOpened,
}: VCCActionButtonProps) => {
  const { track } = useAmplitude();

  if (displayProps.VCCActive || !displayProps.issuingAllowed) {
    return <></>;
  }

  return (
    <Button
      onClick={() => {
        setVCCModalOpened(true);
        track('Click Configure Virtual Cards');
      }}
      type="primary"
    >
      Configure Virtual Cards
    </Button>
  );
};
