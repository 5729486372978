import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';
import queryString from 'query-string';
import { useMemo } from 'react';

import { Bill } from 'app/redux/models/Bill/Bill';
import { PSP } from 'app/typings/PSP';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/bills' as const;

export type GetBillsQueryParams = {
  search?: string;
  psp?: PSP[];
  sortingColumn?: string;
  sortingOrder?: string;
  offset?: number;
  limit?: number;
};

export function useGetBills(queryParams: GetBillsQueryParams = { offset: 0 }) {
  const stringifiedParams = queryString.stringify(queryParams);
  const key = useMemo(() => [path, { stringifiedParams }], [stringifiedParams]);

  return useQuery({
    queryKey: [key],
    queryFn: () =>
      apiRequest({ path: `${path}?${stringifiedParams}` }).catch((err) =>
        message.error('Error fetching bills', err)
      ) as Promise<Bill[]>,
  });
}
