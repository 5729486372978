import { Button } from 'antd';
import { FC } from 'react';

import './Preview.scss';

const Preview: FC<Props> = ({ onClick }) => (
  <div className="preview-btn">
    <Button size="large" onClick={onClick}>
      Preview
    </Button>
  </div>
);

type Props = {
  onClick?: () => void;
};

export default Preview;
