import { Divider } from 'antd';
import classNames from 'classnames';

type RenderPaymentDetailProps = {
  title?: string;
  content: string;
  value: string;
  description?: string;
  removeDivider?: boolean;
  contentAsTitle?: boolean;
  removeTitle?: boolean;
};

export const RenderPaymentDetail = ({
  title,
  content,
  value,
  description,
  removeDivider = false,
  contentAsTitle = false,
}: RenderPaymentDetailProps) => {
  return (
    <div className="payment-detail">
      {title && <div className="payment-detail-title">{title}</div>}
      <div className="payment-detail-info">
        <div className="content-value">
          <div
            className={classNames([
              'payment-detail-content',
              { asTitle: contentAsTitle },
            ])}
          >
            {content}
          </div>
          <div className="payment-detail-value">{value}</div>
        </div>
        <div className="payment-detail-description">{description}</div>
      </div>
      {!removeDivider && <Divider className="payment-detail-divider" />}
    </div>
  );
};
