import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';

import { Notification } from 'app/components/commons/Notification/Notification';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useRooms } from 'app/hooks/data/useRooms';
import { useAppSelector } from 'app/redux/hooks';
import { selectHotelAdminInventory } from 'app/redux/selectors/hotelAdmin';
import { IHotel } from 'app/typings';

import { setBusinessWeek } from '../../ExtranetInventory/Utils';

import RenderModal from './RenderTaskModal/RenderTaskModal';
import './TaskCenter.scss';
import { Tasks } from './Tasks/Tasks';
import { Task } from './Types';

type TaskCenterProps = {
  hotel: IHotel;
};

const TASK_SOURCE = 'drawer';

export const TaskCenter = ({ hotel }: TaskCenterProps) => {
  const {
    isTaskCenterOpen,
    closeTaskCenter,
    missingTasks,
    doneTasks,
    openTask,
    clickOpenTask,
    clearOpenTask,
  } = useContext(TaskCenterContext);

  const inventory = useAppSelector(selectHotelAdminInventory);

  const { data: rooms } = useRooms(hotel.id);

  const [currentRange] = useState<Interval>(setBusinessWeek(new Date()));

  const [notificationVisible, setNotificationVisible] = useState(false);

  useEffect(() => {
    if (
      !inventory &&
      (openTask === 'Pricing rule' || openTask === 'Last minute pricing rule')
    ) {
      setNotificationVisible(true);
    }
  }, [inventory, openTask]);

  const renderNotification = useCallback(() => {
    const notificationTitle =
      openTask === 'Pricing rule' || openTask === 'Last minute pricing rule'
        ? 'To activate a pricing rule, please contact your Account Manager and publish your Staycation package.'
        : 'To activate Auto Inventory, please contact your Account Manager and publish your Staycation package.';

    return (
      <Notification
        title={notificationTitle}
        onOk={() => {
          clearOpenTask();
          setNotificationVisible(false);
        }}
        visible={true}
        type="warning"
        hasCancelButton={false}
        centered
      />
    );
  }, [openTask, clearOpenTask]);

  const renderTask = (task: Task, index: number) => {
    return (
      <Tasks
        key={index}
        taskInfo={task}
        clickButton={() => clickOpenTask(task, TASK_SOURCE)}
        renderModal={(onCompleteTask) => (
          <RenderModal
            task={task}
            onCompleteTask={onCompleteTask}
            rooms={rooms}
            currentRange={currentRange}
            hotel={hotel}
            inventory={inventory}
          />
        )}
        source={TASK_SOURCE}
      />
    );
  };

  return (
    <Drawer
      title={
        <div className="taskCenter__header">
          <h1>Task center</h1>
        </div>
      }
      className="taskCenter"
      width={'510px'}
      open={isTaskCenterOpen}
      bodyStyle={{ padding: 0 }}
      onClose={closeTaskCenter}
    >
      <div className="container">
        <div className="taskCenter__body">
          <div className="taskCenter__body_explanation">
            <h1>Welcome to your Task Center</h1>
            <p>
              In this space, you can quickly update missing information in order
              to increase your hotel's visibility and attractiveness.
            </p>
          </div>
          <div className="taskCenter__body_task">
            {missingTasks?.length > 0 && (
              <div className="taskCenter__body_todo">
                <div className="section_title">
                  <p>To do</p>
                </div>
                {missingTasks.map((task, index) => renderTask(task, index))}
              </div>
            )}
            {doneTasks?.length > 0 && (
              <div className="taskCenter__body_done">
                <div className="section_title">
                  <CheckCircleOutlined className="doneIcon" />
                  <p>Done</p>
                </div>
                {doneTasks.map((task, index) => renderTask(task, index))}
              </div>
            )}
          </div>
        </div>
        <div className="taskCenter__footer">
          <Button size="large" type="ghost" onClick={closeTaskCenter}>
            Close
          </Button>
        </div>
      </div>
      {notificationVisible && renderNotification()}
    </Drawer>
  );
};
