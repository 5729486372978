import { CheckCircleOutlined } from '@ant-design/icons';

import { TextWithIcon } from 'app/components/commons/TextWithIcon/TextWithIcon';

export const VCCCardContent = () => {
  return (
    <div className="vcc-content">
      <TextWithIcon
        icon={<CheckCircleOutlined />}
        text={
          <>
            <span className="font-weight-600">Instant payment:</span> charge the
            virtual card from the day of check-in.
          </>
        }
      />
      <TextWithIcon
        icon={<CheckCircleOutlined />}
        text={
          <>
            <span className="font-weight-600">No KYC required:</span> instant
            setup, start receiving payments immediately.
          </>
        }
      />
      <ul className="info-list">
        <li>
          <span className="font-weight-600">Net payment:</span> the amount on
          the virtual card is the net amount excluding Staycation’s commission.
        </li>
      </ul>
    </div>
  );
};
