import React from 'react';

const BuilderLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 82 24"
    height="24px"
  >
    <g fill="#fff" clipPath="url(#a)">
      <path
        fillRule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm8.281 18.544c0 .643.522 1.176 1.154 1.176.632 0 1.143-.522 1.143-1.176 0-.655-.51-1.176-1.143-1.176-.632 0-1.154.532-1.154 1.176ZM7.7 19.52c-.01 1.131-.82 1.875-2.551 1.875-1.62 0-2.507-.754-2.607-1.863H4.36c.078.377.344.565.832.565.422 0 .666-.166.666-.388 0-.177-.167-.277-.41-.322l-.966-.189c-1.043-.2-1.775-.71-1.775-1.697 0-1.043.91-1.82 2.43-1.82 1.031 0 1.82.378 2.196.977.034.055.111.044.111-.022v-.866h.577v-1.02l1.798-.367v1.388h.61v1.675h-.61v1.741c0 .289.155.455.421.455h.189v1.675h-.777c-1.054 0-1.63-.577-1.63-1.586v-2.285h-2.23c-.012-.267-.267-.455-.678-.455-.41 0-.632.133-.632.343 0 .167.078.244.344.311l1.609.377c.754.178 1.276.6 1.264 1.498Zm9.586 2.851Zm1.963 0h-1.963l.555-1.453c.022-.044.01-.11-.012-.155l-1.375-3.64c-.034-.1-.167-.077-.167.023v4.171h-1.73v-.333c0-.088-.056-.122-.134-.055-.322.289-.832.477-1.331.477-1.442 0-2.607-1.242-2.607-2.862s1.176-2.863 2.651-2.863c.522 0 .988.156 1.287.444.078.078.133.056.133-.044v-.31h3.362l.854 2.451c.034.089.122.089.156 0l.854-2.452h1.964l-2.497 6.601Z"
        clipRule="evenodd"
      />
      <path d="M38.412 13.612c0 1.428-1.224 2.388-3.144 2.388H30.84V7.6h4.488c1.86 0 2.892.684 2.892 2.292 0 .912-.456 1.476-1.344 1.728v.096c1.032.228 1.536.972 1.536 1.896Zm-2.748-3.432c0-.768-.396-.9-1.092-.9H33.3v1.716h1.236c.744 0 1.128-.228 1.128-.816Zm.168 3.252c0-.684-.456-.888-1.056-.888H33.3v1.776h1.476c.6 0 1.056-.204 1.056-.888Zm6.962.924c.673 0 1.045-.276 1.045-.972V7.6h2.46v5.412c0 2.1-1.297 3.156-3.505 3.156-2.207 0-3.503-1.056-3.503-3.156V7.6h2.46v5.784c0 .696.372.972 1.044.972ZM47.535 16V7.6h2.46V16h-2.46Zm3.777 0V7.6h2.448v6.516h3.72V16H51.31Zm14.48-4.2c0 2.88-1.657 4.2-4.297 4.2h-3.36V7.6h3.36c2.436 0 4.296 1.32 4.296 4.2Zm-2.557 0c0-1.8-.684-2.316-1.752-2.316h-.888v4.62h.888c1.068 0 1.752-.504 1.752-2.304Zm3.4 4.2V7.6h6.24v1.884h-3.792v1.392h3.192v1.764h-3.192v1.476h3.864V16h-6.312Zm9.834-3.816V16h-2.46V7.6h4.332c2.016 0 2.712.816 2.712 2.412 0 .996-.804 1.668-1.884 1.932l2.028 3.936V16h-2.724l-1.632-3.816h-.372Zm2.064-2.1c0-.612-.3-.804-.924-.804h-1.14v1.596h1.14c.624 0 .924-.18.924-.792Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h82v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default BuilderLogo;
