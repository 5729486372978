// react-hotjar may be used if future enhancements of hotjar support are needed
import { UserHotjar } from 'app/typings';

export const hotjarIdentify = (user: UserHotjar) => {
  const hotjar = (window as any).hj;

  if (!hotjar) {
    return;
  }

  hotjar('identify', user.id, {
    country_slug: user.countrySlug,
    email: user.email,
    hotel_ids: user.hotelIds,
  });
};

export const hotjarEvent = (eventId: string) => {
  const hotjar = (window as any).hj;

  if (!hotjar) {
    return;
  }

  hotjar('event', eventId);
};
