import { Divider, Form, Select, Spin } from 'antd';
import { useCallback, useEffect } from 'react';

import HowDoesItWork from 'app/components/commons/HowDoesItWork/HowDoesItWork';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import { useRoomsScrapping } from 'app/hooks/data/autoInventory/useScrapping';
import { useRooms } from 'app/hooks/data/useRooms';
import { Room } from 'app/typings';
import {
  AutoInventorySteps,
  RoomScrapped,
  SyncStatus,
} from 'app/typings/autoInventory';
import { parseHtml } from 'app/utils/string';

import { getHDIWDetails } from '../../../Utils';

import './RoomMapping.scss';

const { Option } = Select;

type RoomMappingProps = {
  hotelId: number;
  scrappingRooms: RoomScrapped[];
  setScrappingRooms: (val: RoomScrapped[]) => void;
  setSyncStatus: (syncStatus: SyncStatus) => void;
  setInitConfiguration: (init: boolean) => void;
};

export const RoomMapping = ({
  hotelId,
  scrappingRooms,
  setScrappingRooms,
  setSyncStatus,
  setInitConfiguration,
}: RoomMappingProps) => {
  const form = Form.useFormInstance();
  const { data: rooms } = useRooms(hotelId, true);

  const tempRooms: RoomScrapped[] = rooms
    ? rooms.reduce((acc, room) => {
        if (room.bookingcomId && room.bookingcomName) {
          acc.push({
            roomId: room.bookingcomId,
            roomName: room.bookingcomName,
          });
        }

        return acc;
      }, [] as { roomId: number; roomName: string }[])
    : [];

  const listRoomScrapping =
    scrappingRooms.length > 0 ? scrappingRooms : tempRooms;

  const { getRoomsFromScrapper } = useRoomsScrapping();

  const fetchRooms = useCallback(async () => {
    try {
      const fetchRooms = await getRoomsFromScrapper(hotelId);

      if (fetchRooms.rooms) {
        setScrappingRooms(
          fetchRooms.rooms.map((room) => ({
            ...room,
            roomName: parseHtml(room.roomName),
          })) ?? []
        );
      }
    } catch (error) {
      setSyncStatus(SyncStatus.Failed);
      setInitConfiguration(true);
    }
  }, [
    getRoomsFromScrapper,
    hotelId,
    setInitConfiguration,
    setScrappingRooms,
    setSyncStatus,
  ]);

  useEffect(() => {
    if (!scrappingRooms.length) fetchRooms();
  }, [fetchRooms, scrappingRooms.length]);

  const handleRoomChange = (value: number, roomIdx: number, room: Room) => {
    const currentRooms: {
      [key: number]: Room & {
        AutoInventoryId?: number;
        AutoInventoryName?: string;
      };
    } = form.getFieldValue('rooms') || {};

    const newRoom = {
      ...room,
      AutoInventoryId: value,
      AutoInventoryName:
        scrappingRooms.find((r) => r.roomId === value)?.roomName ?? '',
    };

    form.setFieldsValue({
      rooms: {
        ...currentRooms,
        [roomIdx]: newRoom,
      },
    });
  };

  const handleClear = (roomIdx: number) => {
    const currentRooms: {
      [key: number]: Room & {
        AutoInventoryId?: number;
        AutoInventoryName?: string;
      };
    } = form.getFieldValue('rooms') || {};

    const updatedRooms = { ...currentRooms };

    delete updatedRooms[roomIdx];

    form.setFieldValue('rooms', updatedRooms);
  };

  const isOptionDisabled = (roomId: number) => {
    const currentRooms: {
      [key: number]: Room & {
        AutoInventoryId?: number;
        AutoInventoryName?: string;
      };
    } = form.getFieldValue('rooms') || {};

    return Object.values(currentRooms).some(
      (room) => room.AutoInventoryId === roomId
    );
  };

  return (
    <div className="room-mapping-step">
      <div className="explanation">
        First, link your existing Staycation room categories to Booking.com room
        categories.
      </div>
      <div className="room-mapping-association">
        <div className="titles">
          <div>Staycation rooms</div>
          <div>Booking.com rooms</div>
        </div>
        <div className="room-rows">
          {rooms?.map((room, idx) => (
            <div className="room-row" key={`${room.categoryName}-${room.name}`}>
              <div>
                <div className="staycation-room">
                  <PictureComponent
                    className="room-image"
                    pictureId={room.pictures[0].pictureId}
                  />
                  <div className="text">
                    {room.name ? room.name : room.categoryName}
                  </div>
                </div>
              </div>
              <Divider className="divider" />
              <Form.Item noStyle>
                <Select
                  className="booking-rooms"
                  optionLabelProp="label"
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  placeholder="Select matching room"
                  allowClear
                  onSelect={(value) => handleRoomChange(value, idx, room)}
                  onClear={() => handleClear(idx)}
                  defaultValue={form.getFieldValue([
                    'rooms',
                    idx,
                    'AutoInventoryId',
                  ])}
                >
                  {listRoomScrapping.map((option) => (
                    <Option
                      key={option.roomId}
                      value={option.roomId}
                      label={option.roomName}
                      disabled={isOptionDisabled(option.roomId)}
                    >
                      {option.roomName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          ))}
        </div>
      </div>

      <HowDoesItWork
        items={getHDIWDetails(AutoInventorySteps['room-mapping'])}
        trackFrom="auto-inventory"
        stepFrom={AutoInventorySteps['room-mapping']}
      />
      {!scrappingRooms.length && (
        <div className="scrapping-mask">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};
