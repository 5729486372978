import {
  AvailableOn,
  WeekDays,
} from 'app/components/commons/Experience/Availability/utils';
import { IExperienceCategory } from 'app/components/commons/Experience/Types';
import { ChannelManager } from 'app/typings/channelManagers';
import { RateMode } from 'app/typings/rateModes';
import { ISalesResponse } from 'app/typings/sales';

export type Inventory = {
  channelManager?: ChannelManager;
  rateMode?: RateMode;
  rooms: Array<Room>;
  saleDate: ISalesResponse;
  pkg: Package;
};

export type Package = {
  countryId: number;
  experiences: Experience[];
  id: number;
  name: string;
  slug: string;
  stars: number;
  dayPackage: boolean;
  childrenAllowed: boolean;
};

export type Experience = {
  category: IExperienceCategory;
  availableDayLimits: AvailableOn[];
  availableDays: WeekDays[];
  availableDates: string[];
  closingPeriods: ClosingPeriod[];
  coverPicture: {
    alt: string;
    credit: string;
    name: string;
    pictureId: string;
  };
  description?: string;
  discover: boolean;
  id: number;
  included: boolean;
  name: string;
  openingHours?: {
    type: 'weekly' | 'daily';
    slots?: Array<{
      start: string;
      end: string;
    }>;
    isAllDay?: boolean;
    days?: {
      [day: string]: {
        slots?: Array<{
          start: string;
          end: string;
        }>;
        isAllDay?: boolean;
      };
    };
  };
  pictures: Array<{
    alt: string;
    name: string;
    pictureId: string;
    priority: number;
    section: string;
  }>;
  price?: number;
  priceDescription?:
    | ' per stay'
    | '/pers.'
    | '/ per pers.'
    | '/hour'
    | '/glass'
    | ' for two'
    | '/extra pers.'
    | '';
  priority: number;
};

export type ClosingPeriod = {
  start: string;
  end: string;
};

export type Room = {
  room: {
    hotelId: number;
    categoryId: number;
    name: string;
    hasPRM: boolean;
    area: number;
    beds: Array<{
      categoryId: number;
      included: boolean;
      price: number;
      childPrice: number;
      id: number;
    }>;
    featureIds: Array<number>;
    pictures: Array<{
      pictureId: string;
      name: string;
      size: number;
      dimensions: {
        width: number;
        height: number;
      };
      updatedAt: string;
      alt: string;
      id: number;
    }>;
    id: number;
    priority?: number;
  };
  roomName: string;
  openings: Array<Opening>;
};

export type HotelSimple = {
  id: number;
  name: string;
  countryId: number;
  groupId: number;
  activeDisclaimer: boolean;
  insights: {
    id: number;
    tier: number;
    presenceRatio: number;
    value: number;
  };
  location: {
    locality: string;
    subLocality: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  contacts: [string];
  bookingUrl: string;
  experiencesValue: number;
  snoozeNotifications: string;
  roomCount: number;
  updatedAt: string;
  hotelAdminAccess: boolean;
};

export type Opening = {
  bar?: number;
  booked: number;
  date: string;
  discountPrice?: number;
  hasWarning?: boolean;
  hotelId: number;
  id?: number;
  price: number;
  published?: boolean;
  remaining: number;
  roomId: number;
  stock: number;
  errorStatus?: Array<OpeningErrors>;
  name?: string;
  updatedAt?: string;
  isForced: boolean;
  closed: boolean;
  autoDiscount?: boolean;
};

export type ByErrorOpenings = {
  exceededRate: Array<Opening>;
  wrongStock: Array<Opening>;
  missingStock: Array<Opening>;
  missingBar: Array<Opening>;
  missingDiscountPrice: Array<Opening>;
  underMinimumPrice: Array<Opening>;
  blockedByStaycation: Array<Opening>;
  closed: Array<Opening>;
};

export enum DuplicationMode {
  END_OF_WEEK = 'END_OF_WEEK',
  END_OF_NEXT_WEEK = 'END_OF_NEXT_WEEK',
  END_OF_TABLE = 'END_OF_TABLE',
  CUSTOM = 'CUSTOM',
}

export type DuplicationHover = {
  roomId: number;
  range: Interval;
};

export type DuplicationModeData =
  | {
      mode:
        | DuplicationMode.END_OF_WEEK
        | DuplicationMode.END_OF_NEXT_WEEK
        | DuplicationMode.END_OF_TABLE;
    }
  | {
      mode: DuplicationMode.CUSTOM;
      customDay: Date;
    };

export enum OpeningStatus {
  VALID = 'VALID',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  UNAVAILABLE = 'UNAVAILABLE',
  CLOSED = 'CLOSED',
  CLOSED_ON_STAYCATION = 'CLOSED_ON_STAYCATION',
  UNKNOWN = 'UNKNOWN',
}

export enum OpeningErrors {
  MISSING_STOCK = 'MISSING_STOCK',
  WRONG_STOCK = 'WRONG_STOCK',
  EXCEED_RATE = 'EXCEED_RATE',
  MISSING_BAR = 'MISSING_BAR',
  MISSING_DISCOUNT_PRICE = 'MISSING_DISCOUNT_PRICE',
  UNDER_MINIMUM_PRICE = 'UNDER_MINIMUM_PRICE',
  NO_DISCOUNT = 'NO_DISCOUNT',
  CLOSED = 'CLOSED',
}

export const OpeningsErrorMessage = {
  [OpeningErrors.MISSING_STOCK]: 'Stock missing',
  [OpeningErrors.WRONG_STOCK]: 'Remaining stock above maximum room capacity',
  [OpeningErrors.EXCEED_RATE]: 'Staycation rate above maximum accepted',
  [OpeningErrors.MISSING_BAR]: 'BAR RO missing',
  [OpeningErrors.MISSING_DISCOUNT_PRICE]: 'Staycation rate missing',
  [OpeningErrors.UNDER_MINIMUM_PRICE]: 'Staycation rate under minimum accepted',
  [OpeningErrors.NO_DISCOUNT]: 'No discount',
  [OpeningErrors.CLOSED]: 'Closed on your channel manager',
};
