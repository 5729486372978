import { Spin } from 'antd';

import './DataLoadingSpinner.scss';

const DataLoadingSpinner = () => {
  return (
    <div className="data-loading-spinner">
      <Spin />
    </div>
  );
};

export default DataLoadingSpinner;
