import { Layout, PageHeader, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Column from 'antd/lib/table/Column';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ActionCellMenu from 'app/components/lists/ActionCellMenu';
import Pagination from 'app/components/lists/Pagination';
import Header from 'app/components/lists/TableHeader';
import {
  PriceCell,
  StatusGiftCardBadge,
  dateTimeCell2,
} from 'app/components/lists/cells';
import { PAGE_LIMIT } from 'app/components/lists/constants';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import { useGetGiftCards } from 'app/hooks/data/giftCards/useGetGiftCards';
import { GiftCard, GiftCardStatus, GiftCardType } from 'app/typings/giftCard';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import { SortOrder, formatSortingForAPI } from 'app/utils/sort';
import { capitalizeFirstLetter } from 'app/utils/string';
import { toSnakeCase } from 'app/utils/strings';

import 'styles/pages/GiftCards/gift-cards-list.scss';

import { isValidStatus, isValidType } from './Utils';

type GiftCardsListQueryParam = {
  search: string;
  pageNumber: number;
  sortOrder: SortOrder;
  sortingField: string;
  types: GiftCardType[];
  statuses: GiftCardStatus[];
};

const sortingIntitialState: { sortingField: string; sortOrder: SortOrder } = {
  sortingField: 'createdAt',
  sortOrder: 'descend',
};

const typeFiltersInitialState = [
  GiftCardType.Email,
  GiftCardType.Print,
  GiftCardType.Physical,
  GiftCardType.Voucher,
  GiftCardType.Incentive,
  GiftCardType.Internal,
];

const statusFiltersInitialState = [
  GiftCardStatus.Active,
  GiftCardStatus.Expired,
  GiftCardStatus.Empty,
];

const queryParamKeys = {
  search: 's',
  sortOrder: 'so',
  sortingField: 'sf',
  types: 't',
  statuses: 'st',
  pageNumber: 'p',
};

const queryParamFormatter = {};

function getType(maybeType: string): GiftCardType | undefined {
  return isValidType(maybeType) ? maybeType : undefined;
}

function getStatus(maybeStatus: string): GiftCardStatus | undefined {
  return isValidStatus(maybeStatus) ? maybeStatus : undefined;
}

const queryParamInit = {
  pageNumber: 1,
  sortOrder: sortingIntitialState.sortOrder,
  sortingField: sortingIntitialState.sortingField,
  types: typeFiltersInitialState,
  statuses: statusFiltersInitialState,
  search: '',
} as GiftCardsListQueryParam;

const queryParamExtractor = {
  pageNumber: parseInt,
  types: parseArrayParam(getType),
  statuses: parseArrayParam(getStatus),
};

const TYPE_CHECKBOX_ITEMS = [
  { value: GiftCardType.Email, label: 'Email' },
  { value: GiftCardType.Print, label: 'Print' },
  { value: GiftCardType.Physical, label: 'Physical' },
  { value: GiftCardType.Voucher, label: 'Voucher' },
  { value: GiftCardType.Incentive, label: 'Incentive' },
  { value: GiftCardType.Internal, label: 'Internal' },
] satisfies { value: GiftCardType; label: string }[];

const STATUS_CHECKBOX_ITEMS = [
  { value: GiftCardStatus.Active, label: 'Active' },
  { value: GiftCardStatus.Expired, label: 'Expired' },
  { value: GiftCardStatus.Empty, label: 'Empty' },
  { value: GiftCardStatus.Rejected, label: 'Rejected' },
  { value: GiftCardStatus.Pending, label: 'Pending' },
  { value: GiftCardStatus.Cancelled, label: 'Cancelled' },
] satisfies { value: GiftCardStatus; label: string }[];

const GiftCardsList = () => {
  const history = useHistory();

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<GiftCardsListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const pageNumber = queryParam.pageNumber || 1;

  const formatSorting = useCallback(() => {
    if (!queryParam.sortOrder) return undefined;

    return `${toSnakeCase(queryParam.sortingField)}:${formatSortingForAPI(
      queryParam.sortOrder
    )}`;
  }, [queryParam.sortOrder, queryParam.sortingField]);

  const makePayload = useCallback(
    () => ({
      offset: (pageNumber - 1) * PAGE_LIMIT,
      search: queryParam.search || undefined,
      sorting: formatSorting(),
      status: queryParam.statuses || [],
      mytype: queryParam.types || [],
    }),
    [
      pageNumber,
      queryParam.search,
      queryParam.statuses,
      queryParam.types,
      formatSorting,
    ]
  );

  const debouncedPayload = useDebounce(makePayload());

  const { data: giftCards, isLoading } = useGetGiftCards(debouncedPayload);

  const handleSorting = (sortingField: string, sortOrder: SortOrder) => {
    updateQueryParam({
      sortOrder,
      sortingField,
      pageNumber: 1,
    });
    scrollToTop();
  };

  const handleOffset = (x: number) => {
    updateQueryParam({
      pageNumber: pageNumber + x,
    });
    scrollToTop();
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search,
    });
    scrollToTop();
  };

  const handleTypeFilter = (types: GiftCardType[]) => {
    updateQueryParam({
      types,
      pageNumber: 1,
    });
    scrollToTop();
  };

  const handleStatusFilter = (statuses: GiftCardStatus[]) => {
    updateQueryParam({
      statuses,
      pageNumber: 1,
    });
    scrollToTop();
  };

  const handleActionClick = ({ code }: GiftCard) => {
    history.push(`/gift-cards/${code}`);
  };

  const handleRowClick = ({ code }: GiftCard) => {
    return {
      onClick: () => history.push(`/gift-cards/${code}`),
    };
  };

  return (
    <Layout className="gift-cards-list">
      <PageHeader className="header" title="Gift Cards" />
      <Content className="body">
        <Table
          dataSource={giftCards ?? []}
          title={() => (
            <Header
              handleSearch={handleSearch}
              searchValue={queryParam.search || ''}
              pageNumber={pageNumber}
              pageResultsLength={giftCards?.length ?? 0}
              handleOffset={handleOffset}
            />
          )}
          loading={isLoading}
          rowKey="code"
          onRow={handleRowClick}
          pagination={false}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            handleSorting(field, order);
          }}
        >
          <Column title="Code" dataIndex="code" />
          <Column
            title="Created at"
            sorter
            dataIndex="createdAt"
            render={dateTimeCell2}
            defaultSortOrder={'descend'}
          />
          <Column title="Sender name" dataIndex="billingName" />
          <Column title="Expiration date" sorter dataIndex="expirationDate" />
          <Column
            title="Remaining value"
            dataIndex="remainingValue"
            render={(price, entity) => (
              <PriceCell price={price} entity={entity} />
            )}
          />
          <Column
            key="giftCardType"
            className="sort-column"
            title={
              <ModalCheckBox
                title="Type"
                value={queryParam.types || []}
                onChange={handleTypeFilter}
                items={TYPE_CHECKBOX_ITEMS}
              />
            }
            dataIndex="giftCardType"
            render={capitalizeFirstLetter}
          />
          <Column
            className="sort-column"
            title={
              <ModalCheckBox
                title="Status"
                value={queryParam.statuses || []}
                onChange={handleStatusFilter}
                items={STATUS_CHECKBOX_ITEMS}
              />
            }
            dataIndex="status"
            render={StatusGiftCardBadge}
          />
          <Column
            className="action-column"
            key="actions"
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            render={(entity) => (
              <ActionCellMenu
                entity={entity}
                handleActionClick={handleActionClick}
              />
            )}
          />
        </Table>
        <Pagination
          className="footer"
          pageNumber={pageNumber}
          pageResultsLength={giftCards?.length ?? 0}
          handleOffset={handleOffset}
        />
      </Content>
    </Layout>
  );
};

export default GiftCardsList;
