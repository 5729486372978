import { PAX } from '../PAX';
import { PSP } from '../PSP';
import { VccStatus } from '../vcc';

type Room = {
  categoryId: number;
  categoryRoomCount: number;
  createdAt: string;
  hotelId: number;
  id: number;
  name: string;
  prmAccess: boolean;
  published: boolean;
  updatedAt: string;
  discountPrice: number;
  price: number;
  bedPricePerNight?: number;
  additionalAdultPricePerNight?: number;
  additionalChildPricePerNight?: number;
  singleCustomerDiscountPerNight?: number;
};

type BookingExperienceCategory = {
  id: number;
  name: String;
  experienceType?: string;
  brand: boolean;
  duration: boolean;
  time: boolean;
  quantityTitle: boolean;
  locations: boolean;
  quantity: boolean;
};

type Experience = {
  id: number;
  name?: string;
  category: BookingExperienceCategory;
  included: boolean;
  quantity: number;
  price: number;
  publicPrice?: number;
  priority: number;
  hotelId?: number;
  brand?: string;
  quantityDescription?: string;
  duration?: number;
  durationDescription?: string;
  time?: string;
  roomServiceAvailable?: boolean;
  roomServiceIncluded?: boolean;
  roomServicePrice?: number;
  accessMode?: string;
  locations?: string;
  maxQuantity?: number;
};

export enum BookingStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Expired = 'expired',
  Aborted = 'aborted',
  Rejected = 'rejected',
}
export type Customer = {
  gender?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  guestFirstname?: string;
  guestLastname?: string;
  countrySlug?: string;
  id: number;
};

export enum BookingRefundType {
  HOTEL = 'Hotel',
  STAYCATION = 'Staycation',
}

export enum BookingRefundStatus {
  ACCEPTED = 'ACCEPTED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PENDING_HOTEL = 'PENDING_HOTEL',
}

export type IssuingRefund = {
  amount: number;
  bookingId: number;
  bookingRefundId: number;
  createdAt: string;
  id: number;
  transactionId: string;
};

export type Refund = {
  id: number;
  bookingId: number;
  userId: number;
  createdAt: string;
  pspRefundId?: string;
  pspTransferId?: string;
  amountCB?: number;
  amountGiftCard?: number;
  reasonId: number;
  comment?: string;
  source: BookingRefundType;
  cancelBooking: boolean;
  refundedFromHotel?: number;
  status: BookingRefundStatus;
  billId?: number;
  amountCredit: number;
  issuingRefunds?: Array<IssuingRefund>;
};

export type BookingDetails = {
  id: number;
  updatedAt: string;
  code: string;
  status: BookingStatus;
  checkin: string;
  checkout: string;
  date: string;
  comment: string;
  pax: PAX;
  customer: Customer;
  taxAmount: number;
  commissionAmount: number;
  totalAmount: number;
  payedAt: string;
  chargedAmount: number;
  fees: number;
  creditAmount: number;
  psp: PSP;
  currency: string;
  hotelAmount: number;
  experiences: Array<Experience>;
  room: Room;
  refunds: Array<Refund>;
  issuingCardId?: string;
  issuingTransactionId?: string;
  issuingChargedAt?: string;
  dayPackage: boolean;
  vccStatus: VccStatus;
};

export type BookingListItem = {
  code: string;
  status: BookingStatus;
  checkin?: string;
  checkout?: string;
  createdAt: string;
  customer: Customer;
  pax: PAX;
  roomName?: string;
  roomCategory: string;
  extrasCount: number;
  totalAmount: number;
  dayPackage: Boolean;
  issuingCardId?: string;
  issuingTransactionId?: string;
  issuingChargedAt?: string;
  vccStatus: VccStatus;
  hotelAmount: number;
  psp: PSP;
};
