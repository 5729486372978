import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useMemo } from 'react';

import { UserDetail, UserList, UsersListParams } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/users' as const;

export const userDetailPath = 'admin/users/:id' as const;

export function useGetUsers(usersListParams?: UsersListParams) {
  const stringifiedParams = queryString.stringify(usersListParams ?? {});

  const key = useMemo(() => {
    return [
      path,
      {
        stringifiedParams,
      },
    ];
  }, [stringifiedParams]);

  return useQuery({
    queryKey: key,
    queryFn: () =>
      apiRequest({ path: `${path}?${stringifiedParams}` }) as Promise<
        UserList[]
      >,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!usersListParams,
  });
}

export function useGetUser(id: number) {
  return useQuery({
    queryKey: [userDetailPath, { id }],
    queryFn: () =>
      apiRequest({
        path: userDetailPath,
        params: { id: id.toString() },
      }) as Promise<UserDetail>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
