import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Input, List, Switch } from 'antd';
import { FC, useCallback, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';

import EmojiModal from 'app/components/commons/Package/Headlines/EmojiModal/EmojiModal';
import Pic from 'app/components/commons/Pic';
import type { Picture } from 'app/components/commons/Uploader/Types';
import SortableHandle from 'app/components/layout/Sortable/SortableHandle';

import './Headline.scss';

const RULES = [{ required: true, message: 'Required' }];

export const Headline: FC<IProps> = ({
  field,
  mode,
  remove,
  fieldIndex,
  values,
  setFieldsValue,
}) => {
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const renderAvatar = () => (
    <Form.Item
      name={[field.name, 'emoji']}
      rules={RULES}
      valuePropName="key"
      noStyle
    >
      <div
        onClick={
          mode === 'edit' && !values?.headlines?.[fieldIndex].experienceId
            ? () => setIsEmojiOpen(true)
            : undefined
        }
        className={`headline__avatar ${
          mode === 'edit' && !values?.headlines?.[fieldIndex].experienceId
            ? 'headline__avatar-clickable'
            : ''
        }`}
        key={values?.headlines?.[fieldIndex].emoji?.pictureId}
      >
        {values?.headlines?.[fieldIndex].emoji ? (
          <Pic {...values.headlines[fieldIndex].emoji} width={40} />
        ) : (
          <EditOutlined />
        )}
      </div>
    </Form.Item>
  );

  const renderTitle = () =>
    values?.headlines?.[fieldIndex].experienceId
      ? values.headlines[fieldIndex].title
      : 'Custom headline';

  const renderDescription = () =>
    mode === 'view' ? (
      <div className="headline__description-view-container">
        <div className="headline__description-view headline__description-view-no-input">
          {values?.headlines?.[fieldIndex].valueProposition}
        </div>
      </div>
    ) : (
      <div className="headline__description-view-container">
        <Form.Item
          name={[field.name, 'valueProposition']}
          rules={values?.headlines?.[fieldIndex].featured ? RULES : undefined}
          noStyle
        >
          <Input className="headline__description-view" />
        </Form.Item>
        {!values?.headlines?.[fieldIndex].experienceId && (
          <DeleteOutlined onClick={() => remove(fieldIndex)} />
        )}
      </div>
    );

  const renderSwitch = () => (
    <Form.Item name={[field.name, 'featured']} valuePropName="checked" noStyle>
      <Switch size="small" />
    </Form.Item>
  );

  const renderDragHandler = () => <SortableHandle key="handle" />;

  const onEmojiSelect = useCallback(
    (emoji: any) => {
      if (!values?.headlines) {
        return;
      }

      setFieldsValue({
        ...values,
        headlines: values.headlines.map((h, idx) =>
          idx === fieldIndex ? { ...h, emoji } : h
        ),
      });
      setIsEmojiOpen(false);
    },
    [values, setFieldsValue, fieldIndex]
  );

  return (
    <List.Item
      className="headline"
      actions={mode === 'edit' ? [renderSwitch(), renderDragHandler()] : []}
    >
      <List.Item.Meta
        avatar={renderAvatar()}
        title={renderTitle()}
        description={renderDescription()}
      />
      <EmojiModal
        visible={isEmojiOpen}
        onSelect={onEmojiSelect}
        onCancel={() => setIsEmojiOpen(false)}
      />
    </List.Item>
  );
};

const SortableItem = SortableElement(Headline);

const SortableHeadline: FC<ISortableProps> = ({ disabled, ...props }) => (
  <SortableItem disabled={disabled} {...props} />
);

type HeadlineForm = {
  headlines?: Array<{
    emoji: Picture;
    valueProposition: string;
    experienceId: number;
    featured: boolean;
    title?: string;
  }>;
};

interface IProps {
  field: any;
  mode: string;
  remove: (index: number) => void;
  fieldIndex: number;
  values?: HeadlineForm;
  setFieldsValue: (values: any) => void;
}

interface ISortableProps extends IProps {
  index: number;
  disabled?: boolean;
}

export default SortableHeadline;
