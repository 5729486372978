import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React, { FC } from 'react';

import { SCard } from 'app/components/StaycationUI';

import './ExperienceTitle.scss';

const RULES = [{ required: true, message: 'Required' }];

const ExperienceTitle: FC<Props> = ({ mode, value }) => (
  <SCard
    title="Experience title"
    subtitle="Appear on daytime cards and package page"
    className="experience-title"
  >
    <div className="container">
      <div className="input-row">
        <Form.Item label="Title" name="experienceTitle" rules={RULES}>
          {mode === 'edit' || !value ? (
            <Input disabled={!value && mode !== 'edit'} />
          ) : (
            <div className="view-text">{value}</div>
          )}
        </Form.Item>
        <div className="banner">
          <div className="iconContainer">
            <InfoCircleOutlined className="icon" />
          </div>
          <div className="textContainer">
            <span className="title">Title guidelines</span>
            <p className="banner-content">
              <br />
              <ul className="banner-list">
                <li>
                  List the <em className="emphasized">main experiences</em> and
                  capitalize them - e.g.,{' '}
                  <em className="emphasized">Massage 1h, Spa et Cocktail</em>
                </li>
                <li>
                  Be{' '}
                  <em className="emphasized">
                    descriptive and{' '}
                    <strong className="strongly-emphasized">factual</strong>
                  </em>
                </li>
                <br />
                <li>
                  Include <em className="emphasized">duration</em> for massages
                  and spa access (if the package includes both, only mention the
                  massage duration)
                </li>
                <br />
                <li>
                  Add <em className="emphasized">factual details</em> only if
                  relevant:
                  <ul className="banner-list">
                    <li>
                      ✅ <em className="emphasized">Do</em>: Dîner bistronomique
                      / Cocktail ambiance Art Déco / Petit déjeuner buffet / Tea
                      time signé Nina Métayer
                    </li>
                    <li>
                      ❌ <em className="emphasized">Don’t</em>: Brunch iconique
                      / Petit déjeuner à la Défense / Dîner moderne et créatif
                    </li>
                  </ul>
                </li>
              </ul>
              <br />
              ⚠️ <em className="emphasized">Review titles by category</em> (spa,
              massage, dinner, etc.) to ensure they are{' '}
              <em className="emphasized">not too repetitive</em> and remain
              descriptive enough to{' '}
              <em className="emphasized">help users compare packages</em>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </SCard>
);

type Props = {
  mode: 'view' | 'edit';
  value?: string;
};

export default ExperienceTitle;
