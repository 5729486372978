import { useQuery } from '@tanstack/react-query';

import { CancellationReason } from 'app/typings/cancellationReason';
import { apiRequest } from 'app/utils/request/api';

const cancellationReasonsPath = 'admin/cancellation-reasons' as const;

export function useCancellationReasons() {
  return useQuery({
    queryKey: [cancellationReasonsPath],
    queryFn: () =>
      apiRequest({
        path: cancellationReasonsPath,
      }) as Promise<CancellationReason[]>,
    staleTime: Infinity,
  });
}
