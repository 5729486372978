import { addDays, eachDayOfInterval, format } from 'date-fns';

import { Room } from 'app/typings';
import {
  AutoInventorySteps,
  InventoryScrapped,
  ModifierType,
  RoomsWithInventory,
} from 'app/typings/autoInventory';

import { Inventory } from '../../Types';
import { computePackageValue } from '../../Utils';

const SMART_INVENTORY_OVERBOOKING_LIMIT = 1;

export type FormValues = {
  rooms: {
    [index: number]: Room & {
      AutoInventoryId?: number;
      AutoInventoryName?: string;
    };
  };
  matchOTAPrice?: boolean;
  customBar: {
    value?: number;
    suffix: ModifierType.FLAT | ModifierType.RATE;
  };
  discount?: number;
  lastMinuteDays?: number;
  lastMinuteDiscountValue?: number;
  lastMinuteIsActivated: boolean;
  discountIsActivated: boolean;
  overrideOpenings: boolean;
};

export const checkIfRoomMappingIsValid = (values: FormValues) => {
  if (!values || typeof values.rooms !== 'object') {
    return false;
  }

  const roomValues = Object.values(values.rooms);

  const allUndefined = roomValues.every(
    (item) => item.AutoInventoryId === undefined
  );

  return roomValues.length > 0 && !allUndefined;
};

export const checkIfBarIsValid = (values: FormValues) => {
  if (!values) return false;

  const { matchOTAPrice, customBar } = values;

  return matchOTAPrice || !!(customBar.value && customBar.value > 0);
};

export const checkIfDiscountIsValid = (
  values: FormValues,
  discountStepHasError: boolean
) => {
  if (!values || discountStepHasError) return false;

  const {
    discount,
    discountIsActivated,
    lastMinuteIsActivated,
    lastMinuteDays,
    lastMinuteDiscountValue,
  } = values;

  const isDiscountActive = discount && discountIsActivated;
  const isLastMinuteActive =
    lastMinuteIsActivated && lastMinuteDays && lastMinuteDiscountValue;

  return (
    (isDiscountActive && !lastMinuteIsActivated) ||
    (isLastMinuteActive && !discountIsActivated) ||
    (isDiscountActive && isLastMinuteActive)
  );
};

export const organizePreviewDataByDate = (
  data: InventoryScrapped[],
  inventory: Inventory,
  formData: FormValues
) => {
  const result = {
    dates: [] as string[],
    stocks: [] as number[],
    bars: [] as number[],
    staycationRates: [] as number[],
    discounts: [] as number[],
    packageValue: [] as number[],
  };

  data.forEach((item) => {
    result.dates.push(item.date);

    const stock =
      item.stock - SMART_INVENTORY_OVERBOOKING_LIMIT < 0
        ? 0
        : item.stock - SMART_INVENTORY_OVERBOOKING_LIMIT;

    result.stocks.push(stock);

    const packageValue = computePackageValue(
      undefined,
      inventory,
      new Date(item.date) ?? new Date()
    );

    const prices =
      item.price > 0
        ? pricesCalculation(item.price, formData, item.date, packageValue)
        : { price: 0, staycationRate: 0, discount: 0 };

    result.bars.push(prices.price);
    result.staycationRates.push(prices.staycationRate);
    result.discounts.push(Math.round(prices.discount ?? 0));

    result.packageValue.push(packageValue + prices.price);
  });

  return result;
};
export const createEmptyRoomInventory = () => {
  const now = Date.now();

  const dates = eachDayOfInterval({
    start: now,
    end: addDays(now, 6),
  });

  return dates.map((date) => ({
    date: format(date, 'yyyy-MM-dd'),
    stock: 0,
    price: 0,
  }));
};

export const associateRooms = (
  formData: FormValues,
  roomsScrapped: RoomsWithInventory[]
) => {
  return Object.entries(formData.rooms).map(([idx, room]) => {
    const roomScrapped = roomsScrapped.find(
      (data) => data.roomId === room.AutoInventoryId
    );

    return {
      ...room,
      inventory: roomScrapped?.inventory ?? createEmptyRoomInventory(),
    };
  });
};

const pricesCalculation = (
  price: number,
  formData: FormValues,
  date: string,
  packageValue: number
) => {
  const getCustomBarPrice = () => {
    if (
      formData.customBar.value &&
      formData.customBar.suffix === ModifierType.RATE
    ) {
      return price / (1 + formData.customBar.value / 100);
    }

    return price - (formData.customBar.value ?? 0);
  };

  const barPrice = Math.floor(
    !formData.matchOTAPrice ? getCustomBarPrice() : price
  );
  const crossedPrice = barPrice + packageValue;

  if (!formData.lastMinuteIsActivated && formData.discount) {
    return {
      price: barPrice,
      staycationRate: Math.floor(
        crossedPrice - (crossedPrice * formData.discount) / 100
      ),
      discount: price > 0 ? formData.discount : 0,
    };
  }

  if (
    (formData.lastMinuteDiscountValue && formData.lastMinuteDays) ||
    formData.discount
  ) {
    const nextXDays = eachDayOfInterval({
      start: new Date(),
      end: addDays(new Date(), formData.lastMinuteDays ?? 0),
    }).map((date) => format(date, 'yyyy-MM-dd'));

    if (nextXDays.includes(date)) {
      return {
        price: barPrice,
        staycationRate: Math.floor(
          crossedPrice -
            (crossedPrice * (formData.lastMinuteDiscountValue ?? 0)) / 100
        ),
        discount: price > 0 ? formData.lastMinuteDiscountValue : 0,
      };
    }

    return {
      price: barPrice,
      staycationRate: Math.floor(
        crossedPrice - (crossedPrice * (formData.discount ?? 0)) / 100
      ),
      discount: price > 0 ? formData.discount : 0,
    };
  }

  return {
    price: barPrice,
    staycationRate: 0,
    discount: 0,
  };
};

export const getHDIWDetails = (step: AutoInventorySteps) => {
  const sharedEditable = {
    title: 'Editable',
    description:
      'You will still be able to edit your stocks and rates manually on specific dates.',
    emoji: '🤚',
  };

  const sharedAutomatic = {
    title: 'Automatic',
    description:
      'Your connected stocks and prices on mapped room categories will be automatically published for the next 6 weeks.',
    emoji: '🤖',
  };

  const sharedDynamic = {
    title: 'Dynamic',
    description: 'Stock and BAR data are updated 3 times a day.',
    emoji: '📈',
  };

  const sharedFlexible = {
    title: 'Flexible',
    description:
      'You can deactivate the Auto Inventory at any time and revert to your initial rates and discount.',
    emoji: '🔄',
  };

  const sharedPublicPrices = {
    title: 'Public prices',
    description: 'We read your public rate, not the Genius price.',
    emoji: '🙋‍♂️',
  };

  switch (step) {
    case AutoInventorySteps['room-mapping']:
      return [
        sharedEditable,
        {
          title: 'Safe',
          description:
            "We won't sell your last available stock. If there is 1 stock left on Booking, Staycation closes.",
          emoji: '⛑️',
        },
        sharedAutomatic,
        sharedDynamic,
        sharedFlexible,
      ];

    case AutoInventorySteps.bar:
    case AutoInventorySteps.discount:
      return [
        sharedPublicPrices,
        sharedEditable,
        sharedAutomatic,
        sharedDynamic,
        sharedFlexible,
      ];

    case AutoInventorySteps.preview:
      return [
        {
          title: 'Read only',
          description:
            'We only read your OTA stocks and prices. You will still need to manually enter reservations in your PMS.',
          emoji: '🤓',
        },
        sharedEditable,
        sharedAutomatic,
        sharedDynamic,
        sharedFlexible,
      ];

    default:
      return [];
  }
};
