import { InfoCircleOutlined } from '@ant-design/icons';
import { getDistance } from 'geolib';
import _ from 'lodash';

import { LocationSelect } from 'app/components/commons/LocationSelect/LocationSelect';
import { useClubs } from 'app/hooks/data/useClubs';
import { useCountries } from 'app/hooks/data/useCountries';
import {
  formatGooglePlacePayload,
  getPlaceDetails,
  nearbySearch,
} from 'app/utils/googlePlace/googlePlace';
import { PlaceResult } from 'app/utils/googlePlace/typings';

import FormGroup from './FormGroup/FormGroup';
import './GetLucky.scss';

type Props = {
  onSelect: (data: any) => void;
};

export const GetLucky = ({ onSelect }: Props) => {
  const { data: countries } = useCountries();
  const { data: clubs } = useClubs();

  const nearbySearchCallback = (
    place: PlaceResult,
    subways?: Array<PlaceResult>
  ) => {
    const addr = place.address_components || [];
    const countryComponent = _.find(addr, (addressEntry) =>
      _.includes(addressEntry.types, 'country')
    );
    const country =
      countries?.find(
        (c) =>
          c.slug.toLowerCase() === countryComponent?.short_name.toLowerCase()
      ) || countries?.[0];
    const countryId = country?.id;
    const coordinates = place.geometry?.location;

    if (coordinates) {
      const coords = {
        lng: coordinates.lng(),
        lat: coordinates.lat(),
      };

      const closestClubs = clubs
        ?.filter((c) => country?.authorizedCountries.includes(c.countryId))
        .map((club) => ({
          ...club,
          distance: getDistance(club.city?.coords, coords),
        }))
        .sort((a, b) => a.distance - b.distance);

      const { streetNumber, street, city, department, region, zipCode } =
        formatGooglePlacePayload(addr);

      const maybeStreetNumber = streetNumber ? `${streetNumber}, ` : '';

      onSelect({
        countryId,
        name: place.name,
        phone: place.formatted_phone_number?.replace(/\s/g, ''),
        address: {
          city,
          department,
          region,
          zipCode,
          countryId,
          street: `${maybeStreetNumber}${street}`,
        },
        groupId:
          closestClubs?.filter((c) => c.countryId === countryId)[0]?.groupId ||
          closestClubs?.[0]?.groupId,
        location: { coords },
        subway: subways?.[0]?.name,
      });
    }
  };

  const getDetailsCallback = (place: PlaceResult) => {
    if (!place?.geometry?.location) {
      return;
    }

    nearbySearch(
      {
        location: place.geometry.location,
        radius: 1000,
        type: 'subway_station',
      },
      (subways) => nearbySearchCallback(place, subways)
    );
  };

  const handleChange = (placeId: string) => {
    getPlaceDetails(
      {
        placeId,
        fields: [
          'name',
          'address_components',
          'geometry',
          'formatted_phone_number',
        ],
      },
      getDetailsCallback
    );
  };

  return (
    <div className="get-lucky">
      <FormGroup label="Get lucky 🍀️">
        <LocationSelect
          formItemProps={{
            className: 'selector',
            style: { marginBottom: 4 },
          }}
          renderLocationLabel={(location) => (
            <div className="get-lucky-select">
              <span className="text">{location.text}</span>
              <span className="description">
                {location.subtext && ` (${location.subtext})`}
              </span>
            </div>
          )}
          onSelectPlaceId={handleChange}
          placePredictionTypes={['establishment']}
          minCharacters={4}
        />
        <div className="sub-text">
          <InfoCircleOutlined className="info-circle" />
          Type hotel name to autofill the form!
        </div>
      </FormGroup>
    </div>
  );
};
