import { FC } from 'react';

import './Line.scss';

type Props = {
  title: string;
  value: any;
  className?: string;
};

export const Line: FC<Props> = ({ title, value, className = '' }) => (
  <div className="line">
    <span className="left-title">{title}</span>
    <span className={`right-value ${className}`}>{value}</span>
  </div>
);

export default Line;
