import { GiftCard, PrintGiftCardProperties } from 'app/typings/giftCard';
import { capitalizeFirstLetter } from 'app/utils/string';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & PrintGiftCardProperties;
};

const PrintProperties = ({ giftCard }: Props) => {
  const { giftCardType, senderName, beneficiaryName, message } = giftCard;

  return (
    <>
      <Line title="Type" value={capitalizeFirstLetter(giftCardType)} />
      <Line title="From" value={senderName} />
      <Line title="To" value={beneficiaryName} />
      <Line title="Message" value={message} />
    </>
  );
};

export default PrintProperties;
