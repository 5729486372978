import { Drawer } from 'antd';

import { useGetBookingDetailByCode } from 'app/hooks/data/extranet/bookings/useBooking';
import { dateAndHour } from 'app/utils/dates';

import './BookingDetail.scss';
import BookingDetailHeader from './_components/BookingDetailHeader/BookingDetailHeader';
import BookingDetailSection from './_components/BookingDetailSection/BookingDetailSection';
import BookingInfo from './_components/BookingInfo/BookingInfo';
import Help from './_components/Help/Help';
import PaymentDetails from './_components/PaymentDetails/PaymentDetails';
import VirtualCard from './_components/VirtualCard/VirtualCard';

type BookingDetailProps = {
  bookingCode?: string;
  hotelId: number;
  scrollVCC: boolean;
  onClose: () => void;
};

const BookingDetail = ({
  bookingCode,
  hotelId,
  scrollVCC,
  onClose,
}: BookingDetailProps) => {
  const { data: bookingData, isLoading: bookingIsLoading } =
    useGetBookingDetailByCode(hotelId, bookingCode);

  if (!bookingData || bookingIsLoading) {
    return <></>;
  }

  return (
    <Drawer
      title={
        <div className="booking-detail-title">
          <div className="customer-info">
            {[
              `${bookingData.booking.customer.gender}.`,
              bookingData.booking.customer.firstName,
              bookingData.booking.customer.lastName,
            ].join(' ')}
          </div>
          <div className="booking-info">
            Booking date : {dateAndHour(bookingData.booking.payedAt)}
          </div>
        </div>
      }
      className="booking-detail"
      open={!!bookingCode && !!hotelId}
      onClose={onClose}
      width={496}
      destroyOnClose
      bodyStyle={{ padding: 0 }}
    >
      <div className="booking-detail-container">
        <BookingDetailHeader
          booking={bookingData.booking}
          scrollVCC={scrollVCC}
        />
        <div className="booking-detail-content">
          <BookingDetailSection title="Booking" name="booking">
            <BookingInfo booking={bookingData.booking} />
          </BookingDetailSection>
          <BookingDetailSection title="Payment Details" name="payment-details">
            <PaymentDetails booking={bookingData.booking} hotelId={hotelId} />
          </BookingDetailSection>
          {bookingData.booking.issuingCardId && (
            <>
              <BookingDetailSection title="Virtual Card" name="virtual-card">
                <VirtualCard booking={bookingData.booking} hotelId={hotelId} />
              </BookingDetailSection>
              <BookingDetailSection title="Help" name="help">
                <Help />
              </BookingDetailSection>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default BookingDetail;
