import { Layout, Menu } from 'antd';
import { useContext, useState } from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Delete from 'app/components/commons/ExtraButtons/Delete/Delete';
import Copywriting from 'app/components/pages/Packages/PackageDetail/Copywriting/Copywriting';
import Cover from 'app/components/pages/Packages/PackageDetail/Cover/Cover';
import Disclaimer from 'app/components/pages/Packages/PackageDetail/Disclaimer/Disclaimer';
import Discover from 'app/components/pages/Packages/PackageDetail/Discover/Discover';
import General from 'app/components/pages/Packages/PackageDetail/General/General';
import Preview from 'app/components/pages/Packages/PackageDetail/Preview/Preview';
import Tags from 'app/components/pages/Packages/PackageDetail/Tags/Tags';
import { ConfigContext } from 'app/context/ConfigContext/ConfigContext';
import { useGetPackage } from 'app/hooks/data/packages/usePackages';
import { useDeletePackage } from 'app/hooks/data/packages/useSavePackage';

import './PackageDetail.scss';

const MENU_ITEMS = [
  { name: 'General' },
  { name: 'Copywriting' },
  { name: 'Discover' },
  { name: 'Cover' },
  { name: 'Disclaimer' },
  { name: 'Tags' },
];

type MenuProps = {
  menuItems: Array<{ name: string }>;
  selectedMenu: { name: string };
  setSelectedMenu: (menu: { name: string }) => void;
};

const MenuCustom = ({
  menuItems,
  selectedMenu,
  setSelectedMenu,
}: MenuProps) => (
  <Menu
    mode="horizontal"
    onClick={({ key }) => setSelectedMenu(menuItems[parseInt(key, 10)])}
    selectedKeys={[
      menuItems.findIndex((item) => item.name === selectedMenu.name).toString(),
    ]}
  >
    {menuItems.map((item, index) => (
      <Menu.Item key={index}>{item.name}</Menu.Item>
    ))}
  </Menu>
);

type Props = {
  match: {
    params: {
      id?: string;
    };
  };
};

export const PackageDetail = ({ match }: Props) => {
  const packageId = match.params?.id;
  const parsedPackageId = packageId ? parseInt(packageId, 10) : undefined;

  const { data: pkg } = useGetPackage(parsedPackageId);
  const { mutateAsync: deletePackage } = useDeletePackage();

  const config = useContext(ConfigContext);

  const [menu, setMenu] = useState(MENU_ITEMS[0]);

  const renderMenu = () => (
    <MenuCustom
      menuItems={MENU_ITEMS}
      selectedMenu={menu}
      setSelectedMenu={setMenu}
    />
  );

  const renderPreviewButton = () => {
    if (!parsedPackageId || !pkg) {
      return null;
    }

    return config ? (
      <Preview
        key="preview"
        onClick={() => window.open(`${config.appUrl}/fr/hotels/${pkg.slug}`)}
      />
    ) : (
      <Preview />
    );
  };

  const renderDeleteButton = () => {
    if (!parsedPackageId || !pkg) {
      return null;
    }

    return (
      <Delete
        key="delete"
        entityDescription={`Package #${parsedPackageId}`}
        onDelete={async () =>
          await deletePackage({ packageId: parsedPackageId })
        }
      />
    );
  };

  const dispatchMenuSection = () => {
    switch (menu.name) {
      case 'General':
        return (
          <CurrencyWrapper hotelId={pkg?.hotelId}>
            <General
              headerMenu={parsedPackageId ? renderMenu() : undefined}
              headerExtra={[renderPreviewButton(), renderDeleteButton()]}
              packageId={parsedPackageId ?? null}
            />
          </CurrencyWrapper>
        );
      case 'Copywriting':
        return (
          pkg && (
            <Copywriting
              headerMenu={renderMenu()}
              headerExtra={[renderPreviewButton()]}
              pkg={{ ...pkg, id: parsedPackageId ?? null }}
            />
          )
        );
      case 'Discover':
        return (
          pkg && (
            <Discover
              headerMenu={renderMenu()}
              headerExtra={[renderPreviewButton()]}
              pkg={{ ...pkg, id: parsedPackageId ?? null }}
            />
          )
        );
      case 'Cover':
        return (
          pkg && (
            <Cover
              headerMenu={renderMenu()}
              headerExtra={[renderPreviewButton()]}
              pkg={{ ...pkg, id: parsedPackageId ?? null }}
            />
          )
        );
      case 'Disclaimer':
        return (
          <Disclaimer
            headerMenu={renderMenu()}
            headerExtra={[renderPreviewButton()]}
            packageId={parsedPackageId ?? null}
          />
        );
      case 'Tags':
        return (
          <Tags
            headerMenu={renderMenu()}
            headerExtra={[renderPreviewButton()]}
            packageId={parsedPackageId ?? null}
          />
        );
      default:
        return null;
    }
  };

  return <Layout className="package-detail">{dispatchMenuSection()}</Layout>;
};

export default PackageDetail;
