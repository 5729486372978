import { Refund } from './adminBookings/bookingRefund';
import { BookingStatus } from './adminBookings/bookings';
import { User } from './user';

export enum GiftCardStatus {
  Active = 'active',
  Empty = 'empty',
  Expired = 'expired',
  Rejected = 'rejected',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

export enum GiftCardType {
  Email = 'email',
  Print = 'print',
  Physical = 'physical',
  Voucher = 'voucher',
  Incentive = 'incentive',
  Internal = 'internal',
}

export type VoucherGiftCardProperties = {
  giftCardType: GiftCardType.Voucher;
};

export type IncentiveGiftCardProperties = {
  giftCardType: GiftCardType.Incentive;
};

export type InternalGiftCardProperties = {
  giftCardType: GiftCardType.Internal;
};

export type EmailGiftCardProperties = {
  giftCardType: GiftCardType.Email;
  color: string;
  message: string;
  beneficiaryEmail: string;
  billingEmail: string;
  emailSendDate: string;
};

export type PrintGiftCardProperties = {
  giftCardType: GiftCardType.Print;
  color: string;
  message: string;
};

export type PhysicalGiftCardProperties = {
  giftCardType: GiftCardType.Physical;
  shippingFirstName: string;
  shippingLastName: string;
  shippingStreet1: string;
  shippingStreet2: string;
  shippingZipCode: string;
  shippingCity: string;
  shippingCountryId: number;
};

export type GiftCard = {
  id: number;
  code: string;
  createdAt: string;
  countryId: number;
  startDate?: string;
  expirationDate?: string;
  status: GiftCardStatus;
  initialValue: number;
  remainingValue?: number;
  maxUse?: number;
  minAmount?: number;
  onlyFirstBooking?: boolean;
  senderName: string;
  beneficiaryName: string;
  totalAmount: number;
  cardInfo?: {
    last4: string;
  };
  billingFirstName?: string;
  billingEmail?: string;
  currency: string;
} & (
  | EmailGiftCardProperties
  | PrintGiftCardProperties
  | PhysicalGiftCardProperties
  | VoucherGiftCardProperties
  | IncentiveGiftCardProperties
  | InternalGiftCardProperties
);

export type GiftCardListParams = {
  offset: number;
  search?: string;
  sorting?: string;
  status: GiftCardStatus[];
  mytype: GiftCardType[];
};

export type GiftCardBooking = {
  code: string;
  date: string;
  couponAmount: number;
  status: BookingStatus;
};

export type GiftCardDetails = {
  giftCard: GiftCard;
  giftCardBookings: GiftCardBooking[];
  staycations: number[];
  giftCards: number[];
  user: User;
  refunds: Refund[];
};

export type RefundForm = {
  amountCb?: number;
  reason?: number;
  comment?: string;
  deactivateGiftCard: boolean;
};

export type GiftCardEditForm = {
  startDate?: string;
  expirationDate: string;
  remainingValue: number;
  maxUse?: number;
  senderName: string;
  beneficiaryName: string;
  color?: string;
  beneficiaryEmail?: string;
  billingEmail?: string;
  emailSendDate?: string;
  message?: string;
  shippingFirstName?: string;
  shippingLastName?: string;
  shippingStreet1?: string;
  shippingStreet2?: string;
  shippingZipCode?: string;
  shippingCity?: string;
  shippingCountry?: string;
};
