import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';

import { formatMonthYear } from 'app/utils/dates';

import { StaycationLogo } from '../icons/StaycationLogo';
import StripeLogo from '../icons/StripeLogo';

import './CardVCC.scss';

const DEFAULT_CARD_NUMBER = `1234 5678 9012 3456`;
const TODAY = new Date();
const DEFAULT_EXPIRATION_DATE = formatMonthYear(TODAY.toISOString());
const DEFAULT_CVC = 123;

type CardVCCProps = {
  isBlur: boolean;
};

export const CardVCC = ({ isBlur }: CardVCCProps) => {
  return (
    <div className="vcc-card">
      <div className={classNames(['vcc-card-container', { isBlur }])}>
        <div className="vcc-card-logo">
          <div className="staycation">
            <StaycationLogo />
          </div>
          <div className="by-stripe">
            <p className="processed-by">processed by</p>
            <div className="stripe-logo">
              <StripeLogo />
            </div>
          </div>
        </div>
        <div className="vcc-card-content">
          <div className="card-number" id={'card-number'}>
            {DEFAULT_CARD_NUMBER}
          </div>
          <div className="infos">
            <div className="card-secondary-infos">
              <div className="expiration-info">
                <div className="title">Expiry</div>
                <div className="value" id={'card-expiry'}>
                  {DEFAULT_EXPIRATION_DATE}
                </div>
              </div>
              <div className="cvc-info">
                <div className="title">CVC</div>
                <div className="value" id={'card-cvc'}>
                  {DEFAULT_CVC}
                </div>
              </div>
            </div>
            <div className="copy-button-container">
              <Button type="ghost" className="copy-button">
                <CopyOutlined />
                Copy
                {!isBlur && <div id="card-number-copy" />}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardVCC;
