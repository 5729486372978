import runtimeEnv from '@mars/heroku-js-runtime-env';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import history from 'app/utils/history';

import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({ maxAge: 150 });

const IN_AWS = process.env.REACT_APP_IN_AWS === 'true';

// Need that because of Heroku CRA buildpack (https://bit.ly/2zvW82m)
export const env = !IN_AWS
  ? runtimeEnv()
  : {
      NODE_ENV: process.env.NODE_ENV,
      REACT_APP_ENV: process.env.REACT_APP_ENV,
      REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
      REACT_APP_APP_URL: process.env.REACT_APP_APP_URL,
      REACT_APP_API_URL: process.env.REACT_APP_API_URL,
      REACT_APP_AMPLITUDE_KEY: process.env.REACT_APP_AMPLITUDE_KEY,
    };

export const config = {
  env: env.REACT_APP_ENV as string,
  appUrl: env.REACT_APP_APP_URL as string,
  apiUrl: env.REACT_APP_API_URL as string,
  amplitudeKey: env.REACT_APP_AMPLITUDE_KEY as string | undefined,
};

const initialState = {
  navigation: config,
};

const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

sagaMiddleware.run(sagas);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
