import { Form, Layout } from 'antd';
import arrayMove from 'array-move';
import { Location } from 'history';
import { ReactNode, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import type { IExperience } from 'app/components/commons/Experience/Types';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import Headlines from 'app/components/commons/Package/Headlines/Headlines';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useHotelExperiences } from 'app/hooks/data/hotels/useHotelExperience';
import { useGetPackageCopywriting } from 'app/hooks/data/packages/usePackages';
import { useSavePackageCopywriting } from 'app/hooks/data/packages/useSavePackage';
import { useExperiencesCategories } from 'app/hooks/data/useExperience';
import { Picture } from 'app/typings';
import { Package } from 'app/typings/packages';

import './Copywriting.scss';
import ExperienceTitle from './ExperienceTitle/ExperienceTitle';
import Identity from './Identity/Identity';

const { Content } = Layout;

type FormValues = {
  identity: {
    highlight: string;
    description: string;
    name: string;
  };
  headlines: Array<{
    emoji: Picture;
    valueProposition: string;
    experienceId: number;
    featured: boolean;
    title?: string;
  }>;
  experienceTitle: string;
};

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  pkg: Package;
};

export const Copywriting = ({ pkg, headerMenu, headerExtra }: Props) => {
  const { state } = useLocation<Location>();

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [isDirtyMove, setIsDirtyMove] = useState(false);
  const [form] = Form.useForm();
  const { data: experienceCategories } = useExperiencesCategories();
  const { data: hotelExperiences } = useHotelExperiences(pkg.hotelId, {
    includeStaycationExp: true,
  });
  const { mutateAsync: savePackageCopywriting } = useSavePackageCopywriting();

  const { data: packageCopywriting } = useGetPackageCopywriting(pkg.id);

  const onFinish = useCallback(
    (values: FormValues) => {
      if (pkg && pkg.id) {
        const formattedValues = {
          experienceTitle: values.experienceTitle,
          highlight: values.identity.highlight,
          description: values.identity.description,
          headlines: values.headlines.map(({ title, ...h }) => h),
        };

        savePackageCopywriting({
          packageId: pkg.id,
          payload: formattedValues,
        });
        setMode('view');
      }
    },
    [pkg, savePackageCopywriting]
  );

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  if (
    !pkg ||
    !packageCopywriting ||
    !packageCopywriting?.headlines ||
    !hotelExperiences
  ) {
    return null;
  }

  const formattedValues = {
    identity: packageCopywriting,
    experienceTitle: packageCopywriting.experienceTitle,
    headlines: packageCopywriting?.headlines
      ?.filter(
        (h) =>
          !h.experienceId ||
          hotelExperiences?.find((exp) => exp.id === h.experienceId)?.published
      )
      .map((h) => ({
        ...h,
        title: computeExperienceName({
          category: experienceCategories?.find(
            (cat) =>
              cat.id ===
              hotelExperiences?.find((exp) => exp.id === h.experienceId)
                ?.categoryId
          ),
          ...(hotelExperiences?.find(
            (exp) => exp.id === h.experienceId
          ) as IExperience),
        }),
      })),
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={formattedValues}
      className="cover-form"
    >
      {(values, { resetFields, isFieldsTouched, submit, setFieldsValue }) => (
        <SDetailLayout
          title={
            pkg?.aliasForHotel?.trim() && !!pkg?.aliasForHotel.length
              ? pkg?.aliasForHotel
              : `Package #${pkg.id}`
          }
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={() => {
            submit();
            setIsDirtyMove(false);
          }}
          onCancel={() => {
            onCancel();
            setIsDirtyMove(false);
          }}
          reset={resetFields}
          isDirty={isDirtyMove || isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length === 0)}
          backRoute={state?.pathname}
        >
          <Content className="package-detail__content">
            {pkg.dayPackage ? (
              <ExperienceTitle mode={mode} value={values?.experienceTitle} />
            ) : null}
            <Identity mode={mode} values={values?.identity} />
            <SCard title="Headlines">
              <Headlines
                mode={mode}
                values={values}
                setFieldsValue={setFieldsValue}
                move={({ oldIndex, newIndex }) => {
                  setIsDirtyMove(true);
                  setFieldsValue({
                    headlines: arrayMove(values.headlines, oldIndex, newIndex),
                  });
                }}
              />
            </SCard>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

export default Copywriting;
