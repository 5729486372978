import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import history from 'app/utils/history';

import auth from './auth';
import type { Auth } from './auth';
import bills from './bills';
import type { Bills } from './bills';
import conf from './conf';
import type { Conf } from './conf';
import edition from './edition';
import entities from './entities';
import experiences from './experiences';
import type { Experiences } from './experiences';
import hotelAdmin from './hotelAdmin';
import type { HotelAdmin } from './hotelAdmin';
import hotels from './hotels';
import type { Hotels } from './hotels';
import navigation, { Navigation } from './navigation';
import pictures from './pictures';
import type { Pictures } from './pictures';
import rooms from './rooms';
import type { Rooms } from './rooms';
import tags from './tags';
import type { Tags } from './tags';

export type ApplicationState = {
  auth: Auth;
  bills: Bills;
  form: any;
  edition: any;
  entities: any;
  navigation: Navigation;
  hotelAdmin: HotelAdmin;
  hotels: Hotels;
  rooms: Rooms;
  pictures: Pictures;
  conf: Conf;
  experiences: Experiences;
  router: any;
  tags: Tags;
};

export default combineReducers<ApplicationState>({
  auth,
  bills,
  form,
  edition,
  entities,
  navigation,
  hotelAdmin,
  hotels,
  rooms,
  pictures,
  conf,
  experiences,
  router: connectRouter(history),
  tags,
});
