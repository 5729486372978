import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { Experience } from 'app/typings/experiences';
import { apiRequest } from 'app/utils/request/api';

const pathHotelExperience = 'hotels/:hotelId/experiences' as const;

export function useHotelExperiences(
  hotelId: number | null,
  queryParams: { includeStaycationExp: boolean }
) {
  const queryKey = [pathHotelExperience, { hotelId, queryParams }];

  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      hotelId
        ? (apiRequest({
            path: pathHotelExperience,
            params: { hotelId: hotelId.toString() },
            queryParams,
          }).catch((err) =>
            message.error('Error fetching hotel experiences', err)
          ) as Promise<Experience[]>)
        : Promise.resolve([]),
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId,
  });
}
