import { InfoCircleFilled } from '@ant-design/icons';
import { Alert } from 'antd';

import { BookingDetails } from 'app/typings/extranetBookings/booking';
import { formatDayOfMonth } from 'app/utils/dates';

type CardAlertsProps = {
  booking: BookingDetails;
  cardIsCancelled: boolean;
  cardIsDeactivated: boolean;
};

const CardAlerts = ({
  booking,
  cardIsCancelled,
  cardIsDeactivated,
}: CardAlertsProps) => (
  <div className="card-description">
    {!cardIsCancelled && !cardIsDeactivated ? (
      <>
        <Alert
          className="blue-variant"
          type="info"
          message={
            <div>
              Important: the pre-authorisation, deposit, extras or tourist tax{' '}
              <span className="bold">
                must not be made with this virtual card
              </span>{' '}
              but with the guest's physical card.
            </div>
          }
          icon={<InfoCircleFilled />}
          showIcon
        />
        <Alert
          className="grey-variant"
          type="info"
          message={
            <div>
              The card will be chargeable from{' '}
              <span className="bold">
                {' '}
                check-in day ({formatDayOfMonth(booking.checkin)})
              </span>{' '}
              and up to 6 months.
            </div>
          }
          icon={<InfoCircleFilled />}
          showIcon
        />
      </>
    ) : (
      <></>
    )}

    {cardIsDeactivated ? (
      <Alert
        className="grey-variant"
        type="info"
        message="VCC expire within 180 days of check-in for fraud protection. For any inquiries regarding a deactivated card, please contact your account manager."
        icon={<InfoCircleFilled />}
        showIcon
      />
    ) : (
      <></>
    )}
  </div>
);

export default CardAlerts;
