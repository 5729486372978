import { VccStatusData } from 'app/typings/vcc';

type RenderHistoryProps = {
  content: string;
};

const RenderHistory = ({ content }: RenderHistoryProps) => {
  return <div className="history-item">{content}</div>;
};

type CardHistoryProps = { status: VccStatusData };

const CardHistory = ({ status }: CardHistoryProps) => (
  <div className="card-history">
    {status.history.map((history) => (
      <RenderHistory content={history} key={history} />
    ))}
  </div>
);

export default CardHistory;
