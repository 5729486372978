import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import { useAppSelector } from 'app/redux/hooks';

import ExtranetBooking from './ExtranetBookings';

const ExtranetBookingsWithWrapper = () => {
  const hotel = useAppSelector((state) =>
    state.hotelAdmin.hotels.find((h) => h.id === state.hotelAdmin.hotelId)
  );

  return (
    <CurrencyWrapper hotel={hotel}>
      <ExtranetBooking />
    </CurrencyWrapper>
  );
};

export default ExtranetBookingsWithWrapper;
