import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import {
  GuestTask,
  TaskName,
} from 'app/components/pages/Extranet/commons/TaskCenter/Types';
import { IAdditionnalPax } from 'app/typings/additionalPax';
import { Experience } from 'app/typings/experiences';
import { apiMutation, apiRequest } from 'app/utils/request/api';

const path = '/hotel-admin/:hotelId/package' as const;
const pathSavePax = '/hotel-admin/:hotelId/packages/:packageId' as const;
const experiencesPath =
  '/hotel-admin/:hotelId/packages/:packageId/experiences' as const;

export function useAdditionalPax(hotelId: number) {
  return useQuery({
    queryKey: [path, { hotelId }],
    queryFn: () =>
      apiRequest({
        path,
        params: { hotelId: hotelId.toString() },
      }) as Promise<IAdditionnalPax>,
    staleTime: Infinity,
  });
}

export function useAdditionalPaxExperience(
  hotelId: number,
  packageId?: number
) {
  return useQuery({
    queryKey: [experiencesPath, { hotelId }],
    queryFn: () =>
      apiRequest({
        path: experiencesPath,
        params: {
          hotelId: hotelId.toString(),
          packageId: packageId?.toString() ?? '',
        },
      }) as Promise<Experience[]>,
    staleTime: Infinity,
    enabled: !!packageId,
  });
}

export function useSaveAdditionalPax(
  hotelId: number,
  packageId?: number,
  taskName?: GuestTask
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: IAdditionnalPax) =>
      apiMutation(
        'PATCH',
        {
          path: pathSavePax,
          params: {
            hotelId: hotelId.toString(),
            packageId: packageId?.toString() ?? '',
          },
        },
        payload
      ) as Promise<[]>,
    onSuccess: () => {
      queryClient.invalidateQueries();

      if (taskName === TaskName.ConfigureChildPrice) {
        message.success('Successfully saved additional price for children');
      } else if (taskName === TaskName.AttractSingleGuests) {
        message.success('Successfully saved single guest discount');
      } else {
        message.success('Successfully saved additional PAX');
      }
    },
    onError: (err) => {
      message.error('Error saving additional PAX');
    },
  });
}
